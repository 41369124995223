import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './config/firebaseConfig'; // Adjust the path if needed
import Navbar from './Navbar';

const ExclusiveBetaSignup = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    practiceName: '',
    location: '',
    yearsExperience: '',
    patientsMonthly: '',
    currentTools: '',
    challenges: '',
    hopes: '',
    feedbackFrequency: 'Weekly'
  });
  
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    
    try {
      // Save to Firestore
      const betaApplicationsRef = collection(db, "betaApplications");
      
      await addDoc(betaApplicationsRef, {
        ...formData,
        status: 'pending', // You can use this to track application status
        submittedAt: serverTimestamp()
      });
      
      setSubmitted(true);
      
      // send an email and log the event
       const emailResponse = await fetch('https://ocd-serenity.vercel.app/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type: 'newUserRegistration',
          data: {
            email: 'beta@test.com',
            role: 'therapist', // Example role, adjust dynamically if needed
          },
        }),
      });
      
    } catch (err) {
      console.error("Error submitting application:", err);
      setError("There was an error submitting your application. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // CSS styles
  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      maxWidth: '900px',
      margin: '0 auto',
      padding: '20px'
    },
    header: {
      textAlign: 'center',
      marginBottom: '30px'
    },
    
    subtitle: {
      fontSize: '18px',
      color: '#4a6bbd',
      fontWeight: '600'
    },
    card: {
      
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      padding: '24px',
      marginBottom: '24px'
    },
    sectionTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      
      marginBottom: '16px'
    },
    paragraph: {
      
      lineHeight: '1.5',
      marginBottom: '16px'
    },
    list: {
      listStyle: 'disc',
      paddingLeft: '20px',
      marginBottom: '20px',
      
      lineHeight: '1.5'
    },
    formGroup: {
      marginBottom: '20px'
    },
    label: {
      display: 'block',
      marginBottom: '6px',
      fontWeight: '500',
      
    },
    input: {
      width: '95%',
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      fontSize: '16px'
    },
    select: {
      width: '30%',
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      fontSize: '16px',
      backgroundColor: 'white'
    },
    textarea: {
      width: '100%',
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      fontSize: '16px',
      minHeight: '100px'
    },
    button: {
      width: '100%',
      padding: '12px',
      backgroundColor: '#4a6bbd',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      fontSize: '16px',
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: 'background-color 0.3s'
    },
    buttonHover: {
      backgroundColor: '#3a59a9'
    },
    buttonDisabled: {
      backgroundColor: '#a0aec0',
      cursor: 'not-allowed'
    },
    errorMessage: {
      backgroundColor: '#fee',
      color: '#c53030',
      padding: '12px',
      borderRadius: '4px',
      marginBottom: '20px',
      borderLeft: '4px solid #c53030'
    },
    helpText: {
      fontSize: '14px',
      color: '#777',
      textAlign: 'center',
      marginTop: '12px'
    },
    twoColumnGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '20px'
    },
    fullWidth: {
      gridColumn: '1 / -1'
    },
    successContainer: {
      maxWidth: '500px',
      margin: '0 auto',
      textAlign: 'center',
      padding: '40px 20px'
    },
    successIcon: {
      color: '#48bb78',
      fontSize: '48px',
      marginBottom: '24px'
    },
    successTitle: {
      fontSize: '24px',
      fontWeight: 'bold',
      
      marginBottom: '16px'
    },
    benefitItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '12px'
    },
    checkIcon: {
      color: '#48bb78',
      marginRight: '12px'
    }
  };
  
  if (submitted) {
    return (
          <>
      <Navbar />
      <div style={styles.container}>
        <div style={{...styles.card, ...styles.successContainer}}>
          <h2 style={styles.successTitle}>Application Received!</h2>
          <div style={styles.successIcon}>
            ✓
          </div>
          <p style={styles.paragraph}>
            Thank you for your interest in our exclusive beta program for OCD specialists. We're reviewing your application and will be in touch shortly.
          </p>
          <p style={styles.paragraph}>
            If you have any questions, please contact <span style={{color: '#4a6bbd'}}>olivier@ocdserenity.com</span>
          </p>
        </div>
      </div>
      </>
    );
  }
  
  return (
      <>
      <Navbar />
    <div style={styles.container}>
      {/* Header Section */}
      <div style={styles.header}>
        <h1 style={styles.mainTitle}>
          Exclusive Beta Access: Transforming OCD Treatment
        </h1>
        <p style={styles.subtitle}>
          Limited spots available for qualified OCD specialists
        </p>
      </div>
      
      {/* Main Content */}
      <div style={styles.card}>
        <div>
          <h2 style={styles.sectionTitle}>Join Our Pioneering Program</h2>
          <p style={styles.paragraph}>
            We're selecting a small group of dedicated OCD specialists to be the first to experience our innovative treatment platform. As a beta participant, you'll receive:
          </p>
          <ul style={styles.list}>
            <li>Early access to our comprehensive OCD treatment tools</li>
            <li>Direct input into feature development</li>
            <li>Preferred pricing when we launch publicly</li>
            <li>Priority support from our team</li>
          </ul>
        </div>
        
        
        
        {/* Application Form */}
        <form onSubmit={handleSubmit}>
          <div style={styles.twoColumnGrid}>
            <div style={styles.formGroup}>
              <label htmlFor="name" style={styles.label}>
                Full Name *
              </label>
              <input
                type="text"
                name="name"
                id="name"
                required
                value={formData.name}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
            
            <div style={styles.formGroup}>
              <label htmlFor="email" style={styles.label}>
                Email Address *
              </label>
              <input
                type="email"
                name="email"
                id="email"
                required
                value={formData.email}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
            
            <div style={styles.formGroup}>
              <label htmlFor="practiceName" style={styles.label}>
                Practice Name *
              </label>
              <input
                type="text"
                name="practiceName"
                id="practiceName"
                required
                value={formData.practiceName}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
            
            <div style={styles.formGroup}>
              <label htmlFor="location" style={styles.label}>
                Location *
              </label>
              <input
                type="text"
                name="location"
                id="location"
                required
                value={formData.location}
                onChange={handleChange}
                style={styles.input}
                placeholder="City, State/Province, Country"
              />
            </div>
            
            <div style={{...styles.formGroup, ...styles.fullWidth}}>
              <label htmlFor="yearsExperience" style={styles.label}>
                Years of Experience Treating OCD *
              </label>
              <select
                id="yearsExperience"
                name="yearsExperience"
                required
                value={formData.yearsExperience}
                onChange={handleChange}
                style={styles.select}
              >
                <option value="">Select experience</option>
                <option value="0-2">0-2 years</option>
                <option value="3-5">3-5 years</option>
                <option value="6-10">6-10 years</option>
                <option value="11-15">11-15 years</option>
                <option value="16+">16+ years</option>
              </select>
            </div>
            
            <div style={{...styles.formGroup, ...styles.fullWidth}}>
              <label htmlFor="patientsMonthly" style={styles.label}>
                Approximate Number of OCD Patients Treated Monthly *
              </label>
              <select
                id="patientsMonthly"
                name="patientsMonthly"
                required
                value={formData.patientsMonthly}
                onChange={handleChange}
                style={styles.select}
              >
                <option value="">Select range</option>
                <option value="1-5">1-5 patients</option>
                <option value="6-10">6-10 patients</option>
                <option value="11-20">11-20 patients</option>
                <option value="21-30">21-30 patients</option>
                <option value="31+">31+ patients</option>
              </select>
            </div>
            
            <div style={{...styles.formGroup, ...styles.fullWidth}}>
              <label htmlFor="currentTools" style={styles.label}>
                Current Tools/Methods Used for OCD Treatment *
              </label>
              <textarea
                id="currentTools"
                name="currentTools"
                rows="3"
                required
                value={formData.currentTools}
                onChange={handleChange}
                style={styles.textarea}
                placeholder="E.g., ERP worksheets, anxiety tracking apps, CBT techniques, etc."
              ></textarea>
            </div>
            
            <div style={{...styles.formGroup, ...styles.fullWidth}}>
              <label htmlFor="challenges" style={styles.label}>
                Specific Challenges Faced with Current OCD Treatment Approaches *
              </label>
              <textarea
                id="challenges"
                name="challenges"
                rows="3"
                required
                value={formData.challenges}
                onChange={handleChange}
                style={styles.textarea}
                placeholder="What frustrates you about current OCD treatment methods?"
              ></textarea>
            </div>
            
            <div style={{...styles.formGroup, ...styles.fullWidth}}>
              <label htmlFor="hopes" style={styles.label}>
                What Do You Hope to Gain from Our Platform? *
              </label>
              <textarea
                id="hopes"
                name="hopes"
                rows="3"
                required
                value={formData.hopes}
                onChange={handleChange}
                style={styles.textarea}
                placeholder="What features or capabilities would make the biggest difference in your practice?"
              ></textarea>
            </div>
            
            <div style={{...styles.formGroup, ...styles.fullWidth}}>
              <label htmlFor="feedbackFrequency" style={styles.label}>
                How Often Would You Be Willing to Provide Feedback? *
              </label>
              <select
                id="feedbackFrequency"
                name="feedbackFrequency"
                required
                value={formData.feedbackFrequency}
                onChange={handleChange}
                style={styles.select}
              >
                <option value="Weekly">Weekly</option>
                <option value="Bi-weekly">Bi-weekly</option>
                
              </select>
            </div>
          </div>
          
          <div style={{marginTop: '24px'}}>
            <button
              type="submit"
              disabled={isLoading}
              style={isLoading ? {...styles.button, ...styles.buttonDisabled} : styles.button}
              onMouseOver={(e) => !isLoading && (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
              onMouseOut={(e) => !isLoading && (e.target.style.backgroundColor = styles.button.backgroundColor)}
            >
              {isLoading ? 'Submitting...' : 'Apply for Beta Access'}
            </button>
          </div>
          
          <p style={styles.helpText}>
            All fields marked with * are required. Your information will be kept confidential and only used for beta selection purposes.
          </p>
        </form>
      </div>
      {/* Error message */}
        {error && (
          <div style={styles.errorMessage}>
            <p>{error}</p>
          </div>
        )}
      
      {/* Social Proof */}
      <div style={styles.card}>
        <h2 style={styles.sectionTitle}>Why Join Our Beta Program?</h2>
        <div>
          <div style={styles.benefitItem}>
            <span style={styles.checkIcon}>✓</span>
            <span style={styles.paragraph}>Shape the future of OCD treatment technology</span>
          </div>
          <div style={styles.benefitItem}>
            <span style={styles.checkIcon}>✓</span>
            <span style={styles.paragraph}>Gain early access to innovative tools before your colleagues</span>
          </div>
          <div style={styles.benefitItem}>
            <span style={styles.checkIcon}>✓</span>
            <span style={styles.paragraph}>Receive dedicated support from our team of OCD treatment experts</span>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ExclusiveBetaSignup;