import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import {
  doc,
  setDoc,
  addDoc,
  serverTimestamp,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { auth, db } from './config/firebaseConfig'; // Import Firebase config
import Navbar from './Navbar';
import Footer from './Footer';
import hipaa from './hipaa1.png';
import copyMultipleCollections from './copyMultipleCollections';

const TherapistRegistration = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState(null);
  const [alert1, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [waitingForVerification, setWaitingForVerification] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => setIsPasswordVisible(prev => !prev);
  const navigate = useNavigate();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const handleEmailChange = e => {
    const value = e.target.value;
    setEmail(value);

    if (!value) {
      setEmailError('Email is required.');
    } else if (!emailRegex.test(value)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const handlePasswordChange = e => {
    const value = e.target.value;
    setPassword(value);

    if (!value) {
      setPasswordError('Password is required.');
    } else if (!passwordRegex.test(value)) {
      setPasswordError(
        'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character (ONLY @$!%*?&).'
      );
    } else {
      setPasswordError('');
    }
  };

  const logEvent = async (email, role, eventType, note) => {
    try {
      await addDoc(collection(db, 'Logs'), {
        timestamp: serverTimestamp(),
        eventType,
        email,
        role,
        note,
      });
      console.log('Event logged successfully');
    } catch (error) {
      console.error('Error logging event:', error);
    }
  };

  // Function to generate a unique 8-character Therapist Secret Key
  const generateUniqueSecretKey = async () => {
    const generateKey = () =>
      Math.random().toString(36).substring(2, 10).toUpperCase();

    let secretKey;
    let isUnique = false;

    while (!isUnique) {
      secretKey = generateKey();
      const q = query(
        collection(db, 'users'),
        where('therapistSecretKey', '==', secretKey)
      );
      const querySnapshot = await getDocs(q);
      isUnique = querySnapshot.empty;
    }

    return secretKey;
  };

  /*
  const pollEmailVerification = async (user) => {
    const intervalId = setInterval(async () => {
      await user.reload(); // Refresh user object
      if (user.emailVerified) {
        clearInterval(intervalId);
        setAlert('Email verified successfully! Redirecting...');
        // Add the user to Firestore after email verification
        await addUserToFirestore(user);
        navigate('/therapistdashboard'); // Redirect after successful verification
      }
    }, 3000); // Check every 3 seconds
  };
*/

  const handleRegister = async e => {
    e.preventDefault();
    setError(null);

    if (emailError || passwordError) {
      setError('Please fix the errors above before submitting.');
      return;
    }

    setLoading(true);

    try {
      // Step 1: Register the user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Step 2 : Add the user to Firestore
      await addUserToFirestore(user);

       // send an email and log the event
       const emailResponse = await fetch('https://ocd-serenity.vercel.app/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type: 'newUserRegistration',
          data: {
            email: user.email,
            role: 'therapist', // Example role, adjust dynamically if needed
          },
        }),
      });

      await logEvent(
        email,
        'therapist',
        'registration',
        'Therapist successfully registered !'
      );

      navigate('/therapistdashboard'); // Redirect after successful verification

      // Step 2: Send email verification
      //await sendEmailVerification(user);
      //alert('Please check your email for a verification link to finalize your registration.');
      //setAlert('Please check your email for a verification link.');
      //setWaitingForVerification(true);

      // Step 3: Poll for email verification
      //pollEmailVerification(user);
    } catch (err) {
      console.error('Error during registration:', err);
      if (err.code === 'auth/email-already-in-use') {
        setError(
          'This email is already registered. Please use a different email.'
        );

        await logEvent(
          email,
          'therapist',
          'registration',
          'This email is already registered. Please use a different email.'
        );
      } else {
        setError(
          'Registration failed. Please check your inputs and try again.'
        );

        await logEvent(
          email,
          'therapist',
          'registration',
          'Registration failed. Please check your inputs and try again.'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const addUserToFirestore = async user => {
    try {
      // Generate a unique secret key for the therapist
      const therapistSecretKey = await generateUniqueSecretKey();

      // Step 1: Add the new therapist to the Firestore `users` collection
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        role: 'therapist',
        status: 'free',
        mockDataCleared: false,
        therapistSecretKey, // Add the unique therapist key
        createdAt: serverTimestamp(),
      });
      //console.log('User added to Firestore successfully!');
      
      // Copy tracker records from the specific UID
      const TrackerSourceUID = 'ADjKRnX1iXduMuFV3GRmpjnAk7m1';
      await copyMultipleCollections(TrackerSourceUID, user.uid, [
      	'moodTracker',
        'ocdSuds',
        'ocdTriggers',
        'exposureHierarchy',
        'couponHistory',
        'journals',
        'ocdGoals',
        ]);

    } catch (err) {
      console.error('Error adding user to Firestore:', err);
    }
  };
  
  // Beta program start date
  const betaStartDate = new Date('April 1, 2025');
  const currentDate = new Date();
  
  // Calculate days remaining until beta launch
  const daysRemaining = Math.ceil((betaStartDate - currentDate) / (1000 * 60 * 60 * 24));
  
  // Handle redirect to beta signup page
  const handleBetaSignup = () => {
    window.location.href = 'https://ocdserenity.com/beta-signup';
  };
  
    // Inline styles
  const styles = {
    betaAnnouncement: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      textAlign: 'center',
    },
    hipaaLogo: {
      maxWidth: '150px',
      marginBottom: '20px',
    },
    announcementBox: {
      backgroundColor: '#3e3e3e',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      padding: '30px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
    },
    mainHeading: {
      color: '#f09a73',
      marginTop: 0,
      fontSize: '24px',
    },
    betaInfo: {
      textAlign: 'left',
      
      borderRadius: '6px',
      padding: '20px',
      margin: '25px 0',
      borderLeft: '4px solid #3f51b5',
    },
    infoHeading: {
      color: '#f09a73',
      marginTop: 0,
    },
    list: {
      paddingLeft: '20px',
    },
    listItem: {
      marginBottom: '8px',
      lineHeight: '1.5',
    },
    betaSignupButton: {
      backgroundColor: '#3f51b5',
      color: 'white',
      border: 'none',
      padding: '12px 25px',
      fontSize: '16px',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      margin: '20px 0',
    },
    contactInfo: {
      fontSize: '14px',
      marginTop: '25px',
      color: '#666',
    },
    contactLink: {
      color: '#3f51b5',
      textDecoration: 'none',
    },
    countdown: {
      fontWeight: 'bold',
      color: '#f09a73',
    }
  };

  return (
    <>
      <Navbar />
            <div className='therapist-registration'>
        <h2>Therapist Registration</h2>
        
        <div style={styles.betaAnnouncement}>
          <img src={hipaa} alt='HIPAA Compliant' style={styles.hipaaLogo} />
          
          <div style={styles.announcementBox}>
            <h3 style={styles.mainHeading}>Registration Temporarily Paused</h3>
            <p>OCDSerenity is currently preparing for our exclusive 8-week Limited Release Program starting on April 1, 2025.</p>
            <p><span style={styles.countdown}>{daysRemaining} days</span> until our beta launch!</p>
            
            <div style={styles.betaInfo}>
              <h4 style={styles.infoHeading}>Why Join Our Beta Program?</h4>
              <ul style={styles.list}>
                <li style={styles.listItem}>Priority access to our full suite of OCD-specific clinical tools</li>
                <li style={styles.listItem}>Influence future development aligned with evidence-based protocols</li>
                <li style={styles.listItem}>12 months free platform access (based on participation level)</li>
                <li style={styles.listItem}>50% discount post-launch</li>
              </ul>
              
              <h4 style={styles.infoHeading}>Program Highlights:</h4>
              <ul style={styles.list}>
                <li style={styles.listItem}>Specialized tools for ERP, CBT, and other evidence-based OCD protocols</li>
                <li style={styles.listItem}>Clinical Management Dashboard to track patient progress</li>
                <li style={styles.listItem}>Data-driven insights to support clinical decision-making</li>
              </ul>
            </div>
            
            <button 
              onClick={handleBetaSignup} 
              style={styles.betaSignupButton}
              onMouseOver={(e) => e.target.style.backgroundColor = '#303f9f'}
              onMouseOut={(e) => e.target.style.backgroundColor = '#3f51b5'}
            >
              Apply for Beta Access
            </button>
            
            <p style={styles.contactInfo}>
              Questions? Contact us at <a href='mailto:olivier@ocdserenity.com' style={styles.contactLink}>olivier@ocdserenity.com</a>
            </p>
          </div>
        </div>
      </div>
      {/* Temporarily removed during beta
      <div className='therapist-registration'>
        <h2>Therapist Registration</h2>
        <form onSubmit={handleRegister} className='registration-form'>
          <img src={hipaa} alt='HIPAA Compliant' />
          <br />
          <br />
          <label>
            Email:
            <input
              type='email'
              value={email}
              onChange={handleEmailChange}
              required
            />
            {emailError && <p className='error'>{emailError}</p>}
          </label>
          <p>Your password must meet these criteria:</p>
          <p>- At least <strong>8 characters</strong> long</p>
          <p>- At least <strong>one uppercase</strong> letter</p>
          <p>- At least <strong>one lowercase</strong> letter</p>
          <p>- At least <strong>one special character</strong> from this set: @$!%*?&</p>
          <label>
            Password:
            <div style={{ position: 'relative' }}>
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                required
              />
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '25px',
                  top: '50%',
                  fontSize: '25px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
              >
                {isPasswordVisible ? '👁️' : '🙈'}
              </span>
            </div>
            {passwordError && <p className='error'>{passwordError}</p>}
          </label>
          {!waitingForVerification && (
            <button
              type='submit'
              disabled={loading || !!emailError || !!passwordError}
            >
              {loading ? 'Registering...' : 'Register'}
            </button>
          )}
          {alert1 && <p className='alert'>{alert1}</p>}
        </form>
        {error && <p className='error'>{error}</p>}
      </div>
      */}
      <Footer />
    </>
  );
};

export default TherapistRegistration;