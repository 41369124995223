import React, { useState, useEffect } from 'react';
import './HowToUseOCD.css'; // Import your CSS file for styling
import Navbar from './Navbar';
import Footer from './Footer';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebaseConfig'; // Firebase setup
import secretKey from './secret-key.png';
import client_1 from './client_1.png';
import client_2 from './client_2.png';
import client_3 from './client_3.png';
import client_4 from './client_4.png';
import client_5 from './client_5.png';

const HowToUseTherapist = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status

  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  // ***************************************************************************
  // ********************************* RETURN **********************************
  // ***************************************************************************
  return (
    <>
      <Navbar />
      <div className='howto-container'>
        <h1>How to Use the Therapist Dashboard</h1>
        <br />
        <p>
          Welcome to the guide on using the Therapist Dashboard on OCD Serenity.
          This dashboard allows therapists to manage clients, track progress
          across tools, and provide support efficiently.
        </p>

        <div class='section'>
          <h2>1. Accessing the Dashboard</h2>
          <ul>
            <li>
              Log in to your therapist account on the OCD Serenity website.
              <div className='image-content10'>
                <img
                  src={client_2}
                  alt='client_2'
                  className='responsive-image'
                />
              </div>
            </li>
            <li>
              After logging in, you will be directed to the Therapist Dashboard.
            </li>
          </ul>
        </div>

        <div class='section'>
          <h2>2. Sharing Your Secret Key</h2>
          <p>
            Each therapist has a unique "Secret Key" that clients can use to
            link their accounts to the therapist's dashboard.
          </p>
          <ul>
            <li>
              Click the <strong>"Show Secret Key"</strong> button to reveal your
              key.
            </li>
            <li>Share this key with your clients.</li>
            <li>
              Clients will enter this key during their registration to link
              their account with you.
            </li>
          </ul>
          <div className='image-content10'>
            <img src={secretKey} alt='secretKey' className='responsive-image' />
          </div>
        </div>

        <div class='section'>
          <h2>3. Managing Clients</h2>
          <ul>
            <li>
              View your list of clients, including their alias, email, and
              comments, in the "Your Clients" section.
            </li>
            <li>
              Use the action buttons next to each client to:
              <ul>
                <li>
                  <strong>Show Statistics:</strong> View detailed statistics for
                  the client.
                  <div className='image-content10'>
                    <img
                      src={client_4}
                      alt='client_4'
                      className='responsive-image'
                    />
                  </div>
                </li>
                <li>
                  <strong>Send Message:</strong> Open the messaging modal to
                  communicate with the client.
                  <div className='image-content10'>
                    <img
                      src={client_3}
                      alt='client_3'
                      className='responsive-image'
                    />
                  </div>
                </li>
                <li>
                  <strong>Edit:</strong> Update client information such as alias
                  or comments.
                  <div className='image-content10'>
                    <img
                      src={client_1}
                      alt='client_1'
                      className='responsive-image'
                    />
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class='section'>
          <h2>4. Viewing Progress Across Tools</h2>
          <p>
            The dashboard aggregates data from all tools used by the client,
            including:
          </p>
          <ul>
            <li>
              <strong>Reassurance Coupon Tracker:</strong>
              <ul>
                <li>
                  Displays a bar chart of reassurance coupons given weekly.
                </li>
                <li>
                  View statistics such as total coupons given, average per week,
                  and trends over time.
                </li>
              </ul>
            </li>
            <li>
              <strong>Exposure Hierarchy Builder:</strong>
              <ul>
                <li>Track total, completed, and incomplete exposures.</li>
                <li>
                  Analyze distress level reductions over time and the completion
                  rate.
                </li>
              </ul>
            </li>
            <li>
              <strong>Mood Tracker:</strong>
              <ul>
                <li>View a calendar with recorded moods.</li>
                <li>Analyze mood distribution percentages over time.</li>
              </ul>
            </li>
            <li>
              <strong>Trigger Tracker:</strong>
              <ul>
                <li>
                  View distress level trends and the most common triggers.
                </li>
                <li>Analyze compulsion-to-relief ratios.</li>
              </ul>
            </li>
            <li>
              <strong>SUDS Tracker:</strong>
              <ul>
                <li>
                  Analyze anxiety levels during exposures, along with trends and
                  progress summaries.
                </li>
              </ul>
            </li>
            <li>
              <strong>Journaling:</strong>
              <ul>
                <li>
                  The Journal allows to easily document thoughts and feelings on specific dates, helping to track emotional patterns over time.
                </li>
              </ul>
            </li>
          </ul>
        </div>

   

        <div class='section'>
          <h2>5. Contact and Feedback</h2>
          <p>
            If you encounter issues or have suggestions, use the{' '}
            <strong>Feedback</strong> section in the dashboard to provide your
            input. Your feedback helps improve the platform!
            <div className='image-content10'>
                <img
                  src={client_5}
                  alt='client_5'
                  className='responsive-image'
                />
              </div>
          </p>
        </div>

        
      </div>
      <Footer />
    </>
  );
};

export default HowToUseTherapist;
