import React, { useState, useEffect } from 'react';
import './HowToUseOCD.css'; // Import your CSS file for styling
import Navbar from './Navbar';
import Footer from './Footer';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebaseConfig'; // Firebase setup
import { Link } from 'react-router-dom';
import trigger_1 from './trigger_1.png';
import trigger_2 from './trigger_2.png';
import exposure_1 from './exposure_1.png';
import exposure_2 from './exposure_2.png';
import dashboard_coupon from './dashboard-coupon.png';
import dashboard_exposure from './dashboard-exposure.png';
import dashboard_suds from './dashboard-suds.png';
import dashboard_trigger from './dashboard-trigger.png';
import dashboard_export from './export.png';
import mood_1 from './mood_1.png';
import mood_3 from './mood_3.png';
import coupon_1 from './coupon_1.png';
import suds_1 from './suds_1.png';
import journal_1 from './journal_1.png';
import journal_2 from './journal_2.png';

const HowToUseOCD = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status

  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  // ***************************************************************************
  // ********************************* RETURN **********************************
  // ***************************************************************************
  return (
    <>
      <Navbar />
      <div className='howto-container'>
        <h1>How to Use the Trigger Tracker</h1>
        <br />
        <p>
          The Trigger Tracker is a tool designed to help you log and analyze
          your OCD triggers, intrusive thoughts, compulsions, and relief
          mechanisms. Use this guide to learn how to make the most of it.
        </p>
        <h2>Step 1: Add a New Trigger Entry</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Access the Form:</strong> Locate the form at the top of
              the Trigger Tracker page.
            </li>
            <li>
              <strong>Fill in the Fields:</strong>
              <ul>
                <li>
                  <strong>Suspected Trigger:</strong> Enter the event or
                  situation that triggered your OCD symptoms.
                </li>
                <li>
                  <strong>Thought:</strong> Describe the intrusive thought or
                  fear you experienced.
                </li>
                <li>
                  <strong>Compulsion:</strong> Specify the action or behavior
                  you performed in response to the thought.
                </li>
                <li>
                  <strong>Relief/Distraction:</strong> Record how you tried to
                  alleviate your distress.
                </li>
                <li>
                  <strong>Distress Severity:</strong> Choose an emoji that
                  represents your distress level:
                  <ul>
                    <li>
                      <span class='emoji'>😄</span> Low distress
                    </li>
                    <li>
                      <span class='emoji'>🙂</span> Mild distress
                    </li>
                    <li>
                      <span class='emoji'>😐</span> Moderate distress
                    </li>
                    <li>
                      <span class='emoji'>😟</span> High distress
                    </li>
                    <li>
                      <span class='emoji'>😢</span> Severe distress
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <strong>Save the Entry:</strong> Click the <em>Save</em> button to
              add the entry to your tracker. If you're editing, click{' '}
              <em>Update</em>.
            </li>
          </ol>
        </div>

        <div className='image-content10'>
          <img src={trigger_1} alt='trigger_1' className='responsive-image' />
        </div>

        <h2>Step 2: View and Manage Your Entries</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>View Entries:</strong> Find your logged triggers in the
              table below the form. Each entry includes:
              <ul>
                <li>Date</li>
                <li>Trigger</li>
                <li>Thought</li>
                <li>Compulsion</li>
                <li>Relief</li>
                <li>Distress Level</li>
              </ul>
            </li>
            <li>
              <strong>Edit an Entry:</strong> Click the <em>Edit</em> button
              next to an entry, update the fields, and click <em>Update</em>.
            </li>
            <li>
              <strong>Delete an Entry:</strong> Click the <em>Delete</em> button
              to remove an entry.
            </li>
          </ol>
        </div>

        <div className='image-content10'>
          <img src={trigger_2} alt='trigger_2' className='responsive-image' />
        </div>

        <h2>Step 3: Organize Your Data</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Search Entries:</strong> Use the search bar to find
              specific triggers by keyword.
            </li>
            <li>
              <strong>Sort Data:</strong>
              <ul>
                <li>
                  <em>By Date:</em> Sort entries chronologically.
                </li>
                <li>
                  <em>By Distress:</em> Sort entries by distress level.
                </li>
              </ul>
            </li>
          </ol>
        </div>

        <h2>Step 4: Track Patterns Over Time And Monitor Your Progress Through your Dashboard</h2>
        <div class='step'>
          <p>
            Regularly log triggers and review your entries to identify recurring
            patterns or improvements. Use these insights to guide your therapy
            or monitor your progress.
          </p>
        </div>

        <div className='image-content10'>
          <img
            src={dashboard_trigger}
            alt='dashboard_trigger'
            className='responsive-image'
          />
        </div>

        <div class='divider'></div>

        <h1>How to Use the Mood Tracker</h1>
        <br />
        <p>
          The <strong>Pixel Mood Tracker</strong> is an interactive tool
          designed to help you log and monitor your daily moods throughout the
          year. Each square represents a day, and each row represents a month.
          Follow this guide to learn how to use it.
        </p>

        <h2>Step 1: Record Your Mood</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Select a Day:</strong> Find the square corresponding to
              the day you want to log your mood.
            </li>
            <li>
              <strong>Click to Cycle Through Moods:</strong> Click on the square
              to choose your mood:
              <ul>
                <li>
                  <span class='emoji'>😊</span> <strong>No distress</strong>{' '}
                  (green)
                </li>
                <li>
                  <span class='emoji'>🙂</span>{' '}
                  <strong>Minimal distress</strong> (yellow)
                </li>
                <li>
                  <span class='emoji'>😟</span>{' '}
                  <strong>Quite distressed</strong> (blue)
                </li>
                <li>
                  <span class='emoji'>😫</span>{' '}
                  <strong>Extremely distressed</strong> (red)
                </li>
              </ul>
            </li>
            
          </ol>
        </div>

        <h2>Step 2: View and Analyze Your Mood</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Color Coding:</strong> Each mood is represented by a
              specific color, making it easy to identify patterns over time.
            </li>
            <li>
              <strong>Track Trends:</strong> Observe the variations in color to
              gain insights into your mood patterns and emotional well-being.
            </li>
          </ol>
        </div>

        <div className='image-content10'>
          <img src={mood_1} alt='mood_1' className='responsive-image' />
        </div>

        <h2>Step 3: Clear Your Mood Data</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Clear Data:</strong> Click the <em>Clear All Data</em>{' '}
              button at the bottom of the tracker to reset your mood records.
            </li>
            <li>
              <strong>Confirmation:</strong> A confirmation dialog will appear
              to ensure you want to delete all mood data.
            </li>
            <li>
              <strong>Start Fresh:</strong> Once cleared, you can begin tracking
              your moods again from scratch.
            </li>
          </ol>
        </div>

        <h2>Additional Information</h2>
        <p>
          Use the tool regularly to observe trends in
          your mood and share insights with your therapist if needed.
        </p>

        <div class='divider'></div>

        <h1>How to Use the Exposure Hierarchy Builder</h1>
        <br />
        <p>
          The Exposure Hierarchy Builder is a step-by-step tool to help you
          gradually confront your fears and anxieties. By ranking situations by
          their distress level and working through them systematically, you can
          build resilience and reduce anxiety over time. Here's how to use the
          tool:
        </p>

        <h2>Step 1: Add an Exposure</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Enter a Situation:</strong> Use the input field to
              describe an anxiety-inducing situation (e.g., "Entering a crowded
              room").
            </li>
            <li>
              <strong>Assign a Distress Level:</strong> Enter a distress level
              between 1 and 10, where 1 represents minimal distress and 10
              represents extreme distress.
            </li>
            <li>
              <strong>Save:</strong> Click the <em>Add Exposure</em> button to
              add the new entry to your hierarchy. The entry will appear under
              the <em>Incomplete Exposures</em> section.
            </li>
          </ol>
        </div>

        <div className='image-content10'>
          <img src={exposure_1} alt='exposure_1' className='responsive-image' />
        </div>

        <h2>Step 2: Track Your Progress</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>View Incomplete Exposures:</strong> Incomplete exposures
              are displayed in order of distress level (highest to lowest).
              These represent the situations you still need to work through.
            </li>
            <li>
              <strong>Mark as Complete:</strong> When you've successfully faced
              a situation, click <em>Mark Complete</em> to move it to the{' '}
              <em>Completed Exposures</em> section.
            </li>
            <li>
              <strong>Progress Bar:</strong> A progress bar at the top shows the
              proportion of completed exposures, helping you visualize your
              progress over time.
            </li>
          </ol>
        </div>

        <h2>Step 3: Manage Your Exposures</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Edit an Exposure:</strong> If you need to modify the title
              or distress level of an entry, click the <em>Edit</em> button next
              to the entry, make your changes, and click{' '}
              <em>Update Exposure</em>.
            </li>
            <li>
              <strong>Delete an Exposure:</strong> To remove an entry entirely,
              click the <em>Delete</em> button next to it.
            </li>
            <li>
              <strong>Toggle Completion:</strong> You can move a completed
              exposure back to the <em>Incomplete Exposures</em> section by
              clicking <em>Mark Incomplete</em>.
            </li>
          </ol>
        </div>

        <div className='image-content10'>
          <img src={exposure_2} alt='exposure_2' className='responsive-image' />
        </div>

        <h2>Step 4: Review Completed Exposures Through Your Dashboard</h2>
        <div class='step'>
          <p>
            Completed exposures are displayed with a strikethrough effect,
            making it easy to see what you've accomplished. Review this section
            regularly to reflect on your progress and celebrate your
            achievements.
          </p>
        </div>

        <div className='image-content10'>
          <img
            src={dashboard_exposure}
            alt='dashboard_exposure'
            className='responsive-image'
          />
        </div>

        <div class='divider'></div>

        <h1>How to Use the SUDS Tracker (SUDS Import)</h1>
        <br />
        <p>
          The SUDS Tracker is a powerful tool to help you record and monitor
          your anxiety levels (SUDS scores) during exposure exercises. You can
          also log the interval at which the scores were recorded and specify
          the ritual practiced. Follow this guide to use it effectively:
        </p>

        <h2>Step 1: Input Anxiety Levels</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Enter SUDS Scores:</strong> In the input field, enter your
              anxiety levels as comma-separated values (e.g.,{' '}
              <em>85, 70, 62</em>).
            </li>
            <li>
              <strong>Example:</strong> If you recorded your anxiety levels
              during an exposure exercise at regular intervals, list them in
              order of occurrence.
            </li>
          </ol>
        </div>

        <h2>Step 2: Select an Interval</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Choose Interval:</strong> Use the dropdown menu to select
              the time interval (e.g., 3 minutes, 5 minutes, 10 minutes) at
              which the anxiety levels were recorded.
            </li>
            <li>
              <strong>Default:</strong> If no interval is specified, the default
              is set to 5 minutes.
            </li>
          </ol>
        </div>

        <h2>Step 3: Choose a Ritual</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Select Ritual:</strong> From the dropdown menu, choose the
              ritual practiced during the exercise (e.g.,{' '}
              <em>Checking door locks</em>, <em>Washing hands</em>, etc.).
            </li>
            <li>
              <strong>Add a New Ritual:</strong> If the desired ritual is not
              listed, use the input field to add a new ritual to the list. Click{' '}
              <em>Add Ritual</em> to save it.
            </li>
          </ol>
        </div>

        <div className='image-content10'>
          <img src={suds_1} alt='suds_1' className='responsive-image' />
        </div>

        <h2>Step 4: Save Your Data And Monitor Your Progress Through Your Dashboard</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Submit:</strong> Once you have entered the anxiety levels,
              selected the interval, and chosen the ritual, click the{' '}
              <em>Save</em> button to log your data.
            </li>
            <li>
              <strong>Confirmation:</strong> A success message will appear once
              your data has been saved. You will then be redirected to the
              dashboard for further analysis.
            </li>
          </ol>
        </div>

        <div className='image-content10'>
          <img
            src={dashboard_suds}
            alt='dashboard_suds'
            className='responsive-image'
          />
        </div>

        <h2>Additional Features</h2>
        <p>
          The SUDS Tracker includes several additional features to enhance your
          experience:
        </p>
        <ul>
          <li>
            <strong>Custom Rituals:</strong> You can create and save your own
            rituals to use in future entries.
          </li>
          <li>
            <strong>Automatic Interval Assignment:</strong> The tool
            automatically calculates time intervals for anxiety levels based on
            your selection.
          </li>
        </ul>

        <div class='divider'></div>

        <h1>How to Use the Journaling Tool</h1>
        <br />
        <p>
          The Journaling Tool allows you to record your daily thoughts and
          emotions, helping you track your emotional patterns over time. You can
          write entries for specific dates, score your day, and reflect on past
          experiences. Here’s how to use it effectively:
        </p>

        <h2>Step 1: Open the Journal</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Select a Date:</strong> Use the calendar to choose a
              specific date. Click on a date to open the journal entry modal.
            </li>
            <li>
              <strong>View Existing Entries:</strong> Hover over a date to see
              any existing entries for that day, including the associated emoji
              score.
            </li>
          </ol>
        </div>

        <h2>Step 2: Write a Journal Entry</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Enter Your Thoughts:</strong> In the text area provided,
              write about your experiences, emotions, and reflections for the
              selected date.
            </li>
            <li>
              <strong>Score Your Day:</strong> Choose an emoji to score your
              day:
              <ul>
                <li>😊 (Great)</li>
                <li>🙂 (Good)</li>
                <li>😟 (Difficult)</li>
                <li>😫 (Very Difficult)</li>
              </ul>
            </li>
          </ol>
        </div>

        <div className='image-content10'>
          <img src={journal_1} alt='journal_1' className='responsive-image' />
        </div>

        <h2>Step 3: Save or Edit Your Entry</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Save:</strong> Click the <em>Save</em> button to save your
              journal entry. The entry will appear on the calendar with the
              selected emoji and text.
            </li>
            <li>
              <strong>Edit:</strong> To modify an entry, click on the date
              again, make your changes, and click <em>Save</em>.
            </li>
          </ol>
        </div>

        <h2>Step 4: Reflect on Your Entries</h2>
        <div class='step'>
          <p>
            Use the calendar view to identify trends and patterns in your
            emotions. Regular journaling can provide insights into your
            emotional well-being, helping you and your therapist understand
            triggers and progress over time.
          </p>
        </div>

        <div className='image-content10'>
          <img src={journal_2} alt='journal_2' className='responsive-image' />
        </div>

        <h2>Additional Features</h2>
        <ul>
          <li>
            <strong>Emoji Scores:</strong> Each day is scored with an emoji,
            providing a quick visual overview of your emotional patterns.
          </li>
          <li>
            <strong>Interactive Calendar:</strong> Easily navigate through your
            entries using the intuitive calendar view.
          </li>
        </ul>

        <div class='divider'></div>

        <h1>How to Use the Reassurance Coupon</h1>
        <br />
        <p>
          Reassurance Coupons are a tool to help individuals with OCD manage
          their reassurance-seeking behaviors. By limiting the number of
          reassurance-seeking questions through coupons, the goal is to
          gradually reduce reliance on reassurance and build tolerance for
          uncertainty.
        </p>

        <h2>Step 1: Understand the Purpose</h2>
        <div class='step'>
          <p>
            Reassurance Coupons are meant to limit reassurance-seeking
            behaviors. Each coupon grants one reassurance-seeking question to be
            answered honestly and clearly, without follow-up questions. The goal
            is to use fewer coupons over time and build the ability to manage
            uncertainty.
          </p>
        </div>

        <h2>Step 2: Using the Coupons</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Set a Weekly Limit:</strong> Work with your therapist or
              loved ones to determine how many coupons you can use each week.
            </li>
            <li>
              <strong>Trade Coupons for Questions:</strong> Each coupon can be
              used for one reassurance-seeking question. Once used, it cannot be
              reused.
            </li>
          </ol>
        </div>

        <h2>Step 3: Logging Coupons</h2>
        <div class='step'>
          <ol>
            <li>
              <strong>Track Coupons Given:</strong> Use the "Log Coupons Given"
              section to record how many coupons you’ve used this week.
            </li>
            <li>
              <strong>View Coupon History:</strong> Check the history table to
              see the number of coupons used in previous weeks.
            </li>
            <li>
              <strong>Clear History:</strong> If necessary, clear all logged
              history using the <em>Clear History</em> button. This action is
              irreversible.
            </li>
          </ol>
        </div>

        <div className='image-content10'>
          <img src={coupon_1} alt='coupon_1' className='responsive-image' />
        </div>

        <h2>Step 4: Printable Coupons</h2>
        <div class='step'>
          <p>
            Printable reassurance coupons are available to download. These can
            be shared with loved ones or therapists for physical tracking.
          </p>
          <p>
            ➡️{' '}
            <a
              href='https://www.ocdserenity.com/Reassurance-coupons.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              Download Printable Coupons Here
            </a>{' '}
            ⬅️
          </p>
        </div>

        <h2>Tracking Your Progress Through Your Dashboard</h2>
        <p>
          Using fewer reassurance coupons over time is a sign of progress. Keep
          a record of your weekly usage and discuss trends with your therapist
          or support system to better understand your growth.
        </p>
        <br />
        <div className='image-content10'>
          <img
            src={dashboard_coupon}
            alt='dashboard_coupon'
            className='responsive-image'
          />
        </div>

        <div class='divider'></div>

        <h1>Dashboard Overview</h1>
        <br />
        <p>
          The Dashboard provides an overview of your progress and statistics
          from the various tools on OCD Serenity, including Reassurance Coupon
          Tracker, Exposure Hierarchy Builder, Mood Tracker, Trigger Tracker,
          and SUDS Tracker. Below is a guide on how to interpret and use the
          data displayed on the Dashboard.
        </p>

        <h2>Reassurance Coupon Tracker</h2>
        <div class='section'>
          <p>
            This section displays the number of reassurance coupons you’ve used
            each week, along with cumulative statistics such as total coupons
            given, average coupons per week, and maximum/minimum coupons used in
            a week.
          </p>
          <ul>
          <li>
            <strong>Graph:</strong> Weekly coupons given are visualized in a bar
            chart.
          </li>
          
            <li>
              <strong>Total Coupons Given:</strong> The total number of coupons
              logged.
            </li>
            <li>
              <strong>Average Coupons Per Week:</strong> Your average usage
              across all weeks tracked.
            </li>
            <li>
              <strong>Last Week's Coupons:</strong> The number of coupons used
              in the most recent week.
            </li>
          </ul>
          <div className='image-content10'>
            <img
              src={dashboard_coupon}
              alt='dashboard_coupon'
              className='responsive-image'
            />
          </div>
        </div>

        <h2>Exposure Hierarchy Builder</h2>
        <div class='section'>
          <p>
            This section tracks your exposure tasks and provides insights into
            your progress, including the completion rate and distress reduction
            over time.
          </p>
          <ul>
            <li>
              <strong>Total Exposures:</strong> The number of exposure tasks
              created.
            </li>
            <li>
              <strong>Completed Exposures:</strong> How many tasks you’ve
              successfully completed.
            </li>
            <li>
              <strong>Average Distress Level:</strong> The average distress
              level reported across all exposures.
            </li>
            <li>
              <strong>Distress Reduction Over Time:</strong> The total reduction
              in distress levels for completed tasks.
            </li>
          
          <li>
            <strong>Completion Rate:</strong> Visualized as a percentage,
            showing your progress.
          </li>
          </ul>

          <br />
          <div className='image-content10'>
            <img
              src={dashboard_exposure}
              alt='dashboard_exposure'
              className='responsive-image'
            />
          </div>
        </div>

        <h2>Mood Tracker</h2>
        <div class='section'>
          <p>
            The Mood Tracker displays your daily moods using an interactive
            calendar and calculates percentages for each mood type.
          </p>
          <ul>
            <li>
              <strong>Emoji Percentages:</strong> Shows the proportion of each
              mood (e.g., 😊, 🙂, 😟, 😫).
            </li>
            <li>
              <strong>Visual Calendar:</strong> Moods are displayed on a grid
              for easy tracking.
            </li>
          </ul>
          <div className='image-content10'>
            <img src={mood_3} alt='mood_3' className='responsive-image' />
          </div>
        </div>

        <h2>Trigger Tracker</h2>
        <div class='section'>
          <p>
            The Trigger Tracker provides insights into your distress levels and
            common triggers, with metrics like compulsion-to-relief ratio and
            top triggers.
          </p>
          <ul>
            <li>
              <strong>Top 3 Triggers:</strong> The most frequently recorded
              triggers.
            </li>
            <li>
              <strong>Compulsion to Relief Ratio:</strong> The ratio of
              compulsions to relief strategies logged.
            </li>
            <li>
              <strong>Distress Trend:</strong> Shows whether distress levels
              have increased, decreased, or remained stable.
            </li>
          </ul>
          <div className='image-content10'>
            <img
              src={dashboard_trigger}
              alt='dashboard_trigger'
              className='responsive-image'
            />
          </div>
        </div>

        <h2>SUDS Tracker</h2>
        <div class='section'>
          <p>
            The SUDS Tracker provides insights into anxiety levels logged during
            exposure exercises. It includes a trend analysis and comparative
            metrics.
          </p>
          <ul>
            <li>
              <strong>Average Anxiety Level:</strong> The average anxiety score
              across all sessions.
            </li>
            <li>
              <strong>Trend:</strong> Whether anxiety levels are increasing,
              decreasing, or stable.
            </li>
            <li>
              <strong>Milestones:</strong> Highlighted when significant goals
              are achieved (e.g., consistent improvement).
            </li>
          
          <li>
            <strong>Progress Bar:</strong> Visualizes improvements over time.
          </li>
          </ul>
          <br />
          <div className='image-content10'>
            <img
              src={dashboard_suds}
              alt='dashboard_suds'
              className='responsive-image'
            />
          </div>
        </div>

        <h2>Export and Import Data</h2>
        <div class='section'>
          <p>
            You can manage your data using the export and import tools provided:
          </p>
          <ul>
            <li>
              <strong>Export:</strong> Save all your data as a JSON file for
              backup.
            </li>
            <li>
              <strong>Import:</strong> Load previously saved data into the
              dashboard.
            </li>
            <li>
              <strong>Delete All Data:</strong> Clear all data if needed
              (irreversible).
            </li>
          </ul>
          <div className='image-content10'>
            <img
              src={dashboard_export}
              alt='dashboard_export'
              className='responsive-image'
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HowToUseOCD;
