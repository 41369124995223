import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';

import './GetADemo.css'; // Import your CSS file for styling
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebaseConfig'; // Firebase setup

const GetADemo = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status

  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  return (
    <>
      <Navbar />
      <div className='get-a-demo'>
        <h1>Our Demo</h1>
        <p>Learn how OCD Serenity can support you or your clients.</p>

        <div>
          <br />
          <br />
          <br />
          <video
            autoPlay={true} // Use boolean value for autoPlay
            preload='auto'
            loop
            controls
            id='myVideo'
            style={{ width: '100%', height: 'auto' }}
          >
            <source
              src='https://storage.googleapis.com/ocdserenity_bucket/ocd-v2-1.mp4'
              type='video/webm'
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </>
  );
};

export default GetADemo;
