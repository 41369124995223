import React, { useState, useEffect } from 'react';
import { auth, db } from './config/firebaseConfig';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  doc,
  getDoc,
  addDoc,
  collection,
  serverTimestamp,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import './LoginScreen.css';
import Navbar from './Navbar';
import Footer from './Footer';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


const TrialExpirationModal = ({
  isOpen,
  daysLeft,
  onClose,
  onUpgrade,
  trialEnded,
}) => {
  if (!isOpen) return null;

  const showEndedMessage = trialEnded || daysLeft <= 0;

  return (
    <div className='trial-modal-overlay'>
      <div className='trial-modal-content'>
        <h2>
          {showEndedMessage
            ? 'Your free trial has ended.'
            : `Your free trial ends in ${daysLeft} day${
                daysLeft > 1 ? 's' : ''
              }`}
        </h2>
        <p>
          {showEndedMessage
            ? 'Please upgrade to continue access.'
            : 'Consider upgrading to continue access.'}
        </p>
        {!showEndedMessage && <button onClick={onClose}>Later</button>}
        <button onClick={onUpgrade}>Upgrade now</button>
      </div>
    </div>
  );
};

const LoginScreen = ({ setTherapistId }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);
  const [userRole, setUserRole] = useState('');
  const [trialEnded, setTrialEnded] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [user, setUser] = useState(null); // State to store the logged-in user

  const togglePasswordVisibility = () => setIsPasswordVisible(prev => !prev);
  const navigate = useNavigate();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const handleEmailChange = e => {
    const value = e.target.value;
    setEmail(value);
    setError(null);

    if (!value) {
      setEmailError('Email is required.');
    } else if (!emailRegex.test(value)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const handlePasswordChange = e => {
    const value = e.target.value;
    setPassword(value);
    setError(null);

    if (!value) {
      setPasswordError('Password is required.');
    } else if (!passwordRegex.test(value)) {
      setPasswordError(
        'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character (ONLY @$!%*?&).'
      );
    } else {
      setPasswordError('');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    if (userRole === 'therapist') {
      navigate('/therapistdashboard');
    } else if (userRole === 'individual') {
      navigate('/client-dashboard');
    }
  };

  const handleUpgrade = async () => {
    setIsModalOpen(false);

    try {
      const response = await fetch(
        'https://ocd-serenity.vercel.app/api/create-checkout-session',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            role: userRole,
            userId: user ? user.uid : null,
          }), // Use `user` or `loggedInUser`
        }
      );

      if (!response.ok) {
        throw new Error('Failed to create checkout session.');
      }

      const data = await response.json();
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: data.sessionId });
    } catch (error) {
      console.error('Error handling upgrade:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const logLoginEvent = async (email, role, eventType, note) => {
    try {
      await addDoc(collection(db, 'Logs'), {
        timestamp: serverTimestamp(),
        eventType,
        email,
        role,
        note,
      });
    } catch (error) {
      console.error('Error logging login event:', error);
    }
  };

   const handleLogin = async e => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const loggedInUser = userCredential.user;
      setUser(loggedInUser); // Update user state

      const userDocRef = doc(db, 'users', loggedInUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userRole = userData.role;
        const userStatus = userData.status;
        const createdAt = userData.createdAt.toDate();
        const daysElapsed = Math.floor(
          (Date.now() - createdAt.getTime()) / (1000 * 60 * 60 * 24)
        );

        setUserRole(userRole); // Store the user role for later use

        // Log the login event in Firestore
        await logLoginEvent(
          loggedInUser.email,
          userRole,
          'login',
          'Logged in successfully!'
        );

        if (userRole === 'admin') {
          navigate('/admin-dashboard');
        }

        if (userRole === 'client') {
          navigate('/client-dashboard');
        }

        if (userRole === 'individual') {
          navigate('/client-dashboard');
        }

        if (userRole === 'therapist') {
          const trialPeriodDays = 30;
          const remainingDays = trialPeriodDays - daysElapsed;
          setDaysLeft(remainingDays);

          if (
            remainingDays <= 10 &&
            remainingDays > 0 &&
            userStatus === 'free'
          ) {
            setTherapistId(loggedInUser.uid);
            setIsModalOpen(true);
            setTrialEnded(false);
          } else if (remainingDays <= 0 && userStatus === 'free') {
            // alert('Your free trial has ended. Please upgrade to continue access.');
            setTherapistId(loggedInUser.uid);
            setIsModalOpen(true);
            setTrialEnded(true);
          } else if (userStatus === 'paid') {
            setTherapistId(loggedInUser.uid);
            navigate('/therapistdashboard');
          } else if (userStatus === 'cancelled') {
            alert('The subscription for this account has been cancelled');
            setTherapistId(loggedInUser.uid);
            setIsModalOpen(true);
            setTrialEnded(true);
          } else {
            setTherapistId(loggedInUser.uid);
            navigate('/therapistdashboard');
          }
        }

        // navigate('/client-dashboard');
      } else if (userRole === 'client') {
        navigate('/client-dashboard');
      } else {
        throw new Error('User role not recognized.');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setError(
        'Failed to log in. Please check your credentials and try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      setError('Please enter your email to reset the password.');
      return;
    }
    setMessage('');
    setError(null);

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <>
      <Navbar />
      <TrialExpirationModal
        isOpen={isModalOpen}
        daysLeft={daysLeft}
        onClose={handleCloseModal}
        onUpgrade={handleUpgrade}
        trialEnded={trialEnded}
      />
      <div className='login-screen'>
        <h2>Login</h2>
        <form onSubmit={handleLogin} className='login-form'>
          <label>
            Email:
            <input
              type='email'
              value={email}
              onChange={handleEmailChange}
              required
            />
            {emailError && <p className='error'>{emailError}</p>}
          </label>
          <label>
            Password:
            <div style={{ position: 'relative' }}>
              <input
                type={isPasswordVisible ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                required
              />
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '25px',
                  top: '50%',
                  fontSize: '25px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
              >
                {isPasswordVisible ? '👁️' : '🙈'}
              </span>
            </div>
            {passwordError && <p className='error'>{passwordError}</p>}
          </label>
          <button
            type='submit'
            disabled={!!emailError || !!passwordError || loading}
          >
            {loading ? 'Logging in...' : 'Log In'}
          </button>
          <br />
          <br />
          <a
            href='#'
            onClick={handlePasswordReset}
            className='reset-password-link'
            style={{
              textDecoration: 'underline',
              color: 'white',
              cursor: 'pointer',
            }}
          >
            Forgot Password?
          </a>
          {message && <p className='message'>{message}</p>}
          {error && <p className='error'>{error}</p>}
        </form>
      </div>
      <Footer />
    </>
  );
};

export default LoginScreen;