import React, { useState, useEffect, useRef } from "react";
import { db, auth } from "./config/firebaseConfig"; // Add functions import
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  limit,
  orderBy,
} from "firebase/firestore";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Dot,
  ReferenceLine,
} from "recharts";
import EditClientForm from "./EditClientForm";
import Navbar from "./Navbar";
import Footer from "./Footer";
import MessageModal from "./MessageModal";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";


const TherapistDashboard = ({ therapistId }) => {
  // *******************************************************************************************
  // ******************************* CHECK IF USER LOGGED IN ***********************************
  // *******************************************************************************************
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/login");
      }
      if (user) {
        fetchSecretKey(auth.currentUser?.uid);
        fetchClients();
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noClient, setnoClient] = useState(true);
  const [error, setError] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null); // For editing client
  const [isEditing, setIsEditing] = useState(false); // Modal visibility
  const [isMessaging, setIsMessaging] = useState(false); // Message modal visibility
  const [therapistSecretKey, setTherapistSecretKey] = useState("");

  // *****************************************************************
  // ********** Fetch clients associated with this therapist *********
  // *****************************************************************
  const fetchClients = async () => {
    setLoading(true);
    try {
      /* if (!therapistId) {
        throw new Error('Therapist ID is undefined.');
      } */
      const therapistId = auth.currentUser?.uid;
      if (!therapistId) {
        throw new Error(
          "Therapist ID is undefined. Please ensure you are logged in.",
        );
      }

      const clientsQuery = query(
        collection(db, "users"),
        where("therapistId", "in", [therapistId, "demo"]),
        where("role", "==", "client"),
      );

      const querySnapshot = await getDocs(clientsQuery);
      const clientList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClients(clientList);
    } catch (error) {
      console.error("Error fetching clients:", error);
      setError("Failed to load clients. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const [selectedClientId, setSelectedClientId] = useState(null);

  // Open edit modal
  const openEditModal = (client) => {
    setSelectedClient(client);
    setIsEditing(true);
  };

  // Close modals
  const closeEditModal = () => {
    setSelectedClient(null);
    setIsEditing(false);
  };

  const closeMessagingModal = () => {
    setSelectedClient(null);
    setIsMessaging(false);
  };

  // Function to fetch the secret key for the currently registered therapist
  const fetchSecretKey = async (userId) => {
    try {
      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setTherapistSecretKey(docSnap.data().therapistSecretKey);
      }
    } catch (error) {
      console.error("Error fetching secret key:", error);
    }
  };

  // Move hooks outside of the callback function
  const [userId, setUserId] = useState(null);

  // Set userId based on the authenticated user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  const [feedback, setFeedback] = useState("");
  const handleFeedbackSubmit = async () => {
    if (!feedback.trim()) {
      alert("Please provide your feedback before submitting.");
      return;
    }
    setLoading(true);
    try {
      const feedbackDocRef = doc(db, "therapistFeedback", therapistId);
      await setDoc(
        feedbackDocRef,
        {
          therapistId,
          feedback,
          createdAt: serverTimestamp(),
        },
        { merge: true },
      );

      // setIsSubmitted(true);
      alert(
        "Thank you for your feedback! We will review it shortly. We value your input.",
      );
      setFeedback(""); // Clear the input
    } catch (error) {
      console.error("Error submitting feedback:", error);
      alert("Failed to submit feedback. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  //******************* 📜 Messages *******************
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [receiverId, setReceiverId] = useState(null);

  //*** 🖥️ Fetch messages from Firestore ***
  const fetchMessages = async (receiverId) => {
    setReceiverId(receiverId);
    setMessages([]);

    const messagesRef = collection(db, "messages");
    const q = query(
      messagesRef,
      where("senderId", "in", [userId, receiverId]),
      where("receiverId", "in", [userId, receiverId]),
      orderBy("timestamp"),
      limit(20),
    );

    const querySnapshot = await getDocs(q);
    const fetchedMessages = querySnapshot.docs.map((doc) => doc.data());
    setMessages(fetchedMessages);
  };

  //*** 🖥️ Handle message input change ***
  const handleInputChangeGoal = (e) => {
    setNewMessage(e.target.value);
  };

  //*** 🖥️ Send a new message ***
  const sendMessage = async () => {
    if (newMessage.trim() !== "") {
      const senderId = auth.currentUser?.uid;
      const timestamp = new Date();

      const message = {
        senderId: senderId,
        receiverId: receiverId,
        message: newMessage,
        timestamp: timestamp,
        messageType: "therapist", // or 'therapist' depending on the sender
      };

      await addDoc(collection(db, "messages"), message);
      setNewMessage("");
      fetchMessages(receiverId);
    }
  };

  // *** 🖥️ Load journal entry ***
  const [journalEntries, setJournalEntries] = useState([]);
  const emojiOptions = {
    0: "Happy", //
    1: "Slightly happy", //
    2: "Worried", //
    3: "Very distressed", //
  };

  const loadJournalEntries = async (userId) => {
    setJournalEntries([]);
    try {
      const userDoc = await getDoc(doc(db, "journals", userId));
      if (userDoc.exists()) {
        const journalData = userDoc.data().entries || {};
        const loadedEntries = [];

        Object.keys(journalData).forEach((date) => {
          const dateEntries = journalData[date];

          Object.keys(dateEntries).forEach((entryId) => {
            const entry = dateEntries[entryId];
            const emoji = emojiOptions[entry.score] || "😊";

            loadedEntries.push({
              date,
              entryId,
              text: entry.text,
              emoji,
              score: entry.score,
              timestamp: entry.timestamp,
            });
          });
        });

        loadedEntries.sort((a, b) => b.timestamp - a.timestamp);
        setJournalEntries(loadedEntries);
      }
    } catch (error) {
      console.error("Error loading journal entries:", error);
    }
  };

  //*** 🖥️ Fecth coupons ***
  const [history, setHistory] = useState([]);
  const fetchCoupons = async (userId) => {
    const userHistoryDoc = await getDoc(doc(db, "couponHistory", userId));
    if (userHistoryDoc.exists()) {
      setHistory(userHistoryDoc.data().history || []);
    } else {
      setHistory([]);
    }
  };

  //*** 🖥️ Fecth asssesments ***
  const [scores, setScores] = useState({ totalScore: 0 });
  const [scoresPHQ9, setScoresPHQ9] = useState({ totalScore: 0 });
  const [scoresGAD7, setScoresGAD7] = useState({ totalScore: 0 });

  const fetchAssessments = async (userId) => {
    setScores({ totalScore: 0 });
    setScoresPHQ9({ totalScore: 0 });
    setScoresGAD7({ totalScore: 0 });
    const userDocRef = doc(db, "YBOCS_assessment", userId); // Reference to the user document
    const formsCollectionRef = collection(userDocRef, "YBOCS_forms"); // Sub-collection for the forms

    try {
      // Query the sub-collection to get all forms, ordered by timestamp in descending order
      const q = query(formsCollectionRef, orderBy("timestamp", "desc")); // 'desc' for most recent first
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const formsData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();

          // Check if 'scores' field exists and is structured as expected
          if (data.scores && data.scores.total !== undefined) {
            formsData.push({
              timestamp: data.timestamp.toDate(),
              totalScore: data.scores.total,
              compulsions: data.scores.compulsions,
              obsessions: data.scores.obsessions,
            });
          }
        });

        if (formsData.length > 0) {
          const mostRecentForm = formsData[0]; // Most recent form
          setScores({ totalScore: mostRecentForm.totalScore }); // Update state with the total score
        } else {
          console.warn("No valid forms with scores found.");
        }
      } else {
        console.warn("No forms found in the database.");
      }
    } catch (error) {
      console.error("Error fetching YBOCS forms:", error);
    }

    const userDocRefPHQ9 = doc(db, "PHQ9_assessment", userId); // Reference to the user document
    const formsCollectionRefPHQ9 = collection(userDocRefPHQ9, "PHQ9_forms"); // Sub-collection for the forms

    try {
      const qPHQ9 = query(formsCollectionRefPHQ9, orderBy("timestamp", "desc")); // 'desc' for most recent first
      const querySnapshotPHQ9 = await getDocs(qPHQ9);

      if (!querySnapshotPHQ9.empty) {
        const formsDataPHQ9 = [];
        querySnapshotPHQ9.forEach((doc) => {
          const data = doc.data();

          // Check if 'scores' field exists and is structured as expected
          if (data.scores && data.scores.totalScore !== undefined) {
            formsDataPHQ9.push({
              timestamp: data.timestamp.toDate(),
              totalScore: data.scores.totalScore,
            });
          }
        });

        if (formsDataPHQ9.length > 0) {
          const mostRecentForm = formsDataPHQ9[0]; // Most recent form
          setScoresPHQ9({ totalScore: mostRecentForm.totalScore }); // Update state with the total score
        } else {
          console.warn("No valid forms with scores found.");
        }
      } else {
        console.warn("No PHQ-9 forms found in the database.");
      }
    } catch (error) {
      console.error("Error fetching PHQ-9 forms:", error);
    }

    const userDocRefGAD7 = doc(db, "GAD7_assessment", userId); // Reference to the user document
    const formsCollectionRefGAD7 = collection(userDocRefGAD7, "GAD7_forms"); // Sub-collection for the forms

    try {
      const qGAD7 = query(formsCollectionRefGAD7, orderBy("timestamp", "desc")); // 'desc' for most recent first
      const querySnapshotGAD7 = await getDocs(qGAD7);

      if (!querySnapshotGAD7.empty) {
        const formsDataGAD7 = [];
        querySnapshotGAD7.forEach((doc) => {
          const data = doc.data();

          // Check if 'scores' field exists and is structured as expected
          if (data.scores && data.scores.totalScore !== undefined) {
            formsDataGAD7.push({
              timestamp: data.timestamp.toDate(),
              totalScore: data.scores.totalScore,
            });
          }
        });

        if (formsDataGAD7.length > 0) {
          const mostRecentForm = formsDataGAD7[0]; // Most recent form
          setScoresGAD7({ totalScore: mostRecentForm.totalScore }); // Update state with the total score
        } else {
          console.warn("No valid forms with scores found.");
        }
      } else {
        console.warn("No GAD7 forms found in the database.");
      }
    } catch (error) {
      console.error("Error fetching PHQ-9 forms:", error);
    }
  };

  // *** 🖥️ Exposure hierarchy builder ***
  const [exposures, setExposures] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentExposureTitle, setCurrentExposureTitle] = useState("");
  const [exposureData, setExposureData] = useState({
    exposurePlan: "",
    fearedOutcome: "",
    responsePrevention: "",
    fearBelief: 0,
    confidence: 0,
    exposureOutcome: "",
    exposureVariation: "",
  });
  const distressColors = {
    1: "#61fa69", // Green for lowest distress
    2: "#76fa5e",
    3: "#8afa53",
    4: "#9efa47",
    5: "#aefa3c",
    6: "#c0d32d",
    7: "#d3b22f",
    8: "#e89c2a",
    9: "#f58c24",
    10: "#fa6161", // Red for highest distress
    null: "#fa6161",
  };

  const isRestrictedClient =
    selectedClientId === "ADjKRnX1iXduMuFV3GRmpjnAk7m1";

  //*** 🖥️ Function to get today's date in a readable format ***
  const getFormattedDate = () => {
    const today = new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return today.toLocaleDateString(undefined, options); // Format the date
  };
  const dateString = getFormattedDate();

  const loadExposures = async (userId) => {
    setExposures([]);
    try {
      const docRef = doc(db, "exposureHierarchy", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const exposuresData = docSnap.data().exposures || [];
        const exposuresWithLogs = await Promise.all(
          exposuresData.map(async (exposure) => {
            const logsRef = collection(db, "exposureHierarchy", userId, "Logs");
            const logQuery = query(
              logsRef,
              where("exposureTitle", "==", exposure.title), // Changed "title" to "exposureTitle"
              orderBy("timestamp", "desc"), // Ensure the logs are ordered by timestamp in descending order
              limit(1), // Only get the most recent log
            );
            const logSnap = await getDocs(logQuery);

            let confidence = null;
            let fearBelief = null;

            // Check if any logs were returned
            if (!logSnap.empty) {
              logSnap.forEach((logDoc) => {
                const logData = logDoc.data();
                confidence = logData.confidence || null; // Default to null if the field doesn't exist
                fearBelief = logData.fearBelief || null; // Default to null if the field doesn't exist
              });
            }

            return { ...exposure, confidence, fearBelief }; // Attach the last saved confidence and fearBelief to exposure
          }),
        );

        setExposures(exposuresWithLogs); // Set exposures with the latest logs data
      }
    } catch (error) {
      console.error("Error loading exposures:", error);
    }
  };

  // Calculate progress
  const completedCount = exposures.filter(
    (exposure) => exposure.completed,
  ).length;
  const totalCount = exposures.length;

  //*** 🖥️ Function to open modal when user clicks "Log Exposure" ***
  const openModal = (exposureTitle) => {
    setExposureData({
      exposurePlan: "",
      fearedOutcome: "",
      responsePrevention: "",
      fearBelief: 0,
      confidence: 0,
      exposureOutcome: "",
      exposureVariation: "",
    }); // Clear previous data when opening the modal for a new log
    setModalVisible(true);
    setCurrentExposureTitle(exposureTitle); // Store the title for later use in the log
  };

  //*** 🖥️ Function to close modal ***
  const closeModal = () => setModalVisible(false);

  // New state to hold the current exposure title for the modal

  //*** 🖥️ Load log history ***
  const [logHistory, setLogHistory] = useState([]); // State to store log history

  const loadLogHistory = async () => {
    const logsRef = collection(
      db,
      "exposureHierarchy",
      "nptDT1Ngd2enpd3yD7V8YNFVBuJ3",
      "Logs",
    );
    const logQuery = query(
      logsRef,
      where("exposureTitle", "==", currentExposureTitle),
      orderBy("timestamp", "desc"), // Sort by timestamp
    );

    try {
      const logSnap = await getDocs(logQuery);
      const logs = logSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLogHistory(logs); // Set the logs in state
    } catch (error) {
      console.error("Error loading log history:", error);
    }
  };

  //*** 🖥️ Call loadLogHistory when modal opens ***
  useEffect(() => {
    if (modalVisible) {
      loadLogHistory(); // Fetch the logs when modal is visible
    }
  }, [modalVisible, currentExposureTitle]); // Trigger on modal visibility and title change

  //*** 🖥️ Inline Styles for Modal and Form ***
  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 9999,
    },
    content: {
      backgroundColor: "#2e2e2e",
      padding: "30px",
      width: "90%",
      maxWidth: "500px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
      color: "#f0f0f0",
      fontSize: "12px",
    },
    inputGroup: {
      display: "flex",

      marginBottom: "5px",
    },
    input: {
      width: "230px",
      padding: "12px",
      borderRadius: "8px",
      border: "1px solid #ccc",
      backgroundColor: "#575656",
      color: "#fff",
      fontSize: "12px",
    },
    textarea: {
      width: "95%",
      padding: "12px",
      marginBottom: "5px",
      borderRadius: "8px",
      border: "1px solid #ccc",
      backgroundColor: "#575656",
      color: "#fff",
      fontSize: "12px",
      resize: "vertical", // Allows vertical resizing of textareas
    },
    button: {
      backgroundColor: "#4caf50",
      padding: "12px 20px",
      borderRadius: "8px",
      color: "#fff",
      fontSize: "12px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },

    cancelButton: {
      backgroundColor: "#555",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "20px",
    },
    motivationalText: {
      color: "white", // Set text color to yellow
      fontSize: "12px", // Adjust font size
      marginBottom: "5px", // Add some space below the text
      textAlign: "left", // Center the text
    },
    historyEntry: {
      cursor: "pointer",
    },
    historyEntryHover: {
      color: "#61fa69", // Text color changes to blue on hover
    },
  };

  //*** 🖥️ Render the modal window ***
  const modal = (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.content}>
        <h2
          style={{ color: "#f09a73", marginTop: "0px" }}
        >{`${dateString}`}</h2>
        {/* History Display */}
        <h3>Select an exposure history</h3>
        <div style={{ marginTop: "0px", maxHeight: "90px", overflowY: "auto" }}>
          {logHistory.length > 0 ? (
            logHistory.map((log, index) => (
              <div
                key={log.id}
                style={modalStyles.historyEntry}
                onClick={() => {
                  // Populate the form with this log's data
                  setExposureData({
                    exposurePlan: log.exposurePlan,
                    fearedOutcome: log.fearedOutcome,
                    responsePrevention: log.responsePrevention,
                    fearBelief: log.fearBelief,
                    confidence: log.confidence,
                    exposureOutcome: log.exposureOutcome,
                    exposureVariation: log.exposureVariation,
                  });
                }}
                onMouseEnter={(e) => (e.target.style.color = "#61fa69")} // Hover effect: set text color to blue
                onMouseLeave={(e) => (e.target.style.color = "white")}
              >
                <p style={{ lineHeight: "0.5" }}>
                  <strong>{index + 1}</strong> -{" "}
                  {new Date(log.timestamp.seconds * 1000).toLocaleString()}
                </p>
              </div>
            ))
          ) : (
            <p>No history found for this exposure.</p>
          )}
        </div>
        <br />

        <form>
          <textarea
            style={modalStyles.textarea}
            placeholder="Describe today's exposure plan:"
            value={exposureData.exposurePlan}
            rows="4"
          />
          <textarea
            style={modalStyles.textarea}
            placeholder="Describe your worst, feared outcome of this exposure:"
            value={exposureData.fearedOutcome}
            rows="4"
          />
          <textarea
            style={modalStyles.textarea}
            placeholder="Response Prevention Plan: What rituals, compulsions, avoidances and accommodations are you resisting during and after this exposure?"
            value={exposureData.responsePrevention}
            rows="4"
          />
          <p>
            Rate your belief in your fear coming true & Rate your confidence in
            tolerating your discomfort.
          </p>
          <div style={modalStyles.inputGroup}>
            <select
              style={modalStyles.input}
              value={exposureData.fearBelief || ""}
            >
              <option value="" disabled selected={!exposureData.fearBelief}>
                Select Fear Belief (1-10)
              </option>
              {[...Array(10)].map((_, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>

            <select
              style={{ ...modalStyles.input, marginLeft: "40px" }}
              value={exposureData.confidence || ""}
            >
              <option value="" disabled selected={!exposureData.confidence}>
                Select Confidence (1-10)
              </option>
              {[...Array(10)].map((_, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>

          <textarea
            style={modalStyles.textarea}
            placeholder="What was the exposure outcome? What did you learn? (Tie this answer to questions 1 & 2)"
            value={exposureData.exposureOutcome}
            rows="4"
          />
          <textarea
            style={modalStyles.textarea}
            placeholder="How can you vary this exposure next time and mix it up? (Change time, place, activity, combine with other fear cues and exposures)"
            value={exposureData.exposureVariation}
            rows="4"
          />

          <div style={modalStyles.buttonContainer}>
            <button
              type="button"
              onClick={closeModal}
              style={{ ...modalStyles.button, ...modalStyles.cancelButton }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  //************************** 📜 Mood Tracker **************************
  const statusBar1Ref = useRef(null);
  const statusBar2Ref = useRef(null);
  const statusBar3Ref = useRef(null);
  const statusBar4Ref = useRef(null);
  const [moodData, setMoodData] = useState({});
  const [emojiPercentages, setEmojiPercentages] = useState({
    1: 0, // For 😊
    2: 0, // For 🙂
    3: 0, // For 😟
    4: 0, // For 😫
  });
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const moodEmojis = {
    1: "😊",
    2: "🙂",
    3: "😟",
    4: "😫",
  };

  // *** 🖥️ Fetch mood data from Firestore ***
  const fetchMoodData = async (userId) => {
    setMoodData({});
    setEmojiPercentages({
      1: 0, // For 😊
      2: 0, // For 🙂
      3: 0, // For 😟
      4: 0, // For 😫
    });

    if (userId) {
      const docRef = doc(db, "moodTracker", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (Object.keys(data).length > 0) {
          setMoodData(data);
          updateEmojiPercentages(data);
        }
      }
      //setLoading(false);
    }
  };

  // *** 🖥️ Update the emoji percentages ***
  const updateEmojiPercentages = (updatedMoodData) => {
    const emojiCounts = Object.values(updatedMoodData).reduce(
      (counts, mood) => {
        counts[mood] = (counts[mood] || 0) + 1;
        return counts;
      },
      {},
    );
    const totalEntries = Object.values(updatedMoodData).filter(
      (value) => value !== null,
    ).length;
    const newEmojiPercentages = {};
    for (const mood in moodEmojis) {
      const count = emojiCounts[mood] || 0;
      newEmojiPercentages[mood] =
        totalEntries > 0 ? ((count / totalEntries) * 100).toFixed(0) : 0;
    }
    setEmojiPercentages(newEmojiPercentages);
  };

  // *** 🖥️ Render the grid with days and months ***
  const today = new Date();
  const todayKey = `${today.getMonth()}-${today.getDate()}`;
  const getDaysInMonth = (monthIndex, year) => {
    const date = new Date(year, monthIndex + 1, 0);
    return date.getDate();
  };
  const renderGrid = () => {
    const currentYear = new Date().getFullYear();
    return months.map((month, monthIndex) => {
      const daysInMonth = getDaysInMonth(monthIndex, currentYear);
      return (
        <div key={month} className="month-row">
          <div className="month-label">{month}</div>
          <div className="days-row">
            {Array.from({ length: daysInMonth }, (_, day) => {
              const key = `${monthIndex}-${day + 1}`;
              const isToday = key === todayKey;
              return (
                <div
                  key={day + 1}
                  className={`grid-cell mood-${moodData[key] || 0} ${isToday ? "today-square" : ""}`}
                >
                  {moodEmojis[moodData[key]] || ""}
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  // *** 🖥️ Function to generate status bars dynamically ***
  const generateStatusBar = (ref, percentage) => {
    if (ref.current) {
      const statusBar = ref.current;
      statusBar.innerHTML = "";
      const statusLabel = statusBar.previousElementSibling;
      statusLabel.textContent = `${percentage}%`;

      const filledCount = Math.round((percentage / 100) * 10);
      const colors = [];
      for (let i = 0; i < 10; i++) {
        const lightness = 60 - i * 6;
        const color = `hsl(120, 60%, ${lightness}%)`;
        colors.push(color);
      }

      for (let i = 0; i < filledCount; i++) {
        const symbol = document.createElement("span");
        symbol.classList.add("status-symbol");
        symbol.style.backgroundColor = colors[i];
        statusBar.appendChild(symbol);
      }

      for (let i = filledCount; i < 10; i++) {
        const symbol = document.createElement("span");
        symbol.classList.add("status-symbol", "empty");
        statusBar.appendChild(symbol);
      }
    }
  };

  useEffect(() => {
    if (statusBar1Ref.current && emojiPercentages[1] !== undefined) {
      generateStatusBar(statusBar1Ref, emojiPercentages[1] || 0);
    }
    if (statusBar2Ref.current && emojiPercentages[2] !== undefined) {
      generateStatusBar(statusBar2Ref, emojiPercentages[2] || 0);
    }
    if (statusBar3Ref.current && emojiPercentages[3] !== undefined) {
      generateStatusBar(statusBar3Ref, emojiPercentages[3] || 0);
    }
    if (statusBar4Ref.current && emojiPercentages[4] !== undefined) {
      generateStatusBar(statusBar4Ref, emojiPercentages[4] || 0);
    }
  }, [emojiPercentages]);

  //******************* 📜 OCD Goals *******************
  const [goals, setGoals] = useState({
    workingLife: "",
    homeLife: "",
    socialLife: "",
    relationships: "",
    personalLeisureTime: "",
  });

  // *** 🖥️ Fetch goals from Firestore ***
  const fetchGoals = async (userId) => {
    setGoals({
      workingLife: "",
      homeLife: "",
      socialLife: "",
      relationships: "",
      personalLeisureTime: "",
    });
    const docRef = doc(db, "ocdGoals", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setGoals(docSnap.data());
    }
  };

  //******************* 📜 Trigger Tracker *******************
  const [data, setData] = useState([]);
  const distressTriggerColors = {
    "😄": "#61fa69", // Green for lowest distress
    "🙂": "#8afa53",
    "😐": "#aefa3c",
    "😟": "#e89c2a",
    "😢": "#fa6161", // Red for highest distress
  };

  // *** 🖥️ Fetch trigger data from Firebase Firestore ***
  const loadTriggers = async (userId) => {
    setData([]);
    try {
      const docRef = doc(db, "ocdTriggers", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setData(docSnap.data().triggers || []);
      }
    } catch (error) {
      console.error("Error loading triggers:", error);
    }
  };

  //******************* 📜 SUDS Tracker *******************
  const [allHistoricalData, setAllHistoricalData] = useState([]);

  //*** 🖥️ Load all historical data from Firestore ***
  const loadAllHistoricalDataFromFirestore = async (userId) => {
    setAllHistoricalData([]);
    try {
      const docRef = doc(db, "ocdSuds", userId); // Reference to Firestore document
      const docSnap = await getDoc(docRef); // Get the document snapshot

      if (docSnap.exists()) {
        const data = docSnap.data();
        const formattedData = Object.keys(data).map((trigger) => ({
          trigger,
          data: splitDataBySession(data[trigger]), // Split data by session
        }));
        setAllHistoricalData(formattedData);
      } else {
        console.error("No SUDS data found for this user.");
      }
    } catch (error) {
      console.error("Error fetching data from Firestore:", error);
    }
  };

  //*** 🖥️ Function to split data by session whenever time = 0 ***
  const splitDataBySession = (data) => {
    const sessions = [];
    let currentSession = [];

    data.forEach((entry) => {
      if (entry.time === 0 && currentSession.length > 0) {
        sessions.push(currentSession);
        currentSession = [];
      }
      currentSession.push(entry);
    });

    if (currentSession.length > 0) {
      sessions.push(currentSession); // Push the last session if it exists
    }

    return sessions;
  };

  const CustomDot = (props) => {
    const { cx, cy, payload } = props;
    let fill = "lightblue";
    let r = 2;

    if (payload.level >= 80) {
      fill = "red";
      r = 4;
    } else if (payload.level <= 20) {
      fill = "green";
      r = 4;
    }

    return <Dot cx={cx} cy={cy} r={r} fill={fill} />;
  };

  const lineColors = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#8dd1e1", // Existing colors
    "#d45087",
    "#f95d6a",
    "#ff7c43",
    "#a05195",
    "#665191",
    "#2f4b7c",
    "#4caf50",
    "#ff5722",
    "#607d8b",
    "#795548",
    "#9c27b0",
    "#673ab7",
    "#2196f3",
    "#3f51b5",
  ];

  const displayStat = async (myclient) => {
    setnoClient(false);
    setSelectedClientId(myclient);
    fetchCoupons(myclient);
    loadJournalEntries(myclient);
    fetchAssessments(myclient);
    loadExposures(myclient);
    loadTriggers(myclient);
    fetchGoals(myclient);
    fetchMoodData(myclient);
    loadAllHistoricalDataFromFirestore(myclient);
    fetchMessages(myclient);
  };

  // *****************************************************************
  // ************************** RETURN *******************************
  // *****************************************************************
  return (
    <>
      <style>
        {`
					/* TherapistDashboard.css */

					body {
						background-color: #3e3e3e;
					}

					.therapist-dashboard {
						max-width: 1200px;
						margin: 20px auto;
						margin-top: 20px;
						padding: 20px;
						background-color: #1e1e1e;
						color: white;
						border-radius: 8px;
						box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

					}
					
					.therapist-dashboard p {
							font-size: 12px;
						}


					.blinking-red {
						color: #f09a73;
						font-weight: bold;
					}

					/* Style for the select dropdown */
					.select1-dropdown {
						display: block;
						width: 100%;
						padding: 10px;
						font-size: 12px;
						color: white;
						background-color: #4caf50;
						border: none;
						border-radius: 5px;
						appearance: none; /* Removes default styling in some browsers */
						-webkit-appearance: none; /* For Safari */
						-moz-appearance: none; /* For Firefox */
						cursor: pointer;
						transition: all 0.3s ease;
					}

					.select1-dropdown:focus {
						border-color: #007bff;
						outline: none;
						box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
					}

					.select1-dropdown option {
						padding: 10px;
						
					}

					/* Add some spacing between the dropdown and other elements */
					.select1-container {
						position: relative;
						width: 138px;
					}


					.therapist-dashboard .error {
						color: #d9534f;
						margin-top: 10px;
					}

					.therapist-dashboard .success {
						color: #4caf50;
						margin-top: 10px;
					}

					.modal-exposure-overlay {
						position: fixed;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;

						display: flex;
						justify-content: center;
						align-items: center;
					}

					.modal-exposure-content {
						background-color: #2e2e2e;
						padding: 20px;
						width: 90%;
						max-width: 800px;
						border-radius: 10px;
						box-shadow: 1 4px 8px rgba(255, 255, 255, 0.1);
					}

					.modal-exposure-content h2 {
						font-size: 1.5rem;
						margin-bottom: 15px;
						color: #f09a73;
						text-align: left;
					}

					/* Input and button on the same line */
					.input-exposure-group {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 20px; /* Space between input and actions */
					}

					.input-exposure-group input {
						width: 75%; /* Make the input field wider */
						padding: 10px;
						margin-right: 10px; /* Space between input and button */
						border-radius: 4px;
						border: 1px solid #ccc;
					}

					.modal-exposure-actions {
						margin-top: 20px;
						display: flex;
						justify-content: space-between;
					}

					/* General container for feature votes */

					.feature-container {
						
						margin: 0 0;
						padding: 20px;

					}
					/* Flexbox container for the features table */
					.feature-table {
						display: flex;
						flex-direction: column;
						margin-top: 10px;
					}

					/* Each feature row */
					.feature-row {
						display: flex;
						justify-content: space-between;
						align-items: left;
						background-color: #2e2e2e;
						border-radius: 10px;
						padding: 10px;
						font-size: 12px;
					}

					.feature1-row {
						display: flex;
						justify-content: space-between;
						align-items: left;
						background-color: #222222;
						border-radius: 10px;
						padding: 10px;
					}

					/* Style for each cell in the row */
					.feature-cell {
						flex: 0 0 auto;
						padding: 5px;
						text-align: left;
					}

					/* First column (Title) takes 20% */

					.feature-cell:nth-child(1) {
						width: 25%;
					}

					/* Second column (Description) takes 40% */

					.feature-cell:nth-child(2) {
						width: 50%;
					}

					.feature-cell:nth-child(3) {
						width: 10%;
					}
					.feature-cell:nth-child(4) {
						width: 15%;
					}

					/* Button styling */
					.feature-cell button {
						background-color: #4CAF50;
						color: white;
						border: none;
						padding: 10px 20px;
						border-radius: 5px;
						cursor: pointer;
						transition: background-color 0.3s ease;
					}

					.feature-cell button:hover {
						background-color: #45a049;
					}

					/* Vote count styling */
					.feature-cell:last-child {
						font-weight: bold;
						color: white;
					}

					.feature1-cell {
						flex: 0 0 auto;
						padding: 5px;
						padding-top: 1px;
						padding-bottom: 1px;
						text-align: left;
					}

					/* First column (Title) takes 20% */

					.feature1-cell:nth-child(1) {
						width: 15%;
					}

					/* Second column (Description) takes 40% */

					.feature1-cell:nth-child(2) {
						width: 25%;
					}

					.feature1-cell:nth-child(3) {
						width: 43%;
					}
					.feature1-cell:nth-child(4) {
						width: 17%;
					}
					
					.link-btn {
							padding: 8px 12px;
							margin: 5px 0;
							border: none;
							background-color: #4caf50;
							color: white;
							font-size: 14px;
							border-radius: 4px;
							cursor: pointer;
							transition: background-color 0.2s ease;
						}

						.link-btn:hover {
							background-color: #45a049;

						}
						
						.loading-spinner {
							border: 8px solid #f3f3f3;
							border-top: 8px solid #3498db;
							border-radius: 50%;
							width: 60px;
							height: 60px;
							animation: spin 1s linear infinite;
							margin: 20px auto;
						}
						
						.loading-container {
							display: flex;
							justify-content: center;
							align-items: center;
							height: 100vh;
						}
						
						.dashboard-container {
							display: grid;
							grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
							grid-gap: 20px;
							padding: 20px;
						}
						
						.dashboard-title {
							align-items: center;
							font-size: 1.2em;
							font-weight: bold;
							color: white;
						}
						
							.tile {
							position: relative;
							background-color: #222222;
							color: white;
							border-radius: 8px;
							padding: 20px;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
							overflow: hidden;
						}

						.tile::before,
						.tile::after {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							border-radius: 8px;
						}

						.tile::before {
							border: 4px double #333;
							z-index: -1;
						}

						.tile::after {
							border: 2px solid #444;
							z-index: -2;
						}

						.tile .nested-tile {
							position: relative;
							background-color: #2b2b2b;
							/* Darker background for the nested tile */
							color: #fff;
							border-radius: 8px;
							padding: 15px;
							box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
							/* Shadow for nested tile */
						}

						.tile .nested-tile::before,
						.tile .nested-tile::after {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							border-radius: 8px;
						}

						.tile .nested-tile::before {
							border: 4px double #444;
							/* Outer double border effect for nested tile */
							z-index: -1;
							/* Keep the border behind the tile content */
						}

						.tile .nested-tile::after {
							border: 2px solid #555;
							/* Inner border for nested tile */
							z-index: -2;
							/* Keep this behind the other borders */
						}

						/* Example: Set size for specific tiles */
						.tile-1 {
							grid-column: span 2;
							/* Span across 2 columns */
							grid-row: span 2;
							/* Span across 2 rows */
						}
						
						.tile-2 {
							grid-column: span 1;
							/* Standard width */
							grid-row: span 1;
							/* Standard height */
						}
						
						.tile-header {
							display: flex;
							align-items: center;
							font-size: 1.2em;
							font-weight: bold;
						}

						.tile-header .icon {
							font-size: 1em;
						}


						.tile-content {
							flex-grow: 1;
						}


						.tile-content p {
							font-size: 12px;
						}


						.tile .icon {
							width: 20px;
							height: 20px;
							margin-right: 10px;
						}
						
						.event-link {
							color: #007bff;
							text-decoration: none;
							font-size: 10px;
						}

						.event-link:hover {
							text-decoration: underline;
						}
						.coupon-history1 {
							table-layout: fixed;
							border-collapse: collapse;

							width: 100%;
						}

						.coupon-history1 th,
						.coupon-history1 td {
							border: 0px solid #ccc;
							padding: 0px;

							word-wrap: break-word;
						}

						.coupon-history1 th {
							background-color: #575656;
							font-size: 12px;
							text-align: center;
						}

						.coupon-history1 td {
							line-height: 0.8rem;
							font-size: 12px;
							text-align: left;
							padding-right: 3px;
						}
						
						/* Entries List */
						.entries-list {
							margin-top: 10px;
							font-size: 12px;
							align-items: top;

						}
						
							.entries-list th {
							background-color: #575656;
							text-align: center;
						}

						.entries-list td {
							line-height: 0.8rem;
							font-size: 10px;
							text-align: left;
							padding-right: 3px;
						}

						.entries-list li span {
							font-weight: normal;
							color: #777;
						}
						
						.exposure ul {
							list-style-type: none;
							margin-left: 5px;
							padding: 5px;
							border: 0px solid white;
							background-color: #333;
							border-radius: 4px;
						}

						.exposure li {
							display: flex;
							align-items: center;
							justify-content: right;
							gap: 5px;
							padding: 0px;
							font-size: 12px;
							margin-bottom: 0px;

						}

						.exposure-btn {
							padding: 3px 3px;
							margin: 1px;
							border: none;
							background-color: #4caf50;
							color: white;
							font-size: 12px;
							border-radius: 4px;
							cursor: pointer;
							transition: background-color 0.2s ease;
						}

						.exposure-btn:hover {
							background-color: #45a049;

						}
						
						.tracker-table {
							table-layout: fixed;
							border-collapse: collapse;

							width: 100%;
						}

						.tracker-table th,
						.tracker-table td {
							border: 0px solid #ccc;
							padding: 0px;

							word-wrap: break-word;
						}

						.tracker-table th {
							background-color: #575656;
							font-size: 12px;
							text-align: center;
						}

						.tracker-table td {
							line-height: 0.8rem;
							font-size: 10px;
							text-align: left;
							padding-right: 3px;
						}

						.goal-content textarea {

							width: 95%;
							height: 70px;
							margin-bottom: 10px;
							border-radius: 5px;
							font-size: 12px;
						}
					
											.status-row {
							display: flex;
							align-items: right;
							margin-bottom: 0px;
							margin-left: 60%;
							padding: 0;
							line-height: 1;
							color: white;
							font-size: 12px;
							justify-content: space-between;
							width: 200px;
						}

						.status-row .icon {

							font-size: 14px;
						}

						.status-bar {
							display: inline-block;
							width: 130px;
							height: 20px;

						}

						.status-symbol {
							display: inline-block;
							width: 10%;
							height: 100%;
							background-color: #e0e0e0;
						}

						.status-bar .status-symbol {
							margin-right: 2px;
							/* Space between blocks */
							width: 10px;
							/* Size of each block */
							height: 10px;
							/* Size of each block */
							text-align: center;
							color: white;
							/* Default color for text */
							font-weight: bold;
							display: inline-block;
						}

						.empty {
							background-color: #343635;
						}

						.emoji-percentages {
							display: flex;
							justify-content: space-around;
							flex-wrap: wrap;
							/* Allow wrapping for smaller screens */
							padding: 10px;
							margin: auto;
							margin-bottom: 20px;
							border-radius: 8px;
							font-size: 1.0em;
							box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
							max-width: 700px;
							/* Center within max width */
							background-color: #1e1e1e;
						}

						.emoji-percentages div {
							display: flex;
							align-items: center;
							gap: 20px;
							/* Default gap */
							margin: 5px 10px;
							/* Margin for wrapped items */
							font-weight: bold;
							color: hsl(0, 0%, 100%);
						}

						.emoji-percentages div::before {
							content: attr(data-emoji);
							font-size: 1.0em;
						}

						.emoji-percentages {
							font-size: 0.5rem;
							/* Slightly smaller font size */
						}

						.emoji-percentages div {
							gap: 5px;
							/* Reduce gap on smaller screens */
						}

						.container1-mood {
							margin: auto;
							/* Fills the viewport height */
							font-size: 0.8em;
						}

						.calendar-grid {
							margin-top: 25px;
							margin-bottom: 20px;
							display: flex;
							flex-direction: column;
							line-height: normal
						}

						.month-row {
							display: flex;
							align-items: center;
							margin-bottom: 5px;
						}

						.month-label {
							width: 50px;
							text-align: right;
							margin-right: 10px;
							font-size: 12px;
						}

						.days-row {
							display: grid;
							grid-template-columns: repeat(31, 15px);
							/* 31 columns for days */
							gap: 1px;
						}

						.grid-cell {
							width: 15px;
							height: 15px;
							background-color: #333;
							border-radius: 3px;
							cursor: pointer;
						}

						.grid-cell.mood-1 {
							background-color: #4caf50;
							/* Happy */
						}

						.grid-cell.mood-2 {
							background-color: #ffeb3b;
							/* Neutral */
						}

						.grid-cell.mood-3 {
							background-color: #4b30e6;
						}

						.grid-cell.mood-4 {
							background-color: #f44336;
							/* Sad */
						}

						/* Highlight today's square */
						.today-square {
							border: 2px solid #ff9800;
							/* Bright orange border */
							animation: pulse 1s infinite;
							/* Optional subtle animation */
						}

						/* Optional pulse animation */
						@keyframes pulse {
							0% {
								box-shadow: 0 0 5px #ff9800;
							}

							50% {
								box-shadow: 0 0 15px #ff9800;
							}

							100% {
								box-shadow: 0 0 5px #ff9800;
							}
						}
						
						/* Message Board */
						.message-board {
							display: flex;
							flex-direction: column;
						}

						/* Messages List */
						.messages-list {
							max-height: 400px;
							overflow-y: scroll;
							margin-bottom: 10px;
							border: 0px solid #ddd;
							border-radius: 8px;
							padding: 10px;
							background-color: #333;
							font-size: 12px;
						}

						/* Individual Message Styles */
						.message {
							padding: 10px;
							margin-bottom: 10px;
							border-radius: 8px;


						}

						.message.patient {
							background-color: #1e1e1e;
							color: #4caf50;
							font-size: 12px;
						}

						.message.therapist {
							background-color: #1e1e1e;
							color: #007bff;
							font-size: 12px;
							text-align: right;
						}

						.message.admin {
							background-color: #faf261;
							color: black;
							font-size: 12px;
							text-align: right;
						}

						.message p {
							margin: 0;
							font-size: 12px;
						}

						.message span {
							font-size: 10px;
							color: white;
						}

						/* Send Message Area */
						.send-message {
							display: flex;
							flex-direction: column;
						}

						.send-message textarea {
							width: 90%;
							height: 50px;
							margin-bottom: 10px;

							padding: 10px;
							border-radius: 8px;
							border: 1px solid #ccc;
							font-size: 12px;
							resize: none;
						}

						.send-message button:disabled {
							background-color: #aaa;
							cursor: not-allowed;
						}

					
					`}
      </style>
      <Navbar />
      <div className="therapist-dashboard">
        <h2 className="dashboard-title">
          ↔️ How To Access Your Patients' Dashboard
        </h2>
        <p>
          ✅ Share your "Therapist Secret Key" (
          <span className="blinking-red">{therapistSecretKey}</span>) with your
          patient.
          <br />
          ✅ Ask him/her to register on the website and enter your “Therapist
          Secret Key” in the “Therapist Access” tile on their dashboard.
          <br />
        </p>
        <br />
        {/* Client List */}
        <section>
          <h2 className="dashboard-title">👥 Your Patients</h2>

          {loading ? (
            <p>Loading patients...</p>
          ) : error ? (
            <p className="error">{error}</p>
          ) : clients.length > 0 ? (
            <>
              <div>
                <div className="feature-container">
                  <div className="feature1-row">
                    <div className="feature1-cell">
                      <strong>Alias</strong>
                    </div>
                    <div className="feature1-cell">
                      <b>email</b>
                    </div>
                    <div className="feature1-cell">
                      <b>Comment</b>
                    </div>
                    <div className="feature1-cell"></div>
                  </div>
                  {clients.map((client) => (
                    <div key={client.id} className="feature-table">
                      <div className="feature-row">
                        <div className="feature1-cell">
                          <strong>{client.alias}</strong>
                        </div>
                        <div className="feature1-cell">{client.email}</div>
                        <div className="feature1-cell">{client.comment}</div>
                        <div
                          className="feature1-cell"
                          style={{ width: "100px" }}
                        >
                          <button
                            onClick={() => openEditModal(client)}
                            className="link-btn"
                          >
                            Edit Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                
              </div>
            </>
          ) : (
            <p>No clients added yet.</p>
          )}
        </section>

        {/* Edit Client Modal */}
        {isEditing && selectedClient && (
          <EditClientForm
            client={selectedClient}
            onClose={closeEditModal}
            onUpdate={fetchClients}
          />
        )}

        {/* Message Client Modal */}
        {isMessaging && selectedClient && (
          <MessageModal
            client={selectedClient}
            therapistId={auth.currentUser?.uid}
            onClose={closeMessagingModal}
          />
        )}
      </div>
      <div className="therapist-dashboard">
        <h2 className="dashboard-title">📊 Patients' Personal Dashboard</h2>
        <p>Select a patient:</p>
        <div>
          {clients.map((client) => (
            <button
              key={client.id}
              className="link-btn"
              style={{ marginLeft: "5px" }}
              onClick={() => displayStat(client.id)}
            >
              {client.alias}
            </button>
          ))}
        </div>
        <br />

        {noClient ? (
          <p>No patient selected.</p>
        ) : (
          <>
            <div className="dashboard-container">
              <div class="tile tile-2">
                <div class="tile-header">
                  <span class="icon">🎟️</span>Reassurance Coupon
                </div>
                <div class="tile-content">
                  <p style={{ textAlign: "center" }}>
                    ➡️{" "}
                    <a
                      href="https://www.ocdserenity.com/Reassurance-coupons.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="event-link"
                    >
                      Download printable coupons here
                    </a>{" "}
                    ⬅️
                  </p>
                  <div className="coupon-history1">
                    <h4 style={{ marginTop: "5px", marginBottom: "5px" }}>
                      Coupon History
                    </h4>
                    {history.length > 0 ? (
                      <>
                        <table className="history-table">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Coupons Given</th>
                            </tr>
                          </thead>
                          <tbody>
                            {history.map((entry, index) => (
                              <tr key={index}>
                                <td>{entry.timestamp}</td>
                                <td>
                                  {entry.coupons} (
                                  {Array.from(entry.coupons).length / 2})
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <p style={{ textAlign: "center", color: "#4caf50" }}>
                        No history available.
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="tile tile-1" style={{ maxHeight: "800px" }}>
                <div className="tile-header">
                  <span className="icon">⏱</span>Journaling
                </div>
                <div className="tile-content">
                  <h4>Previous Entries</h4>
                  <div
                    className="entries-list"
                    style={{ maxHeight: "400px", overflowY: "auto" }}
                  >
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <thead>
                        <tr
                          style={{
                            position: "sticky",
                            top: 0,
                            backgroundColor: "#fff",
                            zIndex: 1,
                          }}
                        >
                          <th style={{ width: "50px" }}>Date</th>
                          <th style={{ width: "50px" }}>Mood</th>
                          <th>Entry</th>
                        </tr>
                      </thead>
                      <tbody>
                        {journalEntries.map((entry, index) => {
                          // Define colors for each emoji value
                          const emojiColors = {
                            Happy: "#61fa69",
                            "Slightly happy": "#faf261",
                            Worried: "#fa9961",
                            "Very distressed": "#fa6161",
                          };

                          // Get the color corresponding to the emoji
                          const textColor = emojiColors[entry.emoji] || "black"; // Default to black if emoji doesn't match

                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  backgroundColor: textColor,
                                  color: "black",
                                  paddingLeft: "5px",
                                }}
                              >
                                {entry.date}
                              </td>
                              <td
                                style={{
                                  backgroundColor: textColor,
                                  color: "black",
                                }}
                              >
                                {entry.emoji}
                              </td>
                              <td
                                style={{
                                  backgroundColor: textColor,
                                  color: "black",
                                }}
                              >
                                {entry.text}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="tile tile-2" style={{ height: "190px" }}>
                <div class="tile-header">
                  <span class="icon">📝</span>Assessment Score
                </div>
                <div class="tile-content">
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "space-between",

                      alignItems: "center",
                    }}
                  >
                    <b>
                      Y-BOCS:
                      <span style={{ fontSize: "18px", color: "#4caf50" }}>
                        &nbsp;{scores.totalScore || "N/A"}
                      </span>
                    </b>
                    <button
                      onClick={() =>
                        navigate(`/ybocs-statistics`, {
                          state: { clientId: "nptDT1Ngd2enpd3yD7V8YNFVBuJ3" },
                        })
                      }
                      className="link-btn"
                    >
                      Y-BOCS History
                    </button>
                  </p>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <b>
                      PHQ-9:
                      <span style={{ fontSize: "18px", color: "#4caf50" }}>
                        &nbsp;{scoresPHQ9.totalScore || "N/A"}
                      </span>
                    </b>
                    <button
                      onClick={() =>
                        navigate(`/phq9-statistics`, {
                          state: { clientId: "nptDT1Ngd2enpd3yD7V8YNFVBuJ3" },
                        })
                      }
                      className="link-btn"
                    >
                      PHQ-9 History
                    </button>
                  </p>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "space-between",

                      alignItems: "center",
                    }}
                  >
                    <b>
                      GAD-7:
                      <span style={{ fontSize: "18px", color: "#4caf50" }}>
                        &nbsp;{scoresGAD7.totalScore || "N/A"}
                      </span>
                    </b>
                    <button
                      onClick={() =>
                        navigate(`/gad7-statistics`, {
                          state: { clientId: "nptDT1Ngd2enpd3yD7V8YNFVBuJ3" },
                        })
                      }
                      className="link-btn"
                    >
                      GAD-7 History
                    </button>
                  </p>
                </div>
              </div>

              <div class="tile tile-1">
                <div class="tile-header">
                  <span class="icon">🚀</span>Exposure Hierarchy Builder
                </div>
                <div class="tile-content">
                  {/* Progress bar */}

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h4>Progress:&nbsp;</h4>
                    <div
                      style={{
                        width: "65%",
                        backgroundColor: "#ddd",
                        height: "20px",
                        marginRight: "10px",
                      }}
                    >
                      <div
                        style={{
                          width: `${(completedCount / totalCount) * 100}%`,
                          height: "100%",
                          backgroundColor: "#4caf50",
                        }}
                      ></div>
                    </div>
                    <span style={{ fontSize: "12px" }}>
                      {completedCount}/{totalCount} completed
                    </span>
                  </div>

                  {/* List of exposures */}
                  <div className="exposure">
                    <table className="tracker-table">
                      <thead>
                        <tr>
                          <th>Exposure Title</th>
                          <th style={{ width: "30px" }}>Fear</th>
                          <th style={{ width: "75px" }}>Confidence</th>
                          <th style={{ width: "110px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {exposures
                          .filter((exposure) => !exposure.completed)
                          .sort((a, b) => b.fearBelief - a.fearBelief)
                          .map((exposure) => {
                            // Get the color for the specific distress value
                            const backgroundColor =
                              distressColors[exposure.fearBelief];

                            return (
                              <tr
                                key={exposure.id}
                                style={{ backgroundColor, color: "black" }}
                              >
                                <td style={{ paddingLeft: "5px" }}>
                                  {exposure.title}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {exposure.fearBelief || "N/A"}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {exposure.confidence || "N/A"}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <button
                                    className="exposure-btn"
                                    onClick={() => openModal(exposure.title)}
                                    style={{ backgroundColor: "#1e1e1e" }}
                                  >
                                    Exposure History
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>

                    <h4>Completed Exposures</h4>
                    <table className="tracker-table">
                      <thead>
                        <tr>
                          <th>Exposure Title</th>
                          <th style={{ width: "30px" }}>Fear</th>
                          <th style={{ width: "75px" }}>Confidence</th>
                        </tr>
                      </thead>
                      <tbody>
                        {exposures
                          .filter((exposure) => exposure.completed)
                          .sort((a, b) => b.fearBelief - a.fearBelief)
                          .map((exposure) => {
                            const backgroundColor =
                              distressColors[exposure.fearBelief];

                            return (
                              <tr
                                key={exposure.id}
                                style={{ backgroundColor, color: "black" }}
                              >
                                <td
                                  style={{
                                    paddingLeft: "5px",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {exposure.title}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {exposure.fearBelief || "N/A"}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {exposure.confidence || "N/A"}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="tile tile-1" style={{ maxHeight: "800px" }}>
                <div class="tile-header">
                  <span class="icon">🎯</span>Trigger Tracker
                </div>
                <div class="tile-content">
                  <h3>Recorded Triggers</h3>
                  {data.length > 0 ? (
                    <table className="tracker-table">
                      <thead>
                        <tr>
                          <th>Trigger</th>
                          <th>Thought</th>
                          <th>Compulsion</th>
                          <th>Relief</th>
                          <th style={{ width: "30px" }}>Fear</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((entry, index) => {
                          const backgroundColor =
                            distressTriggerColors[entry.distress];
                          return (
                            <tr
                              key={index}
                              style={{ backgroundColor, color: "black" }}
                            >
                              <td style={{ paddingLeft: "5px" }}>
                                {entry.trigger}
                              </td>
                              <td>{entry.thought}</td>
                              <td>{entry.compulsion}</td>
                              <td>{entry.relief}</td>
                              <td style={{ fontSize: "15px" }}>
                                {entry.distress}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <p style={{ color: "#4caf50" }}>
                      No triggers recorded yet.
                    </p>
                  )}
                </div>
              </div>

              <div className="tile tile-1" style={{ maxHeight: "760px" }}>
                <div className="tile-header">
                  <span className="icon">⏱</span>Goals
                </div>
                <div className="tile-content">
                  <br />
                  <div className="goal-content">
                    <label>
                      Working Life
                      <textarea
                        name="workingLife"
                        value={goals.workingLife}
                        style={{
                          backgroundColor: "#575656",
                          color: "#fff",
                          border: "1px solid #ccc",
                        }}
                      />
                    </label>
                    <label>
                      Home Life
                      <textarea
                        name="homeLife"
                        value={goals.homeLife}
                        style={{
                          backgroundColor: "#575656",
                          color: "#fff",
                          border: "1px solid #ccc",
                        }}
                      />
                    </label>
                    <label>
                      Social Life
                      <textarea
                        name="socialLife"
                        value={goals.socialLife}
                        style={{
                          backgroundColor: "#575656",
                          color: "#fff",
                          border: "1px solid #ccc",
                        }}
                      />
                    </label>
                    <label>
                      Relationships
                      <textarea
                        name="relationships"
                        value={goals.relationships}
                        style={{
                          backgroundColor: "#575656",
                          color: "#fff",
                          border: "1px solid #ccc",
                        }}
                      />
                    </label>
                    <label>
                      Personal Leisure Time
                      <textarea
                        name="personalLeisureTime"
                        value={goals.personalLeisureTime}
                        style={{
                          backgroundColor: "#575656",
                          color: "#fff",
                          border: "1px solid #ccc",
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="tile tile-1" style={{ height: "420px" }}>
                <div className="tile-header">
                  <span className="icon">😊</span> Mood Tracker
                </div>
                <div className="tile-content">
                  <div className="status-row">
                    <span className="icon">😊</span>
                    <span id="status-label-1">{emojiPercentages[1] || 0}%</span>
                    <div ref={statusBar1Ref} className="status-bar"></div>
                  </div>
                  <div className="status-row">
                    <span className="icon">🙂</span>
                    <span id="status-label-2">{emojiPercentages[2] || 0}%</span>
                    <div ref={statusBar2Ref} className="status-bar"></div>
                  </div>
                  <div className="status-row">
                    <span className="icon">😟</span>
                    <span id="status-label-3">{emojiPercentages[3] || 0}%</span>
                    <div ref={statusBar3Ref} className="status-bar"></div>
                  </div>
                  <div className="status-row">
                    <span className="icon">😫</span>
                    <span id="status-label-4">{emojiPercentages[4] || 0}%</span>
                    <div ref={statusBar4Ref} className="status-bar"></div>
                  </div>

                  <div className="container1-mood">
                    <div className="calendar-grid">{renderGrid()}</div>
                  </div>
                </div>
              </div>

              <div class="tile tile-1">
                <div class="tile-header">
                  <span class="icon">⏱️</span>SUDS Tracker
                </div>
                <div class="tile-content">
                  {allHistoricalData.length > 0 ? (
                    allHistoricalData.map((ritualData, ritualIndex) => {
                      //const summary = calculateSummary(ritualData); // Get the summary for this ritual
                      if (ritualData.trigger === "rituals") {
                        return null; // Do not render anything if the trigger is 'rituals'
                      }
                      return (
                        <div key={ritualIndex} className="ritual-graph">
                          <h3>
                            Recorded anxiety levels for{" "}
                            <span className="fear-color">
                              {ritualData.trigger}
                            </span>
                          </h3>
                          <div
                            className="responsive-wrapper"
                            style={{ marginRight: "20px" }}
                          >
                            <ResponsiveContainer width="100%" height={300}>
                              <LineChart>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                  type="number"
                                  dataKey="time"
                                  tickFormatter={(value) => `${value} min`}
                                  label={{
                                    value: "Time (min)",
                                    position: "insideBottomRight",
                                    offset: -5,
                                  }}
                                />
                                <YAxis
                                  domain={[0, 100]}
                                  ticks={[0, 20, 40, 60, 80, 100]}
                                  label={{
                                    value: "Anxiety Level",
                                    angle: -90,
                                    position: "insideLeft",
                                  }}
                                />
                                <Tooltip />
                                <Legend />

                                <ReferenceLine y={80} stroke="red" />
                                <ReferenceLine y={20} stroke="green" />

                                {ritualData.data.map(
                                  (session, sessionIndex) => {
                                    return (
                                      <Line
                                        key={sessionIndex}
                                        data={session}
                                        type="monotone"
                                        dataKey="level"
                                        stroke={
                                          lineColors[
                                            sessionIndex % lineColors.length
                                          ]
                                        }
                                        strokeWidth={2}
                                        dot={<CustomDot />}
                                        name={`Session ${sessionIndex + 1}`}
                                      />
                                    );
                                  },
                                )}
                              </LineChart>
                            </ResponsiveContainer>
                          </div>
                          {/* Trend, Comparative, and Progress summary for this ritual */}
                        </div>
                      );
                    })
                  ) : (
                    <p style={{ textAlign: "center", color: "#4caf50" }}>
                      No history available.
                    </p>
                  )}
                </div>
              </div>

              <div className="tile tile-2">
                <div className="tile-header">
                  <span className="icon">💬</span> Messages
                </div>
                <div className="tile-content">
                  <p>Stay in touch with your patient.</p>
                  <div className="message-board">
                    <div className="messages-list">
                      {messages
                        .slice()
                        .reverse()
                        .map((msg, index) => (
                          <div
                            key={index}
                            className={`message ${msg.messageType}`}
                          >
                            <p>
                              <b>{msg.messageType === "therapist" && "You:"}</b>
                              <b>
                                {msg.messageType === "patient" && "Patient: "}
                              </b>
                              <b>
                                {msg.messageType === "admin" &&
                                  "OCDSereniry Admin: "}
                              </b>
                              <br />
                              <i>{msg.message}</i>
                            </p>
                            <span>
                              {new Date(
                                msg.timestamp.seconds * 1000,
                              ).toLocaleTimeString()}
                            </span>
                          </div>
                        ))}
                    </div>

                    <div className="send-message">
                      <textarea
                        value={newMessage}
                        onChange={handleInputChangeGoal}
                        placeholder="Type your message here..."
                        style={{
                          backgroundColor: "#575656",
                          color: "#fff",
                          border: "1px solid #ccc",
                        }}
                      ></textarea>
                      <button className="link-btn" onClick={sendMessage} disabled={isRestrictedClient}>
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="therapist-dashboard">
        <h2 className="dashboard-title">💡 Share Your Suggestions</h2>
        <p>
          Is there a tool, feature, or method you’d like us to implement ? Let
          us know your ideas so we can make the app better for you and your
          patients.
        </p>
        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Type your suggestion here..."
          rows="5"
          style={{
            width: "50%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            marginBottom: "10px",
            backgroundColor: "#575656",
            color: "white",
          }}
        ></textarea>
        <br />
        <button
          onClick={handleFeedbackSubmit}
          disabled={loading}
          style={{ cursor: loading ? "not-allowed" : "pointer" }}
          className="link-btn"
        >
          {loading ? "Submitting..." : "Submit Feedback"}
        </button>
      </div>
      {modalVisible && modal} {/* Render the modal if modalVisible is true */}
      <Footer />
    </>
  );
};

export default TherapistDashboard;
