import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { auth, db } from './config/firebaseConfig';
import './Navbar.css';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // *** 🖥️ Check user role ***
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const role = userDocSnap.data().role;
            setUserRole(role);
          } 
        } catch (err) {
          console.error('Error fetching user document:', err);
          setError('Error fetching user information.');
        }
      } else {
        // Clear userRole if no user is logged in.
        setUserRole('');
      }
    });

    // Cleanup subscription on unmount.
    return () => unsubscribe();
  }, []);

  // *** 🖥️ Toggle menu ***
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  // *** 🖥️ Logout ***
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Optionally render an error message if something went wrong.
  if (error) {
    return (
      <div className="error-message">
        <p>{error}</p>
      </div>
    );
  }

  // Render different navbar options based on the user role.
  if (userRole === 'client') {
    return (
      <nav className="navbar">
        <div className="logo">
          <Link to="/">
            <div>OCD Serenity</div>
          </Link>
        </div>
        <div className={`burger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
        <div className={`navbar-text ${menuOpen ? 'open' : ''}`}>
          <ul className="nav-links">
            <li>
              <Link to="/client-dashboard">📊 Dashboard</Link>
            </li>
            <li onClick={handleLogout}>
              <span>👤 Logout</span>
            </li>
          </ul>
        </div>
      </nav>
    );
  } else if (userRole === 'therapist') {
    return (
      <nav className="navbar">
        <div className="logo">
          <Link to="/">
            <div>OCD Serenity</div>
          </Link>
        </div>
        <div className={`burger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
        <div className={`navbar-text ${menuOpen ? 'open' : ''}`}>
          <ul className="nav-links">
            <li>
              <Link to="/client-dashboard">📊 Dashboard</Link>
            </li>
            <li>
              <Link to="/therapistdashboard">📈 Therapist Dashboard</Link>
            </li>
            <li onClick={handleLogout}>
              <span>👤 Logout</span>
            </li>
          </ul>
        </div>
      </nav>
    );
  } else {
    // For unauthenticated users or unrecognized roles.
    return (
      <nav className="navbar">
        <div className="logo">
          <Link to="/">
            <div>OCD Serenity</div>
          </Link>
        </div>
        <div className={`burger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
        <div className={`navbar-text ${menuOpen ? 'open' : ''}`}>
          <ul className="nav-links">
          <li>
              <Link to='/yale-brown'>📝 OCD Test (Y-BOCS)</Link>
            </li>
            <li>
              <Link to="/blog">📚 Blog</Link>
            </li>
            {/* Temporarily removed during beta 
            <li>
              <Link to="/pricing">💰 Pricing</Link>
            </li>
            */}
            <li className="menu-item">
              <span style={{ padding: '10px' }}>🧭 How to use</span>
              <ul className="submenu">
                <li>
                  <Link to="/how-to-ocd">🧠 If You Have OCD</Link>
                </li>
                <li>
                  <Link to="/how-to-therapist">🩺 You Are A Therapist</Link>
                </li>
                <li>
                  <Link to="/get-a-demo">🖥️ Our Demo</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/ourvision">🚀 Our Vision</Link>
            </li>
            <li className="menu-item">
              <span style={{ padding: '10px', marginLeft: '100px' }}>👥 Login/Register</span>
              <ul className="submenu">
                <li>
                  <Link to="/login">👤 Login</Link>
                </li>
                <li>
                  <Link to="/registerasclient">👤 Register</Link>
                </li>
                <li>
                  <Link to="/registerastherapist">🧑‍⚕️ Register as Therapist</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;
