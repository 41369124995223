import React, { useState, useEffect, useRef } from "react";
import Navbar from "./Navbar";
import {
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  collection,
  where,
  updateDoc,
  addDoc,
  serverTimestamp,
  arrayUnion,
  orderBy,
  limit,
  deleteDoc,
  deleteField,
} from "firebase/firestore";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Dot,
  ReferenceLine,
} from "recharts";
import { auth, db } from "./config/firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

const ClientDashboard = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  //******************* 📜 Dashboard Settings *******************
  const [userSettings, setUserSettings] = useState({});
  const tilesList = [
    { id: "therapistAccess", name: "Therapist Access" },
    { id: "moodTracker", name: "Mood Tracker" },
    { id: "triggerTracker", name: "Trigger Tracker" },
    { id: "reassuranceCoupon", name: "Reassurance Coupon" },
    { id: "messagesBoard", name: "Messages" },
    { id: "upcomingEvents", name: "Upcoming Events" },
    { id: "assessmentScore", name: "Assessment Score" },
    { id: "exposureHierarchyBuilder", name: "Exposure Hierarchy Builder" },
    { id: "journaling", name: "Journaling" },
    { id: "sudsTracker", name: "SUDS Tracker" },
    { id: "goals", name: "Goals" },
  ];

  //*** 🖥️ Fetch user settings from Firestore ***
  const fetchUserSettings = async () => {
    try {
      const docRef = doc(db, "userSettings", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserSettings(docSnap.data().tiles);
      } else {
        // If no settings are found, default to showing all tiles
        setUserSettings({
          therapistAccess: true,
          moodTracker: true,
          triggerTracker: true,
          dashboardSettings: true, // Default settings for all tiles
          reassuranceCoupon: true,
          messagesBoard: true,
          upcomingEvents: true,
          assessmentScore: true,
          exposureHierarchyBuilder: true,
          journaling: true,
          sudsTracker: true,
          goals: true,
        });
      }
    } catch (error) {
      console.error("Error fetching user settings:", error);
    }
  };

  //*** 🖥️ Handle the toggling of tiles visibility ***
  const handleTileToggle = async (tileId) => {
    const updatedSettings = {
      ...userSettings,
      [tileId]: !userSettings[tileId],
    };
    const docRef = doc(db, "userSettings", user.uid);
    await setDoc(docRef, {
      tiles: updatedSettings,
    });
    setUserSettings(updatedSettings);
  };

  //******************* 📜 SUDS Tracker *******************
  const [importedAnxietyLevels, setImportedAnxietyLevels] = useState("");
  const [importedInterval, setImportedInterval] = useState("5"); // Default to 5 min
  const [importedRitual, setImportedRitual] = useState("");
  const fearTriggers = [
    "Arranging items symmetrically",
    "Avoiding cracks on sidewalks",
    "Avoiding unlucky numbers (e.g., 13)",
    "Cleaning excessively to remove germs",
    "Checking appliances (e.g., stove, oven) repeatedly",
    "Checking door locks multiple times",
    "Counting objects in surroundings (e.g., tiles, cars)",
    "Counting steps while walking",
    "Organizing items by color or size",
    "Placing objects in a specific order (e.g., books, utensils)",
    "Re-arranging clothing or accessories until perfect",
    "Re-checking alarm clock settings multiple times",
    "Re-reading or re-writing text for errors",
    'Repeating actions until they feel "right"',
    'Repeating prayers or mantras for "protection"',
    "Seeking constant reassurance from others",
    "Touching objects a specific number of times",
    "Turning lights on and off repeatedly",
    "Washing hands repeatedly",
  ];
  const [rituals, setRituals] = useState(fearTriggers); // Default rituals
  const [allHistoricalData, setAllHistoricalData] = useState([]);

  //*** 🖥️ Load all historical data from Firestore ***
  const loadAllHistoricalDataFromFirestore = async () => {
    try {
      const docRef = doc(db, "ocdSuds", user.uid); // Reference to Firestore document
      const docSnap = await getDoc(docRef); // Get the document snapshot

      if (docSnap.exists()) {
        const data = docSnap.data();
        const formattedData = Object.keys(data).map((trigger) => ({
          trigger,
          data: splitDataBySession(data[trigger]), // Split data by session
        }));
        setAllHistoricalData(formattedData);
      } else {
        console.error("No SUDS data found for this user.");
      }
    } catch (error) {
      console.error("Error fetching data from Firestore:", error);
    }
  };

  //*** 🖥️ Function to split data by session whenever time = 0 ***
  const splitDataBySession = (data) => {
    const sessions = [];
    let currentSession = [];

    data.forEach((entry) => {
      if (entry.time === 0 && currentSession.length > 0) {
        sessions.push(currentSession);
        currentSession = [];
      }
      currentSession.push(entry);
    });

    if (currentSession.length > 0) {
      sessions.push(currentSession); // Push the last session if it exists
    }

    return sessions;
  };

  const CustomDot = (props) => {
    const { cx, cy, payload } = props;
    let fill = "lightblue";
    let r = 2;

    if (payload.level >= 80) {
      fill = "red";
      r = 4;
    } else if (payload.level <= 20) {
      fill = "green";
      r = 4;
    }

    return <Dot cx={cx} cy={cy} r={r} fill={fill} />;
  };

  const lineColors = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#8dd1e1", // Existing colors
    "#d45087",
    "#f95d6a",
    "#ff7c43",
    "#a05195",
    "#665191",
    "#2f4b7c",
    "#4caf50",
    "#ff5722",
    "#607d8b",
    "#795548",
    "#9c27b0",
    "#673ab7",
    "#2196f3",
    "#3f51b5",
  ];

  //*** 🖥️ Handle data import and save to Firebase ***
  const handleImportSubmit = async () => {
    // Parse the anxiety levels from the input
    const levelsArray = importedAnxietyLevels
      .split(",")
      .map((level) => parseInt(level.trim(), 10))
      .filter((level) => !isNaN(level));

    if (importedRitual && levelsArray.length > 0) {
      const newLevels = levelsArray.map((level, index) => ({
        time: index * parseInt(importedInterval, 10),
        level: level,
      }));

      try {
        //const user = auth.currentUser; // Get current authenticated user

        // Reference to the user's ritual data in Firestore
        const docRef = doc(db, "ocdSuds", user.uid);
        const docSnap = await getDoc(docRef);
        let storedData = {};
        if (docSnap.exists()) {
          storedData = docSnap.data();
        }

        // Flatten the data to avoid nested arrays
        const updatedData = storedData[importedRitual]
          ? [...storedData[importedRitual], ...newLevels]
          : newLevels;

        // Save updated data back to Firestore
        await setDoc(
          docRef,
          { [importedRitual]: updatedData }, // Save the updated ritual's data
          { merge: true }, // Merge with existing data
        );

        //alert('Data imported successfully.');
        loadAllHistoricalDataFromFirestore();
        setImportedAnxietyLevels(""); // Clear the input
      } catch (error) {
        console.error("Error saving data to Firestore:", error);
        alert("An error occurred while saving data.");
      }
    } else {
      alert("Please provide valid data and select a ritual.");
    }
  };

  //*** 🖥️ Fetch rituals from Firestore ***
  const fetchRituals = async () => {
    const docRef = doc(db, "ocdSuds", user.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().rituals) {
      //setRituals(docSnap.data().rituals);
      setRituals((prevRituals) => [...docSnap.data().rituals, ...fearTriggers]);
    }
  };

  //*** 🖥️ ADD CUSTOM RIRTUALS ***
  const [newRitual, setNewRitual] = useState("");

  const addRitual = async () => {
    if (newRitual.trim()) {
      const updatedRituals = [newRitual.trim(), ...rituals];
      setRituals(updatedRituals); // Update state with the new rituals list
      //localStorage.setItem('rituals', JSON.stringify(updatedRituals)); // Save updated rituals to local storage

      try {
        const docRef = doc(db, "ocdSuds", user.uid);

        // Fetch existing ritual data from Firestore
        const docSnap = await getDoc(docRef);
        let storedRituals = [];
        if (docSnap.exists()) {
          storedRituals = docSnap.data().rituals || [];
        }

        // Add the new ritual to the stored rituals
        const updatedFirestoreRituals = [newRitual.trim(), ...storedRituals];

        // Save updated rituals list back to Firestore
        await setDoc(
          docRef,
          { rituals: updatedFirestoreRituals },
          { merge: true },
        );

        alert(
          "Ritual added successfully. You can now select it from the ritual list.",
        );
      } catch (error) {
        console.error("Error saving ritual to Firestore:", error);
        alert("An error occurred while saving the ritual.");
      }

      setNewRitual(""); // Clear the input field after adding
    } else {
      console.log("No ritual was added, input was empty.");
    }
  };

  //*** 🖥️ ADD CUSTOM RIRTUALS ***
  const deleteRitual = async (trigger) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete data for ${trigger}?`,
    );
    if (!confirmDelete) {
      return; // Exit the function if the user cancels
    }
    try {
      const docRef = doc(db, "ocdSuds", user.uid);
      await updateDoc(docRef, {
        [trigger]: deleteField(),
      });
      setAllHistoricalData((prevData) =>
        prevData.filter((data) => data.trigger !== trigger),
      );
      console.log(`${trigger} data deleted successfully.`);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  //******************* 📜 Reassurance Coupon *******************
  const [history, setHistory] = useState([]);
  const [couponsGiven, setCouponsGiven] = useState(0);

  //*** 🖥️ Handle coupons change ***
  const handleCouponsChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value) && value >= 0) {
      setCouponsGiven(value);
    }
  };

  //*** 🖥️ Fecth coupons ***
  const fetchCoupons = async (userId) => {
    const userHistoryDoc = await getDoc(doc(db, "couponHistory", userId));
    if (userHistoryDoc.exists()) {
      setHistory(userHistoryDoc.data().history || []);
    } else {
      setHistory([]);
    }
  };

  //*** 🖥️ Save coupons ***
  const logCoupons = async () => {
    const newEntry = {
      coupons: "🎟️".repeat(couponsGiven),
      timestamp: new Date().toLocaleDateString(),
    };
    const updatedHistory = [...history, newEntry];
    setHistory(updatedHistory);

    const userHistoryRef = doc(db, "couponHistory", user.uid);
    await setDoc(
      userHistoryRef,
      { history: arrayUnion(newEntry) },
      { merge: true },
    );
    setCouponsGiven(0);
  };

  //*** 🖥️ Clear coupons history***
  const clearCouponsHistory = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to clear all coupons history?",
    );
    if (confirmed) {
      if (user.uid) {
        const userHistoryRef = doc(db, "couponHistory", user.uid);
        await updateDoc(userHistoryRef, { history: [] });
      }
      setHistory([]);
    }
  };

  //******************* 📜 Assessment score *******************
  const [scores, setScores] = useState({ totalScore: 0 });
  const [scoresPHQ9, setScoresPHQ9] = useState({ totalScore: 0 });
  const [scoresGAD7, setScoresGAD7] = useState({ totalScore: 0 });

  //*** 🖥️ Fecth asssesments ***
  const fetchAssessments = async (userId) => {
    const userDocRef = doc(db, "YBOCS_assessment", userId); // Reference to the user document
    const formsCollectionRef = collection(userDocRef, "YBOCS_forms"); // Sub-collection for the forms

    try {
      // Query the sub-collection to get all forms, ordered by timestamp in descending order
      const q = query(formsCollectionRef, orderBy("timestamp", "desc")); // 'desc' for most recent first
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const formsData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();

          // Check if 'scores' field exists and is structured as expected
          if (data.scores && data.scores.total !== undefined) {
            formsData.push({
              timestamp: data.timestamp.toDate(),
              totalScore: data.scores.total,
              compulsions: data.scores.compulsions,
              obsessions: data.scores.obsessions,
            });
          }
        });

        if (formsData.length > 0) {
          const mostRecentForm = formsData[0]; // Most recent form
          setScores({ totalScore: mostRecentForm.totalScore }); // Update state with the total score
        } else {
          console.warn("No valid forms with scores found.");
        }
      } else {
        console.warn("No forms found in the database.");
      }
    } catch (error) {
      console.error("Error fetching YBOCS forms:", error);
    }

    const userDocRefPHQ9 = doc(db, "PHQ9_assessment", userId); // Reference to the user document
    const formsCollectionRefPHQ9 = collection(userDocRefPHQ9, "PHQ9_forms"); // Sub-collection for the forms

    try {
      const qPHQ9 = query(formsCollectionRefPHQ9, orderBy("timestamp", "desc")); // 'desc' for most recent first
      const querySnapshotPHQ9 = await getDocs(qPHQ9);

      if (!querySnapshotPHQ9.empty) {
        const formsDataPHQ9 = [];
        querySnapshotPHQ9.forEach((doc) => {
          const data = doc.data();

          // Check if 'scores' field exists and is structured as expected
          if (data.scores && data.scores.totalScore !== undefined) {
            formsDataPHQ9.push({
              timestamp: data.timestamp.toDate(),
              totalScore: data.scores.totalScore,
            });
          }
        });

        if (formsDataPHQ9.length > 0) {
          const mostRecentForm = formsDataPHQ9[0]; // Most recent form
          setScoresPHQ9({ totalScore: mostRecentForm.totalScore }); // Update state with the total score
        } else {
          console.warn("No valid forms with scores found.");
        }
      } else {
        console.warn("No PHQ-9 forms found in the database.");
      }
    } catch (error) {
      console.error("Error fetching PHQ-9 forms:", error);
    }

    const userDocRefGAD7 = doc(db, "GAD7_assessment", userId); // Reference to the user document
    const formsCollectionRefGAD7 = collection(userDocRefGAD7, "GAD7_forms"); // Sub-collection for the forms

    try {
      const qGAD7 = query(formsCollectionRefGAD7, orderBy("timestamp", "desc")); // 'desc' for most recent first
      const querySnapshotGAD7 = await getDocs(qGAD7);

      if (!querySnapshotGAD7.empty) {
        const formsDataGAD7 = [];
        querySnapshotGAD7.forEach((doc) => {
          const data = doc.data();

          // Check if 'scores' field exists and is structured as expected
          if (data.scores && data.scores.totalScore !== undefined) {
            formsDataGAD7.push({
              timestamp: data.timestamp.toDate(),
              totalScore: data.scores.totalScore,
            });
          }
        });

        if (formsDataGAD7.length > 0) {
          const mostRecentForm = formsDataGAD7[0]; // Most recent form
          setScoresGAD7({ totalScore: mostRecentForm.totalScore }); // Update state with the total score
        } else {
          console.warn("No valid forms with scores found.");
        }
      } else {
        console.warn("No GAD7 forms found in the database.");
      }
    } catch (error) {
      console.error("Error fetching PHQ-9 forms:", error);
    }
  };

  //******************* 📜 Messages *******************
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  //const senderId = user.uid; // The current user's uid
  //const receiverId = "therapistOrPatientUID"; // The receiver's uid should be fetched dynamically or passed as a prop

  //*** 🖥️ Fetch messages from Firestore ***
  const fetchMessages = async (userId) => {
    const messagesRef = collection(db, "messages");
    	let therapistId;
        const userRef = doc(db, "users", userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists() && userDoc.data().therapistId) {
        	therapistId = userDoc.data().therapistId;
        	} else {
        	therapistId = userId;
        	}    
    const q = query(
      messagesRef,
      where("senderId", "in", [userId, therapistId]),
      where("receiverId", "in", [userId, therapistId]),
      orderBy("timestamp"),
      limit(20),
    );

    const querySnapshot = await getDocs(q);
    const fetchedMessages = querySnapshot.docs.map((doc) => doc.data());
    setMessages(fetchedMessages);
  };

  //*** 🖥️ Handle message input change ***
  const handleInputChangeGoal = (e) => {
    setNewMessage(e.target.value);
  };

  //*** 🖥️ Send a new message ***
  const sendMessage = async () => {
    if (newMessage.trim() !== "") {
    	let therapistId;
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists() && userDoc.data().therapistId) {
        	therapistId = userDoc.data().therapistId;
        	}
      const timestamp = new Date();

      const message = {
        senderId: user.uid,
        receiverId: therapistId,
        message: newMessage,
        timestamp: timestamp,
        messageType: "patient", // or 'therapist' depending on the sender
      };

      await addDoc(collection(db, "messages"), message);
      setNewMessage("");
      fetchMessages(user.uid);
    }
  };

  //******************* 📜 OCD Goals *******************
  const [goals, setGoals] = useState({
    workingLife: "",
    homeLife: "",
    socialLife: "",
    relationships: "",
    personalLeisureTime: "",
  });

  // *** 🖥️ Fetch goals from Firestore ***
  const fetchGoals = async (userId) => {
    const docRef = doc(db, "ocdGoals", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setGoals(docSnap.data());
    }
  };

  //*** 🖥️ Handle goal changes ***
  const handleGoalChange = (e) => {
    const { name, value } = e.target;
    setGoals((prevGoals) => ({
      ...prevGoals,
      [name]: value,
    }));
  };

  //*** 🖥️ Update goals in Firestore ***
  const handleUpdateGoals = async () => {
    const docRef = doc(db, "ocdGoals", user.uid);
    const updatedAt = new Date();
    await setDoc(docRef, { ...goals, lastUpdated: updatedAt });
    alert("Your goals have been updated!");
  };

  //******************* 📜 Upcoming OCD events *******************
  const [events, setEvents] = useState([]);

  // *** 🖥️ Fetch OCD events from Firestore ***
  const loadEvents = async () => {
    const querySnapshot = await getDocs(collection(db, "ocdEvents"));
    const eventsData = querySnapshot.docs.map((doc) => doc.data());
    setEvents(eventsData);
  };

  //******************* 📜 Journaling *******************
  const [entryText, setEntryText] = useState("");
  const [entryScore, setEntryScore] = useState(0); // Assuming score is a number (e.g., 0 to 4 for emoji ratings)
  const [journalEntries, setJournalEntries] = useState([]);
  const emojiOptions = {
    0: "Happy", //
    1: "Slightly happy", //
    2: "Worried", //
    3: "Very distressed", //
  };

  // *** 🖥️ Delete journal entry ***
  const deleteJournalEntry = async (entryId, date) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this journal entry?",
    );
    if (confirmed) {
      try {
        const userDocRef = doc(db, "journals", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const journalData = userDoc.data().entries || {};

          if (journalData[date] && journalData[date][entryId]) {
            delete journalData[date][entryId];

            if (Object.keys(journalData[date]).length === 0) {
              delete journalData[date];
            }

            await updateDoc(userDocRef, {
              entries: journalData,
            });

            console.log(`Entry ${entryId} from ${date} has been deleted.`);
            loadJournalEntries(user.uid);
          } else {
            console.log("Entry not found.");
          }
        } else {
          console.log("User journal document not found.");
        }
      } catch (error) {
        console.error("Error deleting journal entry:", error);
      }
    }
  };

  // *** 🖥️ Save journal entry ***
  const handleSubmitJournal = async (event) => {
    event.preventDefault();
    if (!entryText) return;

    const date = new Date().toISOString().split("T")[0];
    const entryId = new Date().toISOString();
    const newEntry = {
      text: entryText,
      score: entryScore,
      timestamp: serverTimestamp(),
    };

    try {
      const userDocRef = doc(db, "journals", user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const existingEntries = userDocSnap.data().entries || {};
        const dateEntries = existingEntries[date] || {};
        dateEntries[entryId] = newEntry;

        await updateDoc(userDocRef, {
          [`entries.${date}`]: dateEntries,
        });
      } else {
        await setDoc(userDocRef, {
          entries: {
            [date]: { [entryId]: newEntry },
          },
        });
      }

      setEntryText("");
      setEntryScore(0);
      loadJournalEntries(user.uid);
    } catch (error) {
      console.error("Error saving journal entry:", error);
    }
  };

  // *** 🖥️ Load journal entry ***
  const loadJournalEntries = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "journals", userId));
      if (userDoc.exists()) {
        const journalData = userDoc.data().entries || {};
        const loadedEntries = [];

        Object.keys(journalData).forEach((date) => {
          const dateEntries = journalData[date];

          Object.keys(dateEntries).forEach((entryId) => {
            const entry = dateEntries[entryId];
            const emoji = emojiOptions[entry.score] || "😊";

            loadedEntries.push({
              date,
              entryId,
              text: entry.text,
              emoji,
              score: entry.score,
              timestamp: entry.timestamp,
            });
          });
        });

        loadedEntries.sort((a, b) => b.timestamp - a.timestamp);
        setJournalEntries(loadedEntries);
      }
    } catch (error) {
      console.error("Error loading journal entries:", error);
    } finally {
      setLoading(false);
    }
  };

  //******************* 📜 Trigger Tracker *******************
  const [trigger, setTrigger] = useState("");
  const [thought, setThought] = useState("");
  const [compulsion, setCompulsion] = useState("");
  const [relief, setRelief] = useState("");
  const [distress, setDistress] = useState("😄");
  const [editIndex, setEditIndex] = useState(null);
  const [data, setData] = useState([]);
  const distressTriggerColors = {
    "😄": "#61fa69", // Green for lowest distress
    "🙂": "#8afa53",
    "😐": "#aefa3c",
    "😟": "#e89c2a",
    "😢": "#fa6161", // Red for highest distress
  };

  // *** 🖥️ Fetch trigger data from Firebase Firestore ***
  const loadTriggers = async (userId) => {
    try {
      const docRef = doc(db, "ocdTriggers", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setData(docSnap.data().triggers || []);
      } else {
        console.log("No trigger data found.");
      }
    } catch (error) {
      console.error("Error loading triggers:", error);
    }
  };

  // *** 🖥️ Save trigger data to Firebase Firestore ***
  const saveToFirestore = async (updatedData) => {
    if (!user) return;
    const docRef = doc(db, "ocdTriggers", user.uid);
    try {
      await setDoc(docRef, { triggers: updatedData });
    } catch (error) {
      console.error("Error saving triggers:", error);
    }
  };

  //*** 🖥️ Function to delete ocdTriggers for a specific user ***
  const deleteOcdTriggers = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to clear all Triggers history?",
    );
    if (confirmed) {
      const docRef = doc(db, "ocdTriggers", user.uid); // Reference to the user's ocdTriggers document

      try {
        await deleteDoc(docRef); // Delete the document
        setData([]);
      } catch (error) {
        console.error("Error deleting ocdTriggers:", error);
      }
    }
  };

  // *** 🖥️ Handle form submission (Add or Edit) ***
  const handleSubmit = (e) => {
    e.preventDefault();

    const newEntry = {
      date: new Date().toLocaleDateString(),
      trigger,
      thought,
      compulsion,
      relief,
      distress: distress || "😄",
    };

    let updatedData;
    if (editIndex !== null) {
      updatedData = [...data];
      updatedData[editIndex] = newEntry;
      setEditIndex(null);
    } else {
      updatedData = [...data, newEntry];
    }

    setData(updatedData);
    saveToFirestore(updatedData);

    // Clear form fields
    setTrigger("");
    setThought("");
    setCompulsion("");
    setRelief("");
    //setDistress("😄");
  };

  // *** 🖥️ Handle edit action ***
  const handleEdit = (index) => {
    const entry = data[index];
    setTrigger(entry.trigger);
    setThought(entry.thought);
    setCompulsion(entry.compulsion);
    setRelief(entry.relief);
    setDistress(entry.distress);
    setEditIndex(index);
  };

  // *** 🖥️ Handle delete action ***
  const handleDelete = (index) => {
    const updatedData = data.filter((_, i) => i !== index);
    setData(updatedData);
    saveToFirestore(updatedData);
  };

  //******************* 📜 Exposure hierarchy builder *******************
  const [exposures, setExposures] = useState([]);
  const [newExposure, setNewExposure] = useState("");
  const [distressLevel, setDistressLevel] = useState(0);
  const [editId, setEditId] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false); // New flag to track if data is loaded
  const [modalVisible, setModalVisible] = useState(false);
  const [exposureData, setExposureData] = useState({
    exposurePlan: "",
    fearedOutcome: "",
    responsePrevention: "",
    fearBelief: 0,
    confidence: 0,
    exposureOutcome: "",
    exposureVariation: "",
  });
  const distressColors = {
    1: "#61fa69", // Green for lowest distress
    2: "#76fa5e",
    3: "#8afa53",
    4: "#9efa47",
    5: "#aefa3c",
    6: "#c0d32d",
    7: "#d3b22f",
    8: "#e89c2a",
    9: "#f58c24",
    10: "#fa6161", // Red for highest distress
    null: "#fa6161",
  };

  //*** 🖥️ Function to get today's date in a readable format ***
  const getFormattedDate = () => {
    const today = new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return today.toLocaleDateString(undefined, options); // Format the date
  };
  const dateString = getFormattedDate();

  const loadExposures = async (userId) => {
    try {
      const docRef = doc(db, "exposureHierarchy", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const exposuresData = docSnap.data().exposures || [];
        const exposuresWithLogs = await Promise.all(
          exposuresData.map(async (exposure) => {
            const logsRef = collection(db, "exposureHierarchy", userId, "Logs");
            const logQuery = query(
              logsRef,
              where("exposureTitle", "==", exposure.title), // Changed "title" to "exposureTitle"
              orderBy("timestamp", "desc"), // Ensure the logs are ordered by timestamp in descending order
              limit(1), // Only get the most recent log
            );
            const logSnap = await getDocs(logQuery);

            let confidence = null;
            let fearBelief = null;

            // Check if any logs were returned
            if (!logSnap.empty) {
              logSnap.forEach((logDoc) => {
                const logData = logDoc.data();
                confidence = logData.confidence || null; // Default to null if the field doesn't exist
                fearBelief = logData.fearBelief || null; // Default to null if the field doesn't exist
              });
            }
            return { ...exposure, confidence, fearBelief }; // Attach the last saved confidence and fearBelief to exposure
          }),
        );

        setExposures(exposuresWithLogs); // Set exposures with the latest logs data
      }
      setDataLoaded(true); // Set the flag to true once data is loaded
    } catch (error) {
      console.error("Error loading exposures:", error);
      setDataLoaded(true); // Ensure flag is set even on error
    }
  };

  //*** 🖥️  Function to delete exposureHierarchy for user.uid ***
  const handleClearExposureData = async () => {
    if (
      window.confirm("Are you sure you want to delete all the expsoures data?")
    ) {
      try {
        // Get the reference to the "exposureHierarchy" document for the current user
        const docRef = doc(db, "exposureHierarchy", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // Delete all logs in the "Logs" subcollection first
          const logsRef = collection(db, "exposureHierarchy", user.uid, "Logs");
          const logSnap = await getDocs(logsRef);
          logSnap.forEach(async (logDoc) => {
            await deleteDoc(logDoc.ref); // Delete each log document
          });

          // Now delete the exposures themselves from the "exposureHierarchy"
          const exposureRef = doc(db, "exposureHierarchy", user.uid);
          await deleteDoc(exposureRef); // Delete the exposureHierarchy document
          setExposures([]);
          console.log(
            "Exposure hierarchy and associated logs deleted successfully.",
          );
        } else {
          console.log("No exposure hierarchy found for the user.");
        }
      } catch (error) {
        console.error("Error deleting exposure hierarchy:", error);
      }
    }
  };

  //*** 🖥️ Save exposures to Firebase, only if data has already been loaded ***
  useEffect(() => {
    if (user && dataLoaded) {
      saveExposures(user.uid, exposures);
    }
  }, [exposures, user, dataLoaded]);

  //*** 🖥️ Save exposures to Firebase ***
  const saveExposures = async (userId, exposuresData) => {
    try {
      const docRef = doc(db, "exposureHierarchy", userId);
      await setDoc(docRef, { exposures: exposuresData });
    } catch (error) {
      console.error("Error saving exposures:", error);
    }
  };

  //*** 🖥️ Function to add a new exposure ***
  const addExposure = () => {
    if (newExposure) {
      const newEntry = {
        id: Date.now(),
        title: newExposure,
        distress: 10,
        completed: false,
      };
      setExposures([...exposures, newEntry]);
      setNewExposure("");
      setDistressLevel(0);
      loadExposures(user.uid);
    }
  };

  //*** 🖥️ Function to delete an exposure ***
  const deleteExposure = (id) => {
    if (window.confirm("Are you sure you want to delete this expsoure ?")) {
      setExposures(exposures.filter((exposure) => exposure.id !== id));
    }
  };

  //*** 🖥️ Function to update an exposure ***
  const updateExposure = () => {
    setExposures(
      exposures.map((exposure) =>
        exposure.id === editId
          ? { ...exposure, title: newExposure, distress: distressLevel }
          : exposure,
      ),
    );
    setEditId(null);
    setNewExposure("");
    setDistressLevel(0);
  };

  //*** 🖥️  Toggle completion status ***
  const toggleCompletion = (id) => {
    setExposures(
      exposures.map((exposure) =>
        exposure.id === id
          ? { ...exposure, completed: !exposure.completed }
          : exposure,
      ),
    );
  };

  // Calculate progress
  const completedCount = exposures.filter(
    (exposure) => exposure.completed,
  ).length;
  const totalCount = exposures.length;

  //*** 🖥️ Handle Modal Submit (Save Log) - Update to save under specific exposure title ***
  const handleLogExposureSubmit = async () => {
    const newLog = {
      ...exposureData,
      timestamp: new Date(),
      exposureTitle: currentExposureTitle, // Use the current exposure title stored in state
    };

    try {
      // Add the log under the specific exposure title document in the Logs collection
      await addDoc(
        collection(db, "exposureHierarchy", user.uid, "Logs"), // Logs is the subcollection
        newLog, // Each log will be a document in the Logs collection
      );
      setModalVisible(false);
      loadExposures(user.uid);
    } catch (error) {
      console.error("Error saving exposure log:", error);
    }
  };

  //*** 🖥️ Handle form input changes ***
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setExposureData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  //*** 🖥️ Function to open modal when user clicks "Log Exposure" ***
  const openModal = (exposureTitle) => {
    setExposureData({
      exposurePlan: "",
      fearedOutcome: "",
      responsePrevention: "",
      fearBelief: 0,
      confidence: 0,
      exposureOutcome: "",
      exposureVariation: "",
    }); // Clear previous data when opening the modal for a new log
    setModalVisible(true);
    setCurrentExposureTitle(exposureTitle); // Store the title for later use in the log
  };

  //*** 🖥️ Function to close modal ***
  const closeModal = () => setModalVisible(false);

  // New state to hold the current exposure title for the modal
  const [currentExposureTitle, setCurrentExposureTitle] = useState("");

  //*** 🖥️ Load log history ***
  const [logHistory, setLogHistory] = useState([]); // State to store log history

  const loadLogHistory = async () => {
    const logsRef = collection(db, "exposureHierarchy", user.uid, "Logs");
    const logQuery = query(
      logsRef,
      where("exposureTitle", "==", currentExposureTitle),
      orderBy("timestamp", "desc"), // Sort by timestamp
    );

    try {
      const logSnap = await getDocs(logQuery);
      const logs = logSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLogHistory(logs); // Set the logs in state
    } catch (error) {
      console.error("Error loading log history:", error);
    }
  };

  //*** 🖥️ Call loadLogHistory when modal opens ***
  useEffect(() => {
    if (modalVisible) {
      loadLogHistory(); // Fetch the logs when modal is visible
    }
  }, [modalVisible, currentExposureTitle]); // Trigger on modal visibility and title change

  //*** 🖥️ Inline Styles for Modal and Form ***
  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: 9999,
    },
    content: {
      backgroundColor: "#2e2e2e",
      padding: "30px",
      width: "90%",
      maxWidth: "500px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
      color: "#f0f0f0",
      fontSize: "12px",
    },
    inputGroup: {
      display: "flex",

      marginBottom: "5px",
    },
    input: {
      width: "230px",
      padding: "12px",
      borderRadius: "8px",
      border: "1px solid #ccc",
      backgroundColor: "#575656",
      color: "#fff",
      fontSize: "12px",
    },
    textarea: {
      width: "95%",
      padding: "12px",
      marginBottom: "5px",
      borderRadius: "8px",
      border: "1px solid #ccc",
      backgroundColor: "#575656",
      color: "#fff",
      fontSize: "12px",
      resize: "vertical", // Allows vertical resizing of textareas
    },
    button: {
      backgroundColor: "#4caf50",
      padding: "12px 20px",
      borderRadius: "8px",
      color: "#fff",
      fontSize: "12px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },

    cancelButton: {
      backgroundColor: "#555",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "20px",
    },
    motivationalText: {
      color: "white", // Set text color to yellow
      fontSize: "12px", // Adjust font size
      marginBottom: "5px", // Add some space below the text
      textAlign: "left", // Center the text
    },
    historyEntry: {
      cursor: "pointer",
    },
    historyEntryHover: {
      color: "#61fa69", // Text color changes to blue on hover
    },
  };

  //*** 🖥️ Render the modal window ***
  const modal = (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.content}>
        <h2
          style={{ color: "#f09a73", marginTop: "0px" }}
        >{`${dateString}`}</h2>
        <p style={modalStyles.motivationalText}>
          💪 You're making progress, step by step. 🚶‍♀️ Keep going, you're
          stronger than you think!
        </p>
        <br />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogExposureSubmit();
          }}
        >
          <textarea
            style={modalStyles.textarea}
            placeholder="Describe today's exposure plan:"
            value={exposureData.exposurePlan}
            onChange={(e) => handleInputChange(e, "exposurePlan")}
            rows="4"
          />
          <textarea
            style={modalStyles.textarea}
            placeholder="Describe your worst, feared outcome of this exposure:"
            value={exposureData.fearedOutcome}
            onChange={(e) => handleInputChange(e, "fearedOutcome")}
            rows="4"
          />
          <textarea
            style={modalStyles.textarea}
            placeholder="Response Prevention Plan: What rituals, compulsions, avoidances and accommodations are you resisting during and after this exposure?"
            value={exposureData.responsePrevention}
            onChange={(e) => handleInputChange(e, "responsePrevention")}
            rows="4"
          />
          <p>
            Rate your belief in your fear coming true & Rate your confidence in
            tolerating your discomfort.
          </p>
          <div style={modalStyles.inputGroup}>
            <select
              style={modalStyles.input}
              value={exposureData.fearBelief || ""}
              onChange={(e) => handleInputChange(e, "fearBelief")}
            >
              <option value="" disabled selected={!exposureData.fearBelief}>
                Select Fear Belief (1-10)
              </option>
              {[...Array(10)].map((_, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>

            <select
              style={{ ...modalStyles.input, marginLeft: "40px" }}
              value={exposureData.confidence || ""}
              onChange={(e) => handleInputChange(e, "confidence")}
            >
              <option value="" disabled selected={!exposureData.confidence}>
                Select Confidence (1-10)
              </option>
              {[...Array(10)].map((_, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </div>

          <textarea
            style={modalStyles.textarea}
            placeholder="What was the exposure outcome? What did you learn? (Tie this answer to questions 1 & 2)"
            value={exposureData.exposureOutcome}
            onChange={(e) => handleInputChange(e, "exposureOutcome")}
            rows="4"
          />
          <textarea
            style={modalStyles.textarea}
            placeholder="How can you vary this exposure next time and mix it up? (Change time, place, activity, combine with other fear cues and exposures)"
            value={exposureData.exposureVariation}
            onChange={(e) => handleInputChange(e, "exposureVariation")}
            rows="4"
          />

          {/* History Display */}
          <h3>Exposure History</h3>
          <div
            style={{ marginTop: "0px", maxHeight: "90px", overflowY: "auto" }}
          >
            {logHistory.length > 0 ? (
              logHistory.map((log, index) => (
                <div
                  key={log.id}
                  style={modalStyles.historyEntry}
                  onClick={() => {
                    // Populate the form with this log's data
                    setExposureData({
                      exposurePlan: log.exposurePlan,
                      fearedOutcome: log.fearedOutcome,
                      responsePrevention: log.responsePrevention,
                      fearBelief: log.fearBelief,
                      confidence: log.confidence,
                      exposureOutcome: log.exposureOutcome,
                      exposureVariation: log.exposureVariation,
                    });
                  }}
                  onMouseEnter={(e) => (e.target.style.color = "#61fa69")} // Hover effect: set text color to blue
                  onMouseLeave={(e) => (e.target.style.color = "white")}
                >
                  <p style={{ lineHeight: "0.5" }}>
                    <strong>{index + 1}</strong> -{" "}
                    {new Date(log.timestamp.seconds * 1000).toLocaleString()}
                  </p>
                </div>
              ))
            ) : (
              <p>No history found for this exposure.</p>
            )}
          </div>

          <div style={modalStyles.buttonContainer}>
            <button type="submit" style={modalStyles.button}>
              Save Exposure
            </button>
            <button
              type="button"
              onClick={closeModal}
              style={{ ...modalStyles.button, ...modalStyles.cancelButton }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  //******************* 📜 Linked to therapist *******************
  const [secretKey, setSecretKey] = useState("");
  const [isLinked, setIsLinked] = useState(false);

  useEffect(() => {
    // Check if the user is already linked to a therapist
    const checkLinkStatus = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists() && userDoc.data().therapistId) {

          setIsLinked(true); // User is already linked to a therapist
        }
      }
    };

    checkLinkStatus();
  }, []);

  // *** 🖥️ Handle secret key submit ***
  const handleSecretKeySubmit = async () => {
    if (secretKey.trim()) {
      try {
        const therapistsQuery = query(
          collection(db, "users"),
          where("therapistSecretKey", "==", secretKey),
        );

        const querySnapshot = await getDocs(therapistsQuery);

        if (!querySnapshot.empty) {
          const therapistDoc = querySnapshot.docs[0];
          const therapistId = therapistDoc.id;

          // Assuming the user is already logged in
          const user = auth.currentUser;

          if (user) {
            const userRef = doc(db, "users", user.uid);
            await updateDoc(userRef, {
              therapistId: therapistId, // Link the user to the therapist
            });

            setIsLinked(true); // Update the state to show the user is now linked
            alert("Account linked to therapist successfully!");
          } else {
            alert("You are currently not logged in.");
          }
        } else {
          alert("Invalid Therapist Secret Key. Please try again.");
        }
      } catch (error) {
        alert("Failed to verify Therapist Secret Key. Please try again.");
      }
    } else {
      alert("Please enter a valid Therapist Secret Key.");
    }
  };

  //************************** 📜 Mood Tracker **************************
  const statusBar1Ref = useRef(null);
  const statusBar2Ref = useRef(null);
  const statusBar3Ref = useRef(null);
  const statusBar4Ref = useRef(null);
  const [moodData, setMoodData] = useState({});
  const [emojiPercentages, setEmojiPercentages] = useState({
    1: 0, // For 😊
    2: 0, // For 🙂
    3: 0, // For 😟
    4: 0, // For 😫
  });
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const moodEmojis = {
    1: "😊",
    2: "🙂",
    3: "😟",
    4: "😫",
  };

  // *** 🖥️ Fetch mood data from Firestore ***
  const fetchMoodData = async () => {
    if (user && user.uid) {
      const docRef = doc(db, "moodTracker", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (Object.keys(data).length > 0) {
          setMoodData(data);
          updateEmojiPercentages(data);
        }
      }
      setLoading(false);
    }
  };

  // *** 🖥️ Update the emoji percentages ***
  const updateEmojiPercentages = (updatedMoodData) => {
    const emojiCounts = Object.values(updatedMoodData).reduce(
      (counts, mood) => {
        counts[mood] = (counts[mood] || 0) + 1;
        return counts;
      },
      {},
    );
    const totalEntries = Object.values(updatedMoodData).filter(
      (value) => value !== null,
    ).length;
    const newEmojiPercentages = {};
    for (const mood in moodEmojis) {
      const count = emojiCounts[mood] || 0;
      newEmojiPercentages[mood] =
        totalEntries > 0 ? ((count / totalEntries) * 100).toFixed(0) : 0;
    }
    setEmojiPercentages(newEmojiPercentages);
  };

  // *** 🖥️ Render the grid with days and months ***
  const today = new Date();
  const todayKey = `${today.getMonth()}-${today.getDate()}`;
  const getDaysInMonth = (monthIndex, year) => {
    const date = new Date(year, monthIndex + 1, 0);
    return date.getDate();
  };
  const renderGrid = () => {
    const currentYear = new Date().getFullYear();
    return months.map((month, monthIndex) => {
      const daysInMonth = getDaysInMonth(monthIndex, currentYear);
      return (
        <div key={month} className="month-row">
          <div className="month-label">{month}</div>
          <div className="days-row">
            {Array.from({ length: daysInMonth }, (_, day) => {
              const key = `${monthIndex}-${day + 1}`;
              const isToday = key === todayKey;
              return (
                <div
                  key={day + 1}
                  className={`grid-cell mood-${moodData[key] || 0} ${isToday ? "today-square" : ""}`}
                  onClick={() => handleClick(monthIndex, day + 1)}
                >
                  {moodEmojis[moodData[key]] || ""}
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  // *** 🖥️ Save mood data to Firestore ***
  const saveMoodDataToFirestore = async (updatedMoodData) => {
    if (user) {
      try {
        await setDoc(doc(db, "moodTracker", user.uid), updatedMoodData);
      } catch (error) {
        console.error("Error saving mood data: ", error);
      }
    }
  };

  // *** 🖥️ Handle click on a square ***
  const handleClick = (month, day) => {
    const key = `${month}-${day}`;
    setMoodData((prevData) => {
      const currentMood = prevData[key] || 0;
      const newMood = (currentMood % 5) + 1;
      const updatedMoodData = { ...prevData };
      if (newMood === 5) {
        delete updatedMoodData[key];
      } else {
        updatedMoodData[key] = newMood;
      }
      saveMoodDataToFirestore(updatedMoodData);
      updateEmojiPercentages(updatedMoodData);
      return updatedMoodData;
    });
  };

  // *** 🖥️ Delete mood data from Firestore ***
  const handleClearData = async () => {
    if (
      window.confirm(
        "Are you sure you want to clear all your mood tracking data?",
      )
    ) {
      setMoodData({});
      await saveMoodDataToFirestore({});
      setEmojiPercentages({
        1: 0,
        2: 0,
        3: 0,
        4: 0,
      });
      updateEmojiPercentages(emojiPercentages);
    }
  };

  // *** 🖥️ Function to generate status bars dynamically ***
  const generateStatusBar = (ref, percentage) => {
    if (ref.current) {
      const statusBar = ref.current;
      statusBar.innerHTML = "";
      const statusLabel = statusBar.previousElementSibling;
      statusLabel.textContent = `${percentage}%`;

      const filledCount = Math.round((percentage / 100) * 10);
      const colors = [];
      for (let i = 0; i < 10; i++) {
        const lightness = 60 - i * 6;
        const color = `hsl(120, 60%, ${lightness}%)`;
        colors.push(color);
      }

      for (let i = 0; i < filledCount; i++) {
        const symbol = document.createElement("span");
        symbol.classList.add("status-symbol");
        symbol.style.backgroundColor = colors[i];
        statusBar.appendChild(symbol);
      }

      for (let i = filledCount; i < 10; i++) {
        const symbol = document.createElement("span");
        symbol.classList.add("status-symbol", "empty");
        statusBar.appendChild(symbol);
      }
    }
  };

  useEffect(() => {
    if (statusBar1Ref.current && emojiPercentages[1] !== undefined) {
      generateStatusBar(statusBar1Ref, emojiPercentages[1] || 0);
    }
    if (statusBar2Ref.current && emojiPercentages[2] !== undefined) {
      generateStatusBar(statusBar2Ref, emojiPercentages[2] || 0);
    }
    if (statusBar3Ref.current && emojiPercentages[3] !== undefined) {
      generateStatusBar(statusBar3Ref, emojiPercentages[3] || 0);
    }
    if (statusBar4Ref.current && emojiPercentages[4] !== undefined) {
      generateStatusBar(statusBar4Ref, emojiPercentages[4] || 0);
    }
  }, [emojiPercentages]);

  //*************** 📜 Check if user is logged in & fetch the data **************
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/login");
      }
      if (user) {
        setUser(user);
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  //*************** 📜 Fetch mood data after user has been set ******************
  useEffect(() => {
    if (user) {
      fetchUserSettings();
      fetchMoodData();
      loadExposures(user.uid);
      loadTriggers(user.uid);
      loadJournalEntries(user.uid);
      loadEvents();
      fetchGoals(user.uid);
      fetchMessages(user.uid);
      fetchAssessments(user.uid);
      fetchCoupons(user.uid);
      loadAllHistoricalDataFromFirestore();
      fetchRituals();
      setLoading(false);
    }
  }, [user]);

  //*************** 📜 Is loading **************
  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div>
        <head>
          {/* Include the Google Font */}
          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap"
            rel="stylesheet"
          />
        </head>

        <style>
          {`
						body {
							font-family: 'Poppins', sans-serif;
							background-color: #3e3e3e;
							max-width: 1900px;
						}

						.dashboard-container {
							display: grid;
							grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
							grid-gap: 20px;
							padding: 20px;
						}

						.tile {
							position: relative;
							background-color: #1e1e1e;
							color: white;
							border-radius: 8px;
							padding: 20px;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
							overflow: hidden;
						}

						.tile::before,
						.tile::after {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							border-radius: 8px;
						}

						.tile::before {
							border: 4px double #333;
							z-index: -1;
						}

						.tile::after {
							border: 2px solid #444;
							z-index: -2;
						}

						.tile .nested-tile {
							position: relative;
							background-color: #2b2b2b;
							/* Darker background for the nested tile */
							color: #fff;
							border-radius: 8px;
							padding: 15px;
							box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
							/* Shadow for nested tile */
						}

						.tile .nested-tile::before,
						.tile .nested-tile::after {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							border-radius: 8px;
						}

						.tile .nested-tile::before {
							border: 4px double #444;
							/* Outer double border effect for nested tile */
							z-index: -1;
							/* Keep the border behind the tile content */
						}

						.tile .nested-tile::after {
							border: 2px solid #555;
							/* Inner border for nested tile */
							z-index: -2;
							/* Keep this behind the other borders */
						}

						/* Example: Set size for specific tiles */
						.tile-1 {
							grid-column: span 2;
							/* Span across 2 columns */
							grid-row: span 2;
							/* Span across 2 rows */
						}

						.tile-2 {
							grid-column: span 1;
							/* Standard width */
							grid-row: span 1;
							/* Standard height */
						}

						.tile-3 {
							grid-column: span 3;
							/* Span across 3 columns */
							grid-row: span 1;
							/* Standard height */
						}

						.tile-header {
							display: flex;
							align-items: center;
							font-size: 1.2em;
							font-weight: bold;
						}

						.tile-header .icon {
							font-size: 1em;
						}


						.tile-content {
							flex-grow: 1;
						}


						.tile-content p {
							font-size: 12px;
						}

						.tile-content input {
							font-size: 14px;
							width: 90px;
							height: 10px;
						}

						.tile-footer {
							text-align: right;
							font-size: 0.9em;
							color: #bbb;
						}

						.tile .icon {
							width: 20px;
							height: 20px;
							margin-right: 10px;
						}

						.status-row {
							display: flex;
							align-items: right;
							margin-bottom: 0px;
							margin-left: 60%;
							padding: 0;
							line-height: 1;
							color: white;
							font-size: 12px;
							justify-content: space-between;
							width: 200px;
						}

						.status-row .icon {

							font-size: 14px;
						}

						.status-bar {
							display: inline-block;
							width: 130px;
							height: 20px;

						}

						.status-symbol {
							display: inline-block;
							width: 10%;
							height: 100%;
							background-color: #e0e0e0;
						}

						.status-bar .status-symbol {
							margin-right: 2px;
							/* Space between blocks */
							width: 10px;
							/* Size of each block */
							height: 10px;
							/* Size of each block */
							text-align: center;
							color: white;
							/* Default color for text */
							font-weight: bold;
							display: inline-block;
						}

						.empty {
							background-color: #343635;
						}

						.emoji-percentages {
							display: flex;
							justify-content: space-around;
							flex-wrap: wrap;
							/* Allow wrapping for smaller screens */
							padding: 10px;
							margin: auto;
							margin-bottom: 20px;
							border-radius: 8px;
							font-size: 1.0em;
							box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
							max-width: 700px;
							/* Center within max width */
							background-color: #1e1e1e;
						}

						.emoji-percentages div {
							display: flex;
							align-items: center;
							gap: 20px;
							/* Default gap */
							margin: 5px 10px;
							/* Margin for wrapped items */
							font-weight: bold;
							color: hsl(0, 0%, 100%);
						}

						.emoji-percentages div::before {
							content: attr(data-emoji);
							font-size: 1.0em;
						}

						.emoji-percentages {
							font-size: 0.5rem;
							/* Slightly smaller font size */
						}

						.emoji-percentages div {
							gap: 5px;
							/* Reduce gap on smaller screens */
						}

						.container1-mood {
							margin: auto;
							/* Fills the viewport height */
							font-size: 0.8em;
						}

						.calendar-grid {
							margin-top: 25px;
							margin-bottom: 20px;
							display: flex;
							flex-direction: column;
							line-height: normal
						}

						.month-row {
							display: flex;
							align-items: center;
							margin-bottom: 5px;
						}

						.month-label {
							width: 50px;
							text-align: right;
							margin-right: 10px;
							font-size: 12px;
						}

						.days-row {
							display: grid;
							grid-template-columns: repeat(31, 15px);
							/* 31 columns for days */
							gap: 1px;
						}

						.grid-cell {
							width: 15px;
							height: 15px;
							background-color: #333;
							border-radius: 3px;
							cursor: pointer;
						}

						.grid-cell.mood-1 {
							background-color: #4caf50;
							/* Happy */
						}

						.grid-cell.mood-2 {
							background-color: #ffeb3b;
							/* Neutral */
						}

						.grid-cell.mood-3 {
							background-color: #4b30e6;
						}

						.grid-cell.mood-4 {
							background-color: #f44336;
							/* Sad */
						}

						/* Highlight today's square */
						.today-square {
							border: 2px solid #ff9800;
							/* Bright orange border */
							animation: pulse 1s infinite;
							/* Optional subtle animation */
						}

						/* Optional pulse animation */
						@keyframes pulse {
							0% {
								box-shadow: 0 0 5px #ff9800;
							}

							50% {
								box-shadow: 0 0 15px #ff9800;
							}

							100% {
								box-shadow: 0 0 5px #ff9800;
							}
						}

						.clear-btn {
							padding: 8px 12px;
							margin: 5px 0;
							border: none;
							width: 60px;
							background-color: #fc3232;
							color: white;
							font-size: 12px;
							border-radius: 4px;
							cursor: pointer;
							transition: background-color 0.2s ease;
						}

						.clear-btn:hover {
							background-color: #fc4747;

						}

						.link-btn {
							padding: 8px 12px;
							margin: 5px 0;
							border: none;


							background-color: #4caf50;
							color: white;
							font-size: 14px;
							border-radius: 4px;
							cursor: pointer;
							transition: background-color 0.2s ease;
						}

						.link-btn:hover {
							background-color: #45a049;

						}

						.loading-spinner {
							border: 8px solid #f3f3f3;
							border-top: 8px solid #3498db;
							border-radius: 50%;
							width: 60px;
							height: 60px;
							animation: spin 1s linear infinite;
							margin: 20px auto;
						}

						@keyframes spin {
							0% {
								transform: rotate(0deg);
							}

							100% {
								transform: rotate(360deg);
							}
						}

						.loading-container {
							display: flex;
							justify-content: center;
							align-items: center;
							height: 100vh;
						}

						.exposure ul {
							list-style-type: none;
							margin-left: 5px;
							padding: 5px;
							border: 0px solid white;
							background-color: #333;
							border-radius: 4px;
						}

						.exposure li {
							display: flex;
							align-items: center;
							justify-content: right;
							gap: 5px;
							padding: 0px;
							font-size: 12px;
							margin-bottom: 0px;

						}

						.exposure-btn {
							padding: 3px 3px;
							margin: 1px;
							border: none;
							background-color: #4caf50;
							color: white;
							font-size: 12px;
							border-radius: 4px;
							cursor: pointer;
							transition: background-color 0.2s ease;
						}

						.exposure-btn:hover {
							background-color: #45a049;

						}

						.form-trigger {
							margin-left: 5px;
							padding: 10px;
							border: 0px solid white;
							background-color: #333;
							border-radius: 4px;

						}


						.form-group {
							margin-bottom: 0px;

						}

						.form-group label {
							display: block;
							margin-bottom: 2px;
						}

						.form-group input {
							width: 90%;
							padding: 8px;
							border: 1px solid #ccc;
							border-radius: 5px;
						}

						.form-group select {

							padding: 5px;
							border: 1px solid #ccc;
							border-radius: 5px;
							font-size: 1rem;
						}

						.tracker-table {
							table-layout: fixed;
							border-collapse: collapse;

							width: 100%;
						}

						.tracker-table th,
						.tracker-table td {
							border: 0px solid #ccc;
							padding: 0px;

							word-wrap: break-word;
						}

						.tracker-table th {
							background-color: #575656;
							font-size: 12px;
							text-align: center;
						}

						.tracker-table td {
							line-height: 0.8rem;
							font-size: 10px;
							text-align: left;
							padding-right: 3px;
						}

						.form-journal {

							font-size: 12px;
							text-align: left;
						}

						.form-journal textarea {

							width: 95%;
							margin-bottom: 10px;
							border-radius: 5px;
							font-size: 14px;
						}

						.form-journal select {

							padding: 3px;
							margin-left: 5px;
							border: 1px solid #ccc;
							border-radius: 5px;
							font-size: 14px;
						}

						/* Entries List */
						.entries-list {
							margin-top: 10px;
							font-size: 12px;
							align-items: top;

						}

						.entries-list th {
							background-color: #575656;
							text-align: center;
						}

						.entries-list td {
							line-height: 0.8rem;
							font-size: 10px;
							text-align: left;
							padding-right: 3px;
						}

						.entries-list li span {
							font-weight: normal;
							color: #777;
						}

						/* Tile Footer */
						.tile-footer {
							font-size: 12px;
							color: #999;
							text-align: right;
							margin-top: 20px;
						}

						.event-list {
							list-style-type: none;
							padding: 0;
						}

						.event-item {
							margin-bottom: 1.0rem;
							padding-bottom: 1rem;
							border-bottom: 1px solid #ddd;
						}

						.event-date {
							font-weight: bold;
							font-size: 14px;
							color: #4caf50;
						}

						.event-details {
							margin: 0.5rem 0;
						}

						.event-link {
							color: #007bff;
							text-decoration: none;
							font-size: 10px;
						}

						.event-link:hover {
							text-decoration: underline;
						}


						.goal-content textarea {

							width: 95%;
							height: 70px;
							margin-bottom: 10px;
							border-radius: 5px;
							font-size: 12px;
						}

						/* Message Board */
						.message-board {
							display: flex;
							flex-direction: column;
						}

						/* Messages List */
						.messages-list {
							max-height: 400px;
							overflow-y: scroll;
							margin-bottom: 10px;
							border: 0px solid #ddd;
							border-radius: 8px;
							padding: 10px;
							background-color: #333;
							font-size: 12px;
						}

						/* Individual Message Styles */
						.message {
							padding: 10px;
							margin-bottom: 10px;
							border-radius: 8px;


						}

						.message.patient {
							background-color: #1e1e1e;
							color: #4caf50;
							font-size: 12px;
						}

						.message.therapist {
							background-color: #1e1e1e;
							color: #007bff;
							font-size: 12px;
							text-align: right;
						}

						.message.admin {
							background-color: #faf261;
							color: black;
							font-size: 12px;
							text-align: right;
						}

						.message p {
							margin: 0;
							font-size: 12px;
						}

						.message span {
							font-size: 10px;
							color: white;
						}

						/* Send Message Area */
						.send-message {
							display: flex;
							flex-direction: column;
						}

						.send-message textarea {
							width: 90%;
							height: 50px;
							margin-bottom: 10px;

							padding: 10px;
							border-radius: 8px;
							border: 1px solid #ccc;
							font-size: 12px;
							resize: none;
						}

						.send-message button:disabled {
							background-color: #aaa;
							cursor: not-allowed;
						}

						.coupon-history1 {
							table-layout: fixed;
							border-collapse: collapse;

							width: 100%;
						}

						.coupon-history1 th,
						.coupon-history1 td {
							border: 0px solid #ccc;
							padding: 0px;

							word-wrap: break-word;
						}

						.coupon-history1 th {
							background-color: #575656;
							font-size: 12px;
							text-align: center;
						}

						.coupon-history1 td {
							line-height: 0.8rem;
							font-size: 12px;
							text-align: left;
							padding-right: 3px;
						}

					.select-row {
						
						flex-wrap: wrap; /* Makes the layout responsive */
						gap: 10px; /* Space between select fields */
						margin-bottom: 20px;
					}

					.select-container {
						
						min-width: 100px; /* Prevents the fields from becoming too narrow */
					}

					.select-container label {
						display: block;
						font-size: 16px;
						color: hsl(0, 0%, 100%);
						margin-bottom: 5px;
					}

					.select-container select {
						width: 100%;
						padding: 10px;
						font-size: 14px;
						border-radius: 4px;
						border: 1px solid #ccc;
					}



	  
        `}
        </style>

        <div className="dashboard-container">
          {userSettings["reassuranceCoupon"] && (
            <div class="tile tile-2">
              <div class="tile-header">
                <span class="icon">🎟️</span>Reassurance Coupon
              </div>
              <div class="tile-content">
                <p>
                  Trade in a coupon for one reassurance seeking question to be
                  answered. The goal is to reduce the number of coupons given
                  per week.
                </p>
                <p style={{ textAlign: "center" }}>
                  ➡️{" "}
                  <a
                    href="https://www.ocdserenity.com/Reassurance-coupons.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="event-link"
                  >
                    Download printable coupons here
                  </a>{" "}
                  ⬅️
                </p>
                <h4 style={{ marginTop: "5px", marginBottom: "5px" }}>
                  Coupons Given This Week{" "}
                </h4>
                <div className="coupon-input">
                  <input
                    type="number"
                    id="couponsGiven"
                    value={couponsGiven}
                    onChange={handleCouponsChange}
                    min="0"
                    style={{ width: "40px", textAlign: "center" }}
                  />
                  <button
                    className="link-btn"
                    onClick={logCoupons}
                    disabled={couponsGiven <= 0}
                  >
                    Save
                  </button>
                </div>

                <div className="coupon-history1">
                  <h4 style={{ marginTop: "5px", marginBottom: "5px" }}>
                    Coupon History
                  </h4>
                  <button className="clear-btn" onClick={clearCouponsHistory}>
                    Clear
                  </button>
                  {history.length > 0 ? (
                    <>
                      <table className="history-table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Coupons Given</th>
                          </tr>
                        </thead>
                        <tbody>
                          {history.map((entry, index) => (
                            <tr key={index}>
                              <td>{entry.timestamp}</td>
                              <td>
                                {entry.coupons} (
                                {Array.from(entry.coupons).length / 2})
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <p style={{ textAlign: "center", color: "#4caf50" }}>
                      No history available.
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}

          {userSettings["messagesBoard"] && (
            <div className="tile tile-2">
              <div className="tile-header">
                <span className="icon">💬</span> Messages
              </div>
              <div className="tile-content">
                <p>Stay in touch with your therapist.</p>
                <div className="message-board">
                  <div className="messages-list">
                    {messages
                      .slice()
                      .reverse()
                      .map((msg, index) => (
                        <div
                          key={index}
                          className={`message ${msg.messageType}`}
                        >
                          <p>
                            <b>
                              {msg.messageType === "therapist" && "Therapist:"}
                            </b>
                            <b>{msg.messageType === "patient" && "You: "}</b>
                            <b>
                              {msg.messageType === "admin" &&
                                "OCDSereniry Admin: "}
                            </b>
                            <br />
                            <i>{msg.message}</i>
                          </p>
                          <span>
                            {new Date(
                              msg.timestamp.seconds * 1000,
                            ).toLocaleTimeString()}
                          </span>
                        </div>
                      ))}
                  </div>

                  <div className="send-message">
                    <textarea
                      value={newMessage}
                      onChange={handleInputChangeGoal}
                      placeholder="Type your message here..."
                      style={{
                        backgroundColor: "#575656",
                        color: "#fff",
                        border: "1px solid #ccc",
                      }}
                    ></textarea>
                    <button className="link-btn" onClick={sendMessage}>
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {userSettings["upcomingEvents"] && (
            <div className="tile tile-2">
              <div className="tile-header">
                <span className="icon">📅</span>Upcoming events
              </div>
              <div className="tile-content">
                <ul className="event-list">
                  {events.map((event, index) => (
                    <li key={index} className="event-item">
                      <span className="event-date">{event.date}</span>
                      <p className="event-details">
                        {event.details}&nbsp;&nbsp;
                        <a
                          href={event.link}
                          target="_blank"
                          className="event-link"
                          rel="noopener noreferrer"
                        >
                          Learn more
                        </a>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div class="tile tile-2" style={{ height: "320px" }}>
            <div class="tile-header">
              <span class="icon">⚙️</span>Dashboard Settings
            </div>
            <div class="tile-content">
              <p>Select tiles to display:</p>
              <form>
                {tilesList.map((tile, index) => (
                  <div
                    key={index}
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      marginBottom: "0px",
                    }}
                  >
                    <input
                      type="checkbox"
                      id={`tile-${tile.id}`}
                      checked={userSettings[tile.id]}
                      onChange={() => handleTileToggle(tile.id)}
                      style={{ height: "15px", width: "15px" }}
                    />
                    <label htmlFor={`tile-${tile.id}`}>{tile.name}</label>
                  </div>
                ))}
              </form>
            </div>
          </div>

          {userSettings["assessmentScore"] && (
            <div class="tile tile-2" style={{ height: "160px" }}>
              <div class="tile-header">
                <span class="icon">📝</span>Assessment Score
              </div>
              <div class="tile-content">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "5px",
                    alignItems: "center",
                  }}
                >
                  <b>
                    Y-BOCS:
                    <span style={{ fontSize: "18px", color: "#4caf50" }}>
                      &nbsp;{scores.totalScore || "N/A"}
                    </span>
                  </b>
                  &nbsp;&nbsp;
                  <a
                    href="#"
                    target="_blank"
                    className="event-link"
                    rel="noopener noreferrer"
                  >
                    <Link
                      to="/yale-brown"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      👉 Take the test
                    </Link>
                  </a>
                </p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "5px",
                    alignItems: "center",
                  }}
                >
                  <b>
                    PHQ-9:
                    <span style={{ fontSize: "18px", color: "#4caf50" }}>
                      &nbsp;{scoresPHQ9.totalScore || "N/A"}
                    </span>
                  </b>
                  &nbsp;&nbsp;
                  <a
                    href="#"
                    target="_blank"
                    className="event-link"
                    rel="noopener noreferrer"
                  >
                    <Link
                      to="/phq9"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      👉 Take the test
                    </Link>
                  </a>
                </p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "5px",
                    alignItems: "center",
                  }}
                >
                  <b>
                    GAD-7:
                    <span style={{ fontSize: "18px", color: "#4caf50" }}>
                      &nbsp;{scoresGAD7.totalScore || "N/A"}
                    </span>
                  </b>
                  &nbsp;&nbsp;
                  <a
                    href="#"
                    target="_blank"
                    className="event-link"
                    rel="noopener noreferrer"
                  >
                    <Link
                      to="/gad7"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      👉 Take the test
                    </Link>
                  </a>
                </p>
              </div>
            </div>
          )}

          {userSettings["therapistAccess"] && (
            <div class="tile tile-2" style={{ height: "160px" }}>
              <div class="tile-header">
                <span class="icon">🧑‍⚕️</span>Therapist access
              </div>
              <div class="tile-content">
                {isLinked ? (
                  <>
                    <br />
                    <p style={{ textAlign: "center", color: "#4caf50" }}>
                      ✅ Your account is linked to your therapist!
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      Enter your therapist's secret key to link your account:
                    </p>
                    <input
                      type="text"
                      placeholder="Secret Key"
                      value={secretKey}
                      onChange={(e) =>
                        setSecretKey(e.target.value.toUpperCase())
                      }
                    />
                    <button
                      className="link-btn"
                      onClick={handleSecretKeySubmit}
                    >
                      Link
                    </button>
                  </>
                )}
              </div>
            </div>
          )}

          {userSettings["exposureHierarchyBuilder"] && (
            <div class="tile tile-1">
              <div class="tile-header">
                <span class="icon">🚀</span>Exposure Hierarchy Builder
              </div>
              <div class="tile-content">
                <p>
                  The Exposure Hierarchy Builder helps you tackle your fears and
                  anxieties step-by-step. Start by listing situations that cause
                  distress, ranking them by how challenging they feel. As you
                  work through each exposure, mark your progress and adjust as
                  needed. This tool is designed to support you in facing
                  difficult situations gradually, helping to build resilience
                  and reduce anxiety over time.
                </p>
                {/* Add new exposure */}
                <div>
                  <input
                    style={{ width: "350px" }}
                    type="text"
                    value={newExposure}
                    onChange={(e) => setNewExposure(e.target.value)}
                    placeholder="Enter an anxiety-inducing situation"
                  />
                  {editId ? (
                    <button className="link-btn" onClick={updateExposure}>
                      Update Exposure
                    </button>
                  ) : (
                    <button className="link-btn" onClick={addExposure}>
                      Add Exposure
                    </button>
                  )}
                </div>

                {/* Progress bar */}

                <div style={{ display: "flex", alignItems: "center" }}>
                  <h4>Progress:&nbsp;</h4>
                  <div
                    style={{
                      width: "65%",
                      backgroundColor: "#ddd",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: `${(completedCount / totalCount) * 100}%`,
                        height: "100%",
                        backgroundColor: "#4caf50",
                      }}
                    ></div>
                  </div>
                  <span style={{ fontSize: "12px" }}>
                    {completedCount}/{totalCount} completed
                  </span>
                </div>

                {/* List of exposures */}
                <div className="exposure">
                  <table className="tracker-table">
                    <thead>
                      <tr>
                        <th>Exposure Title</th>
                        <th style={{ width: "30px" }}>Fear</th>
                        <th style={{ width: "75px" }}>Confidence</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exposures
                        .filter((exposure) => !exposure.completed)
                        .sort((a, b) => b.fearBelief - a.fearBelief)
                        .map((exposure) => {
                          // Get the color for the specific distress value
                          const backgroundColor =
                            distressColors[exposure.fearBelief];

                          return (
                            <tr
                              key={exposure.id}
                              style={{ backgroundColor, color: "black" }}
                            >
                              <td style={{ paddingLeft: "5px" }}>
                                {exposure.title}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {exposure.fearBelief || "N/A"}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {exposure.confidence || "N/A"}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <button
                                  className="exposure-btn"
                                  onClick={() => openModal(exposure.title)}
                                  style={{ backgroundColor: "#1e1e1e" }}
                                >
                                  Log Exposure
                                </button>
                                <button
                                  className="exposure-btn"
                                  onClick={() => toggleCompletion(exposure.id)}
                                  style={{ backgroundColor: "#1e1e1e" }}
                                >
                                  Mark Complete
                                </button>
                                <button
                                  className="exposure-btn"
                                  onClick={() => deleteExposure(exposure.id)}
                                  style={{ backgroundColor: "#1e1e1e" }}
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  <h4>Completed Exposures</h4>
                  <table className="tracker-table">
                    <thead>
                      <tr>
                        <th>Exposure Title</th>
                        <th style={{ width: "30px" }}>Fear</th>
                        <th style={{ width: "75px" }}>Confidence</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exposures
                        .filter((exposure) => exposure.completed)
                        .sort((a, b) => b.fearBelief - a.fearBelief)
                        .map((exposure) => {
                          const backgroundColor =
                            distressColors[exposure.fearBelief];

                          return (
                            <tr
                              key={exposure.id}
                              style={{ backgroundColor, color: "black" }}
                            >
                              <td
                                style={{
                                  paddingLeft: "5px",
                                  textDecoration: "line-through",
                                }}
                              >
                                {exposure.title}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  textDecoration: "line-through",
                                }}
                              >
                                {exposure.fearBelief || "N/A"}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  textDecoration: "line-through",
                                }}
                              >
                                {exposure.confidence || "N/A"}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <button
                                  className="exposure-btn"
                                  onClick={() => toggleCompletion(exposure.id)}
                                  style={{ backgroundColor: "#1e1e1e" }}
                                >
                                  Mark Incomplete
                                </button>
                                <button
                                  className="exposure-btn"
                                  onClick={() => deleteExposure(exposure.id)}
                                  style={{ backgroundColor: "#1e1e1e" }}
                                >
                                  X
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <button
                  className="clear-btn"
                  onClick={handleClearExposureData}
                  style={{ marginTop: "20px" }}
                >
                  Clear
                </button>
              </div>
            </div>
          )}

          {userSettings["triggerTracker"] && (
            <div class="tile tile-1" style={{ maxHeight: "800px" }}>
              <div class="tile-header">
                <span class="icon">🎯</span>Trigger Tracker
              </div>
              <div class="tile-content">
                <p>
                  Use this tool to track your OCD triggers, intrusive thoughts,
                  compulsions, and the relief or distractions you experience.
                  This tracker helps you recognize patterns in your thoughts and
                  behaviors, making it easier to monitor your progress over
                  time.
                </p>
                <div class="">
                  <form onSubmit={handleSubmit} className="tracker-form">
                    <div className="form-group">
                      <label style={{ marginTop: "5px" }}>
                        Suspected Trigger
                      </label>
                      <input
                        type="text"
                        value={trigger}
                        onChange={(e) => setTrigger(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label style={{ marginTop: "5px" }}>Thought</label>
                      <input
                        type="text"
                        value={thought}
                        onChange={(e) => setThought(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label style={{ marginTop: "5px" }}>Compulsion</label>
                      <input
                        type="text"
                        value={compulsion}
                        onChange={(e) => setCompulsion(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label style={{ marginTop: "5px" }}>
                        Relief/Distraction
                      </label>
                      <input
                        type="text"
                        value={relief}
                        onChange={(e) => setRelief(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label style={{ marginTop: "5px" }}>
                        Distress Severity
                      </label>
                      <select
                        value={distress}
                        onChange={(e) => setDistress(e.target.value)}
                        required
                      >
                        <option value="😄">😄</option>
                        <option value="🙂">🙂</option>
                        <option value="😐">😐</option>
                        <option value="😟">😟</option>
                        <option value="😢">😢</option>
                      </select>

                      <button
                        type="submit"
                        className="link-btn"
                        style={{ marginLeft: "5px" }}
                      >
                        {editIndex !== null ? "Update" : "Save"}
                      </button>
                    </div>
                  </form>
                </div>

                <h3>Recorded Triggers</h3>
                {data.length > 0 ? (
                  <table className="tracker-table">
                    <thead>
                      <tr>
                        <th>Trigger</th>
                        <th>Thought</th>
                        <th>Compulsion</th>
                        <th>Relief</th>
                        <th style={{ width: "30px" }}>Fear</th>
                        <th style={{ width: "50px" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((entry, index) => {
                        const backgroundColor =
                          distressTriggerColors[entry.distress];
                        return (
                          <tr
                            key={index}
                            style={{ backgroundColor, color: "black" }}
                          >
                            <td style={{ paddingLeft: "5px" }}>
                              {entry.trigger}
                            </td>
                            <td>{entry.thought}</td>
                            <td>{entry.compulsion}</td>
                            <td>{entry.relief}</td>
                            <td style={{ fontSize: "15px" }}>
                              {entry.distress}
                            </td>
                            <td>
                              <button
                                onClick={() => handleEdit(index)}
                                className="exposure-btn"
                                style={{ backgroundColor: "#1e1e1e" }}
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => handleDelete(index)}
                                className="exposure-btn"
                                style={{ backgroundColor: "#1e1e1e" }}
                              >
                                X
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <p style={{ color: "#4caf50" }}>No triggers recorded yet.</p>
                )}
              </div>

              <button className="clear-btn" onClick={deleteOcdTriggers}>
                Clear
              </button>
            </div>
          )}

          {userSettings["journaling"] && (
            <div className="tile tile-1" style={{ maxHeight: "800px" }}>
              <div className="tile-header">
                <span className="icon">⏱</span>Journaling
              </div>
              <div className="tile-content">
                <p>
                  The Journaling Tool allows you to record your daily thoughts
                  and emotions, helping you track your emotional patterns over
                  time. You can write entries for specific dates, score your
                  day, and reflect on past experiences.
                </p>
                <h4>New Entry</h4>
                <div className="form-journal">
                  <form onSubmit={handleSubmitJournal}>
                    <textarea
                      placeholder="Write your thoughts..."
                      value={entryText}
                      onChange={(e) => setEntryText(e.target.value)}
                      rows="4"
                      cols="50"
                      style={{
                        backgroundColor: "#575656",
                        color: "#fff",
                        border: "1px solid #ccc",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "15px",
                        alignItems: "center",
                      }}
                    >
                      <label>
                        <span style={{ fontSize: "14px" }}>Mood:</span>
                        <select
                          value={entryScore}
                          onChange={(e) =>
                            setEntryScore(Number(e.target.value))
                          }
                          style={{ backgroundColor: "#575656", color: "#fff" }}
                        >
                          <option value={0}>Happy</option>
                          <option value={1}>Slightly happy</option>
                          <option value={2}>Worried</option>
                          <option value={3}>Very distressed</option>
                        </select>
                      </label>

                      <button className="link-btn" type="submit">
                        Save Entry
                      </button>
                    </div>
                  </form>
                </div>
                <h4>Previous Entries</h4>
                <div
                  className="entries-list"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr
                        style={{
                          position: "sticky",
                          top: 0,
                          backgroundColor: "#fff",
                          zIndex: 1,
                        }}
                      >
                        <th>Date</th>
                        <th>Mood</th>
                        <th>Entry</th>
                        <th style={{ width: "20px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {journalEntries.map((entry, index) => {
                        // Define colors for each emoji value
                        const emojiColors = {
                          Happy: "#61fa69",
                          "Slightly happy": "#faf261",
                          Worried: "#fa9961",
                          "Very distressed": "#fa6161",
                        };

                        // Get the color corresponding to the emoji
                        const textColor = emojiColors[entry.emoji] || "black"; // Default to black if emoji doesn't match

                        return (
                          <tr key={index}>
                            <td
                              style={{
                                backgroundColor: textColor,
                                color: "black",
                                paddingLeft: "5px",
                              }}
                            >
                              {entry.date}
                            </td>
                            <td
                              style={{
                                backgroundColor: textColor,
                                color: "black",
                              }}
                            >
                              {entry.emoji}
                            </td>
                            <td
                              style={{
                                backgroundColor: textColor,
                                color: "black",
                              }}
                            >
                              {entry.text}
                            </td>
                            <td
                              style={{
                                paddingRight: "10px",
                                backgroundColor: textColor,
                                color: "black",
                              }}
                            >
                              <button
                                className="exposure-btn"
                                onClick={() =>
                                  deleteJournalEntry(entry.entryId, entry.date)
                                }
                                style={{ backgroundColor: "black" }}
                              >
                                X
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {userSettings["sudsTracker"] && (
            <div class="tile tile-1">
              <div class="tile-header">
                <span class="icon">⏱️</span>SUDS Tracker
              </div>
              <div class="tile-content">
                <p>
                  Use this feature to quickly log multiple anxiety levels from
                  your exposure exercises. Simply enter your levels of anxiety
                  between 0 and 100 (SUDS scores) separated by commas, and
                  select the interval at which these values were recorded. You
                  can also choose the ritual practiced during the exercise.
                </p>

                <div className="select-row">
                  <div className="select-container">
                    <label>
                      Select Interval
                      <br />
                      <select
                        value={importedInterval}
                        onChange={(e) => setImportedInterval(e.target.value)}
                        style={{ width: "100px" }}
                      >
                        <option value="3">3 minutes</option>
                        <option value="5">5 minutes</option>
                        <option value="10">10 minutes</option>
                        <option value="15">15 minutes</option>
                        <option value="20">20 minutes</option>
                      </select>
                    </label>
                  </div>
                  <div className="select-container">
                    <label>
                      Select Ritual
                      <br />
                      <select
                        value={importedRitual}
                        onChange={(e) => setImportedRitual(e.target.value)}
                        style={{ width: "300px" }}
                      >
                        <option value="" disabled>
                          Select a ritual
                        </option>

                        {rituals.map((ritual, index) => (
                          <option key={index} value={ritual}>
                            {ritual}
                          </option>
                        ))}
                      </select>
                    </label>
                    <p style={{ marginTop: "2px", marginBottom: "2px" }}>Or</p>
                    <div>
                      <input
                        type="text"
                        placeholder="Add a new ritual..."
                        value={newRitual}
                        onChange={(e) => setNewRitual(e.target.value)}
                        style={{ width: "300px" }}
                      />
                      <button onClick={addRitual} className="link-btn">
                        Add Ritual
                      </button>
                    </div>
                  </div>
                </div>
                <p>
                  Input your anxiety levels as comma-separated values (e.g., 85,
                  70, 62).
                </p>
                <div>
                  <input
                    style={{ width: "350px" }}
                    type="text"
                    value={importedAnxietyLevels}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Updated regex to allow a trailing comma
                      const regex = /^(\d{1,2}|100)(,(\d{1,2}|100))*,?$/;

                      // Check if input matches the regex
                      if (regex.test(value) || value === "") {
                        setImportedAnxietyLevels(value);
                      } else {
                        // Show alert if the value doesn't match the rule
                        alert(
                          "Please enter valid anxiety levels (numbers between 0 and 100, separated by commas).",
                        );
                      }
                    }}
                    placeholder="Enter anxiety levels (e.g., 85, 70, 62)"
                  />

                  <button onClick={handleImportSubmit} className="link-btn">
                    Save
                  </button>
                </div>

                {allHistoricalData.length > 0 ? (
                  allHistoricalData.map((ritualData, ritualIndex) => {
                    //const summary = calculateSummary(ritualData); // Get the summary for this ritual
                    if (ritualData.trigger === "rituals") {
                      return null; // Do not render anything if the trigger is 'rituals'
                    }
                    return (
                      <div key={ritualIndex} className="ritual-graph">
                        <h3>
                          Recorded anxiety levels for{" "}
                          <span className="fear-color">
                            {ritualData.trigger}
                          </span>
                        </h3>
                        <div
                          className="responsive-wrapper"
                          style={{ marginRight: "20px" }}
                        >
                          <ResponsiveContainer width="100%" height={300}>
                            <LineChart>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                type="number"
                                dataKey="time"
                                tickFormatter={(value) => `${value} min`}
                                label={{
                                  value: "Time (min)",
                                  position: "insideBottomRight",
                                  offset: -5,
                                }}
                              />
                              <YAxis
                                domain={[0, 100]}
                                ticks={[0, 20, 40, 60, 80, 100]}
                                label={{
                                  value: "Anxiety Level",
                                  angle: -90,
                                  position: "insideLeft",
                                }}
                              />
                              <Tooltip />
                              <Legend />

                              <ReferenceLine y={80} stroke="red" />
                              <ReferenceLine y={20} stroke="green" />

                              {ritualData.data.map((session, sessionIndex) => {
                                return (
                                  <Line
                                    key={sessionIndex}
                                    data={session}
                                    type="monotone"
                                    dataKey="level"
                                    stroke={
                                      lineColors[
                                        sessionIndex % lineColors.length
                                      ]
                                    }
                                    strokeWidth={2}
                                    dot={<CustomDot />}
                                    name={`Session ${sessionIndex + 1}`}
                                  />
                                );
                              })}
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                        {/* Trend, Comparative, and Progress summary for this ritual */}
                        <button
                          className="clear-btn"
                          onClick={() => {
                            deleteRitual(ritualData.trigger);
                          }}
                          style={{
                            marginLeft: "85%",
                          }}
                        >
                          Clear
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <p style={{ textAlign: "center", color: "#4caf50" }}>
                    No history available.
                  </p>
                )}
              </div>
            </div>
          )}

          {userSettings["goals"] && (
            <div className="tile tile-1" style={{ maxHeight: "760px" }}>
              <div className="tile-header">
                <span className="icon">⏱</span>Goals
              </div>
              <div className="tile-content">
                <p>
                  Imagine you had a magic wand and could make all your OCD
                  symptoms go away. What would your life look like? Where would
                  you be living? What job would you be doing? What would your
                  social life look like?
                </p>

                <div className="goal-content">
                  <label>
                    Working Life
                    <textarea
                      name="workingLife"
                      value={goals.workingLife}
                      onChange={handleGoalChange}
                      placeholder="Enter your goal for working life"
                      style={{
                        backgroundColor: "#575656",
                        color: "#fff",
                        border: "1px solid #ccc",
                      }}
                    />
                  </label>
                  <label>
                    Home Life
                    <textarea
                      name="homeLife"
                      value={goals.homeLife}
                      onChange={handleGoalChange}
                      placeholder="Enter your goal for home life"
                      style={{
                        backgroundColor: "#575656",
                        color: "#fff",
                        border: "1px solid #ccc",
                      }}
                    />
                  </label>
                  <label>
                    Social Life
                    <textarea
                      name="socialLife"
                      value={goals.socialLife}
                      onChange={handleGoalChange}
                      placeholder="Enter your goal for social life"
                      style={{
                        backgroundColor: "#575656",
                        color: "#fff",
                        border: "1px solid #ccc",
                      }}
                    />
                  </label>
                  <label>
                    Relationships
                    <textarea
                      name="relationships"
                      value={goals.relationships}
                      onChange={handleGoalChange}
                      placeholder="Enter your goal for relationships"
                      style={{
                        backgroundColor: "#575656",
                        color: "#fff",
                        border: "1px solid #ccc",
                      }}
                    />
                  </label>
                  <label>
                    Personal Leisure Time
                    <textarea
                      name="personalLeisureTime"
                      value={goals.personalLeisureTime}
                      onChange={handleGoalChange}
                      placeholder="Enter your goal for personal leisure time"
                      style={{
                        backgroundColor: "#575656",
                        color: "#fff",
                        border: "1px solid #ccc",
                      }}
                    />
                  </label>
                </div>

                <button className="link-btn" onClick={handleUpdateGoals}>
                  Update
                </button>
              </div>
            </div>
          )}

          {userSettings["moodTracker"] && (
            <div className="tile tile-1" style={{ height: "550px" }}>
              <div className="tile-header">
                <span className="icon">😊</span> Mood Tracker
              </div>
              <div className="tile-content">
                <p>
                  The Pixel Mood Tracker is a simple and interactive tool to
                  help you track your daily mood. Simply click on the square
                  corresponding to the day. You can cycle through four different
                  moods by clicking the square multiple times
                </p>

                <div className="status-row">
                  <span className="icon">😊</span>
                  <span id="status-label-1">{emojiPercentages[1] || 0}%</span>
                  <div ref={statusBar1Ref} className="status-bar"></div>
                </div>
                <div className="status-row">
                  <span className="icon">🙂</span>
                  <span id="status-label-2">{emojiPercentages[2] || 0}%</span>
                  <div ref={statusBar2Ref} className="status-bar"></div>
                </div>
                <div className="status-row">
                  <span className="icon">😟</span>
                  <span id="status-label-3">{emojiPercentages[3] || 0}%</span>
                  <div ref={statusBar3Ref} className="status-bar"></div>
                </div>
                <div className="status-row">
                  <span className="icon">😫</span>
                  <span id="status-label-4">{emojiPercentages[4] || 0}%</span>
                  <div ref={statusBar4Ref} className="status-bar"></div>
                </div>

                <div className="container1-mood">
                  <div className="calendar-grid">{renderGrid()}</div>
                </div>
                <button className="clear-btn" onClick={handleClearData}>
                  Clear
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {modalVisible && modal} {/* Render the modal if modalVisible is true */}
    </>
  );
};

export default ClientDashboard;
