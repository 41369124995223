// src/index.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './Home';
import TOS from './TOS';
import Privacy from './Privacy';
import Settings from './Settings';
import LoginScreen from './LoginScreen';
import SuccessPage from './SuccessPage';
import Cancel from './Cancel'; // Adjust the import path as necessary
import TherapistRegistration from './TherapistRegistration';
import ClientRegistration from './ClientRegistration';
import Pricing from './Pricing';
import TherapistDashboard from './TherapistDashboard';
import ClientDashboard from './ClientDashboard';
import OurVision from './OurVision';
import HowToUseOCD from './HowToUseOCD';
import HowToUseTherapist from './HowToUseTherapist';
import GetADemo from './GetADemo';
import YaleBrownForm from './YaleBrownForm';
import GAD7 from './GAD7';
import PHQ9 from './PHQ9';
import GAD7Statistics from './GAD7Statistics'; // Import your new page component
import PHQ9Statistics from './PHQ9Statistics'; // Import your new page component
import YBOCSStatistics from './YBOCSStatistics';
import ExclusiveBetaSignup from './ExclusiveBetaSignup';
import Blog from './Blog';
import PostSUDS from './PostSUDS';
import PostOCDExperts from './PostOCDExperts';
import PostManageOCD from './PostManageOCD';
import PostErp from './PostErp';
import PostMisconceptions from './PostMisconceptions';
import PostEssentialTools from './PostEssentialTools';
import PostMaintainingRecovery from './PostMaintainingRecovery';
import PostActAndErp from './PostActAndErp';
import PostErpFail from './PostErpFail';
import PostOcdOverview from './PostOcdOverview';
import PostSupportingOcd from './PostSupportingOcd';


function App() {
  const [therapistId, setTherapistId] = useState(null);
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tos" element={<TOS />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/suds-tracker-manage-ocd-anxiety" element={<PostSUDS />} />
        <Route path="/50-influential-experts-shaping-ocd" element={<PostOCDExperts />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/therapistdashboard" element={<TherapistDashboard therapistId={therapistId} />} />
        <Route path="/client-dashboard" element={<ClientDashboard />} />
        <Route path="/login" element={<LoginScreen setTherapistId={setTherapistId} />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/registerastherapist" element={<TherapistRegistration />} />
        <Route path="/registerasclient" element={<ClientRegistration />} />
        <Route path="/ourvision" element={<OurVision />} />
        <Route path="/how-to-ocd" element={<HowToUseOCD />} />
        <Route path="/how-to-therapist" element={<HowToUseTherapist />} />
        <Route path="/get-a-demo" element={<GetADemo />} />
        <Route path="/yale-brown" element={<YaleBrownForm />} />
        <Route path="/gad7" element={<GAD7 />} />
        <Route path="/phq9" element={<PHQ9 />} />
        <Route path="/gad7-statistics" element={<GAD7Statistics />} />
        <Route path="/phq9-statistics" element={<PHQ9Statistics />} />
        <Route path="/ybocs-statistics" element={<YBOCSStatistics />} />
        <Route path="/beta-signup" element={<ExclusiveBetaSignup />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/manage-ocd-with-erp" element={<PostManageOCD />} />
        <Route path="/technology-enhanced-erp" element={<PostErp />} />
        <Route path="/ocd-misconceptions" element={<PostMisconceptions />} />
        <Route path="/ocd-essential-tools" element={<PostEssentialTools />} />
        <Route path="/maintaining-ocd-recovery" element={<PostMaintainingRecovery />} />
        <Route path="/act-and-erp" element={<PostActAndErp />} />
        <Route path="/ocd-erp-fails" element={<PostErpFail />} />
        <Route path="/treatments-and-therapies-for-ocd" element={<PostOcdOverview />} />
        <Route path="/supporting-loved-ones-with-ocd" element={<PostSupportingOcd />} />
        
      </Routes>
    </Router>

    </>
  );
}

export default App;