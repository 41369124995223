import React from "react";
import "./Home.css";
import calmMindImage from "./calmMind-removebg-preview.png";
import patientImage1 from "./patient1.jpg";
import patientImage2 from "./patient2.jpg";
import therapistImage1 from "./therapist1.jpg";
import Navbar from "./Navbar";
import EmailSignup from "./EmailSignup";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Homepage = () => {
  return (
    <>
      <Navbar />
      <div className="homepage-container">
        <header className="homepage-header">
          <h1>The Specialized Platform Built Exclusively for OCD Treatment</h1>
          <h2>
            Transform evidence-based OCD treatment with digital precision and extend your therapeutic reach between sessions.
            <br />
          </h2>
          <div className="header-content">
            <div className="text-content">
              <br />
              <p>
                OCDSerenity empowers mental health providers with purpose-built tools 
                that enhance the therapeutic relationship and treatment protocols unique to OCD.
                Our platform bridges the gap between weekly appointments with structured support tools, 
                while maintaining the highest standards of security and HIPAA compliance.
              </p>
              <br />
            </div>
            
          </div>
          <div>
            <br />
          </div>
        </header>

        <section className="beta-access-section homepage-header">
          <h1>Exclusive Beta Access: For OCD Treatment Specialists</h1>
          <h2>
            We're selecting a small group of dedicated OCD specialists to be the first to experience our specialized therapeutic platform.
          </h2>
          <div className="header-content">
            <div className="text-content">
              <p>
                As a selected beta participant, you'll receive:
                <br /><br />
                • Priority access to our OCD-specific clinical tools<br />
                • Input into feature development aligned with evidence-based protocols<br />
                • One-on-one implementation support for your practice<br />
                • Preferred pricing when we officially launch
                <br /><br />
                <div className="cta-buttons">
                  <Link to="/beta-signup">
                    <button 
                      className="cta-button" 
                      aria-label="Apply for specialist beta access to OCD Serenity's clinical platform"
                    >
                      <b>✨ Apply for Specialist Beta Access ✨</b>
                      <br /><br />
                      Limited to qualified OCD treatment providers
                    </button>
                  </Link>
                </div>
              </p>
            </div>
            <div className="image-content">
              <img src={calmMindImage} alt="Specialist Beta Program" />
            </div>
          </div>
          <h2>Join our clinical community and help shape the future of technology-enhanced OCD treatment.</h2>
        </section>
        <br/>

        <section className="homepage-header">
          <h1>Purpose-Built for OCD Treatment</h1>
          <p>
            Unlike general mental health platforms, OCDSerenity is designed specifically 
            for the unique challenges of OCD treatment, providing specialized tools that 
            convert patient experiences into actionable clinical insights.
            <br /><br />
            <div style={{ maxWidth: "900px", height: "auto", margin: "auto" }}>
              <img src={therapistImage1} alt="OCD Specialist using platform" style={{ maxWidth: "100%", height: "auto" }} />
            </div>
            <br />
            Our specialized clinical features include:
          </p>
          <br />
          <h2>
            <strong>For OCD Treatment Specialists</strong>
          </h2>
          <p>
            OCDSerenity enhances your clinical expertise with purpose-built tools that streamline OCD treatment and improve patient outcomes:
          </p>
          <br />
          <p>
            ✅ <strong>Evidence-Based Treatment Support</strong>: Tools specifically designed to enhance ERP, CBT, and other evidence-based OCD protocols.
          </p>
          <br />
          <p>
            ✅ <strong>Clinical Management Dashboard</strong>: Easily track patient progress across multiple OCD dimensions, with specialized visualization of compulsion frequency, severity, and response to treatment.
          </p>
          <br />
          <p>
            ✅ <strong>Treatment Continuity</strong>: Extend your therapeutic reach between sessions with structured support tools that reinforce clinical protocols.
          </p>
          <br />
          <p>
            ✅ <strong>Data-Driven Insights</strong>: Transform subjective patient experiences into objective, trackable data to identify patterns and treatment effectiveness.
          </p>
          <br />
          <br />
          <p>
            All patient data is securely encrypted and stored in compliance with HIPAA standards, 
            creating a trusted environment focused on clinical excellence and patient progress.
          </p>
          <br />
          <EmailSignup />
          <br />
          <h2>
            <strong>For Individuals with OCD</strong>
          </h2>
          <p>
            Our platform supports your treatment journey with specialized tools that work in harmony with your therapist's approach:
          </p>
          <br />
          <div style={{ maxWidth: "900px", height: "auto", margin: "auto" }} >
             <img src={patientImage1} alt="Patient using OCD Serenity" style={{ maxWidth: "100%", height: "auto" }} />
          </div>
          <div style={{ maxWidth: "900px", height: "auto", margin: "auto" }} >
             <img src={patientImage2} alt="Patient tracking progress" style={{ maxWidth: "100%", height: "auto" }}  />
          </div>
          <br />
          <br />
          <p>
            ✅ <strong>Personalized Dashboard</strong>: Your central hub for tracking progress and managing OCD-specific tools between therapy sessions.
          </p>
          <br />
          <p>
            ✅ <strong>Evidence-Based Resources</strong>: Access to specialized OCD content curated from trusted clinical sources to complement your treatment.
          </p>
          <br />
          <p>
            ✅ <strong>OCD-Specific Tracking Tools</strong>: Purpose-built tools for monitoring triggers, intrusive thoughts, and compulsions to recognize patterns and progress.
          </p>
          <br />
          <p>
            ✅ <strong>Exposure Hierarchy Builder</strong>: Collaboratively develop and track graduated exposure exercises with your therapist, following evidence-based ERP protocols.
          </p>
          <br />
          <p>
            ✅ <strong>SUDS Integration</strong>: Track distress levels during exposure exercises and daily activities to objectively measure progress and identify patterns.
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Homepage;