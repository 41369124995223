import React from 'react';
import Navbar from './Navbar';

const Blog = () => {
  return (
    <>
      <Navbar />
      <div className='blog-post' style={{ paddingBottom: '500px' }}>
        <h1 className='blog-title'>OCD Serenity Blog</h1>
        <br />
        <br />
        <a
          href='https://www.ocdserenity.com/suds-tracker-manage-ocd-anxiety'
          style={{ fontSize: '18px' }}
        >
          ✍️ How a SUDS Tracker Can Help Manage OCD Anxiety
        </a>
        <br/><br/>
        <a
          href='https://www.ocdserenity.com/manage-ocd-with-erp'
          style={{ fontSize: '18px' }}
        >
          ✍️ How to Manage OCD with ERP: A Step-by-Step Guide
        </a>
        <br/><br/>
        <a
          href='https://www.ocdserenity.com/50-influential-experts-shaping-ocd'
          style={{ fontSize: '18px' }}
        >
          ✍️ 50 Influential Experts Shaping OCD Treatment
        </a>
        <br/><br/>
        <a
          href='https://www.ocdserenity.com/technology-enhanced-erp'
          style={{ fontSize: '18px' }}
        >
          ✍️ Technology-Enhanced ERP: Bridging the Gap Between Sessions
        </a>
        <br/><br/>
        <a
          href='https://www.ocdserenity.com/ocd-misconceptions'
          style={{ fontSize: '18px' }}
        >
          ✍️ 7 Misconceptions About OCD Treatment Debunked by Research
        </a>
        <br/><br/>
        <a
          href='https://www.ocdserenity.com/ocd-essential-tools'
          style={{ fontSize: '18px' }}
        >
          ✍️ 9 Essential Tools Mental Health Professionals Use in OCD Treatment
        </a>
        <br/><br/>
        <a
          href='https://www.ocdserenity.com/maintaining-ocd-recovery'
          style={{ fontSize: '18px' }}
        >
          ✍️ 8 Strategies for Maintaining OCD Recovery Long-Term
        </a>
        <br/><br/>
        <a
          href='https://www.ocdserenity.com/act-and-erp'
          style={{ fontSize: '18px' }}
        >
          ✍️ Why ACT and ERP Are More Powerful Together: A Clinical Breakthrough in OCD Treatment
        </a>
        <br/><br/>
        <a
          href='https://www.ocdserenity.com/ocd-erp-fails'
          style={{ fontSize: '18px' }}
        >
          ✍️ Why ERP Fails For Some Patients New Understanding From Recent Studies
        </a>
        <br/><br/>
        <a
          href='https://www.ocdserenity.com/treatments-and-therapies-for-ocd'
          style={{ fontSize: '18px' }}
        >
          ✍️ Treatments and Therapies for Obsessive-Compulsive Disorder (OCD): A Comprehensive Overview
        </a>
        <br/><br/>
        <a
          href='https://www.ocdserenity.com/supporting-loved-ones-with-ocd'
          style={{ fontSize: '18px' }}
        >
          ✍️ Supporting Loved Ones with OCD: A Guide for Families and Caregivers
        </a>

      </div>
    </>
  );
};

export default Blog;